import React, { useState, useEffect, useRef, memo } from "react";
import { useGlobalContext } from "../../../Context";
// import RosaryStory from "./rosary-story";
import { ParagraphParsing, PrayerParsing } from "../utils/paragraphParsing";
import { QuestionMarkRed } from "../../../fonts/svg/questionMarkRed";
import styles from "../../styles/HeavenlyPower.module.css";

// Font Awesome Icon Inserts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@awesome.me/kit-72510df5aa/icons";

const HeavenlyPowerPrayers = ({ prayers, prayerMsg }) => {
  const [data, setData] = useState({});
  const [prayerSelection, setPrayerSelection] = useState(null);
  const [informationIconStatus, setInformationIconStatus] = useState({ selected: false, hasSelected: false });

  const UserSelectsPrayer = (id) => {
    const selectedPrayer = data.filter((prayer) => prayer.id === id);
    // console.log(selectedPrayer);
    setPrayerSelection(selectedPrayer[0].id);
  };

  useEffect(() => {
    setData(prayers);
    setPrayerSelection(2);
  }, []);

  // Return Section for Standard Prayers, or Mystical Prayers
  if (data && prayerSelection !== null) {
    return (
      <div className={"heavenly-power-prayers"}>
        <div className={styles["heavenly-power-prayer-section"]}>
          <h1 id="heavenly-power-prayer-section" className={styles["section-title"]}>
            {data[prayerSelection - 1]["title"]}
          </h1>
          <div className={styles["prayer-section-info-btn-cont"]}>
            <button
              onClick={() => setInformationIconStatus({ selected: !informationIconStatus.selected, hasSelected: true })}
              className={
                !informationIconStatus.hasSelected
                  ? `${styles["information-icon"]} ${styles["icon-bounce"]}`
                  : styles["information-icon"]
              }
            >
              <QuestionMarkRed />
            </button>
          </div>
          {informationIconStatus.selected && (
            <div className={styles["information-msg-hp"]}>
              <p>{prayerMsg.informationMsg + " "}</p>
              <div className={styles["information-icons"]}>
                {/* <i
                  onClick={() => setInformationIconStatus({ ...informationIconStatus, selected: false })}
                  style={{ position: "relative", top: ".25em" }}
                  className="fa-solid fa-xmark"
                ></i> */}
                <FontAwesomeIcon
                  onClick={() => setInformationIconStatus({ ...informationIconStatus, selected: false })}
                  style={{ position: "relative", top: ".25em" }}
                  icon={fas.faXmark}
                />
                <a href={prayerMsg.informationLink} target="_blank">
                  {/* <i style={{ cursor: "pointer" }} className="fa-solid fa-arrow-up-right-from-square"></i> */}
                  <FontAwesomeIcon style={{ cursor: "pointer" }} icon={fas.faArrowUpRightFromSquare} />
                </a>
              </div>
            </div>
          )}
          {prayerSelection !== 7 ? (
            <>
              <StandardPrayer prayer={data[prayerSelection - 1]} />
              <PrayerButtons prayers={data} callBack={UserSelectsPrayer} selectedBtnID={prayerSelection} />
            </>
          ) : (
            <>
              <MysticalPrayer prayer={data[prayerSelection - 1]} />
              <PrayerButtons prayers={data} callBack={UserSelectsPrayer} selectedBtnID={prayerSelection} />
            </>
          )}
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

// Component for all prayers except for Mystical Prayers
const StandardPrayer = ({ prayer }) => {
  return (
    <div className={styles["prayer-content"]}>
      {prayer.prayer_list.map((prayerObj, index) => {
        const { prayer_name, recitation, frequency } = prayerObj;
        return (
          <div key={index}>
            <ParagraphParsing paragraph={prayer_name} k={"pn" + index} txtClass={styles["prayer-name"]} />
            <PrayerParsing paragraph={recitation} k={"r" + index} txtClass={styles["prayer-recitation"]} />
            <ParagraphParsing paragraph={frequency} k={"pf" + index} txtClass={styles["prayer-frequency"]} />
          </div>
        );
      })}
      {prayer.graces.title && (
        <div>
          <ParagraphParsing paragraph={prayer.graces.title} txtClass={styles["grace-header"]} />
        </div>
      )}
      {prayer.graces.grace_list.map((graceObj, index) => {
        return (
          <div key={index}>
            <ParagraphParsing paragraph={graceObj} k={"gr" + index} txtClass={styles["grace"]} />
          </div>
        );
      })}
    </div>
  );
};

// Component for all prayers except for Mystical Prayers
const MysticalPrayer = ({ prayer }) => {
  return (
    <div className={styles["prayer-content"]}>
      {prayer.prayer_list.map((prayerObj, index) => {
        const { prayer_name, quote_from_Jesus, recitation, frequency } = prayerObj;
        return (
          <div key={index}>
            <ParagraphParsing paragraph={prayer_name} k={"pn" + index} txtClass={styles["prayer-name-mystical"]} />
            <ParagraphParsing
              paragraph={quote_from_Jesus + "   " + frequency}
              k={"jq" + index}
              txtClass={styles["prayer-quote-Jesus"]}
            />
            <PrayerParsing paragraph={recitation} k={"r" + index} txtClass={styles["prayer-recitation"]} />
          </div>
        );
      })}
    </div>
  );
};

// Component for selecting a particular prayer, call back is the usestate function from HeavenlyPowerPrayers, `setPrayerSelection`
const PrayerButtons = ({ prayers, callBack, selectedBtnID }) => {
  const { windowDimensions } = useGlobalContext();

  if (windowDimensions.width < 1200) {
    return (
      <div className={styles["prayer-buttons"]}>
        {prayers.map((prayer, index) => {
          let btn_class =
            prayer.id === selectedBtnID
              ? styles["prayer-btn"] + " " + styles["s-prayer-btn"]
              : styles["prayer-btn"] + " " + styles["ns-prayer-btn"];

          if (index === 5) {
            btn_class =
              prayer.id === selectedBtnID
                ? styles["prayer-btn-ang-appeal"] + " " + styles["s-prayer-btn"]
                : styles["prayer-btn-ang-appeal"] + " " + styles["ns-prayer-btn"];
          }

          return (
            <div key={index} onClick={() => callBack(prayer.id)} className={btn_class}>
              {prayer.id}
            </div>
          );
        })}
      </div>
    );
  } else {
    return (
      <div className={styles["prayer-buttons"]}>
        {prayers.map((prayer, index) => {
          let btn_class =
            prayer.id === selectedBtnID
              ? styles["prayer-btn-bg"] + " " + styles["s-prayer-btn"]
              : styles["prayer-btn-bg"] + " " + styles["ns-prayer-btn"];

          if (index === 5) {
            btn_class =
              prayer.id === selectedBtnID
                ? styles["prayer-btn-bg-ang-appeal"] + " " + styles["s-prayer-btn"]
                : styles["prayer-btn-bg-ang-appeal"] + " " + styles["ns-prayer-btn"];
          }

          return (
            <div key={index} onClick={() => callBack(prayer.id)} className={btn_class}>
              {prayer.title}
            </div>
          );
        })}
      </div>
    );
  }
};

export default memo(HeavenlyPowerPrayers);
