import React, { useState, useEffect, memo } from "react";
import { useGlobalContext } from "../../../Context";
import DMGoldArrows from "./dm-gold-arrows";
import styles from "../../styles/DivineMercy.module.css";

const DivineMercyChannels = ({ props, dmFillers = null, images }) => {
  const { language } = useGlobalContext();
  const [dynamicTypingDone, setDynamicTypingDone] = useState(true);
  const DMChannels = props;
  let stringCount = 0; //references letter character of convoText
  let timeOfInterval = 100; //speed of text
  let currentObj = ""; //refers to the word the program is dynamically typing
  let timeout = [];
  let dmSubTitleMessageIndex = 0;

  const scrollToDMQuoteSection = () => {
    var dmQuoteElement = document.querySelector("#dm-quotes-checkpoint");
    // dmQuoteElement.scrollIntoView({ behavior: "smooth", block: "center" });
    let top = dmQuoteElement.getBoundingClientRect().top;
    window.scrollBy(0, top - 100);
  };

  const BeginDynamicMessage = async () => {
    function sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
    await sleep(7000);
    currentObj = dmFillers.channelSubtitle[dmSubTitleMessageIndex];
    setDynamicTypingDone(false);
    timeout.push(setInterval(TypeWords, timeOfInterval));
  };

  const deleteExcessTimeoutIds = () => {
    while (timeout.length > 1) {
      const id = timeout.pop();
      clearInterval(id);
    }
  };

  useEffect(() => {
    const manager = setInterval(() => {
      deleteExcessTimeoutIds();
    }, 150);
    setTimeout(() => {
      clearInterval(manager);
    }, 18000);
    return () => clearInterval(manager);
  }, []);

  const TypeWords = () => {
    if (stringCount === dmFillers.channelSubtitle[dmSubTitleMessageIndex].length) {
      // Stops typing when conversation is done. 0 indicates the first msg
      if (dmSubTitleMessageIndex === 0) {
        currentObj = dmFillers.channelSubtitle[++dmSubTitleMessageIndex];
        stringCount = 0;
      } else {
        clearInterval(timeout.pop());
        setDynamicTypingDone(true);
        return;
      }
    } else if (timeout.length < 2) {
      // 1st. (Gets Element we are going to change.)
      const element = document.querySelector("#dm-channels-subheader");

      if (element === undefined) {
        clearInterval(timeout.pop());
        console.log("page navigated away");
      } else {
        // 3rd. Increases length of word on Screen
        let letter = currentObj.slice(0, ++stringCount);
        if (element !== null) {
          element.innerHTML = letter; // Assigns new letter to words on screen
        }
      }
    }
  };

  // useEffect(() => {
  //   BeginDynamicMessage();
  //   return () => clearInterval(timeout.pop());
  // }, []);

  //If language changes it removes the setInterval
  useEffect(() => {
    timeout.map((id) => {
      clearInterval(id);
    });
    const element = document.querySelector("#dm-channels-subheader");
    if (element !== null) {
      element.innerHTML = "";
      setDynamicTypingDone(true);
    }
    BeginDynamicMessage();
    return () => clearInterval(timeout.pop());
  }, [language]);

  return (
    <>
      <h2 className={`${styles["dm-channels-header"]} ${styles["section-title"]}`}>
        {dmFillers && dmFillers.channelTitle}
      </h2>
      <button className={`${styles["invisible-btn"]} ${styles["dm-header-btn"]}`} onClick={scrollToDMQuoteSection}>
        <span id="dm-channels-subheader" className={styles["dm-channels-subheader"]}></span>
        {!dynamicTypingDone && <span id={styles["blink-line-dm"]}>|</span>}
      </button>

      <div className={styles["dm-channels-grid-container"]}>
        {DMChannels &&
          DMChannels.map((obj, index) => {
            const { channel, description } = obj;
            const hasLink = index === 1 || index === 4;
            const externalLink = index === 1 ? dmFillers.dmChapletLink : dmFillers.dmNovenaLink;
            let imgClass = index === 0 ? `${styles["channel-img"]} ${styles["Jesus-img"]}` : styles["channel-img"];
            imgClass = index === 3 ? `${styles["channel-img"]} ${styles["hour-img"]}` : imgClass;
            imgClass = index === 2 ? `${styles["channel-img"]} ${styles["sunday-img"]}` : imgClass;
            let cardClass = styles["dm-channel-card"];
            if (index === 0 || index === 2) {
              cardClass = cardClass + " " + styles["dm-spec-card"];
            }
            return (
              <article key={index} className={cardClass}>
                <img src={images[index]} className={imgClass} alt={channel} />
                <div className={styles["channel-info"]}>
                  <h5 className={styles["channel-overlay-title"]}>{channel}</h5>
                  <p className={styles["channel-overlay-info"]}>{description}</p>
                  {hasLink && (
                    <DMGoldArrows
                      props={{
                        message: dmFillers.channelArrowMessage,
                        link: externalLink,
                        classStyle: styles["dm-channel-arrows"],
                        btnStyle: styles["invisible-btn"],
                      }}
                    />
                  )}
                </div>
                <div className={styles["pulse-circle"]}>
                  <span className={`${styles["c1"]} ${styles["leftgroup"]}`}></span>
                  <span className={`${styles["c2"]} ${styles["leftgroup"]}`}></span>
                  <span className={`${styles["c3"]} ${styles["leftgroup"]}`}></span>
                  <span className={`${styles["c4"]} ${styles["leftgroup"]}`}></span>
                  <span className={`${styles["c5"]} ${styles["leftgroup"]}`}></span>
                  <span className={`${styles["c6"]} ${styles["leftgroup"]}`}></span>
                  <span className={`${styles["c7"]} ${styles["leftgroup"]}`}></span>
                  <span className={`${styles["c8"]} ${styles["leftgroup"]}`}></span>
                  <span className={`${styles["c9"]} ${styles["leftgroup"]}`}></span>
                  <span className={`${styles["c10"]} ${styles["leftgroup"]}`}></span>
                  <span className={`${styles["c11"]} ${styles["rightgroup"]}`}></span>
                  <span className={`${styles["c12"]} ${styles["rightgroup"]}`}></span>
                  <span className={`${styles["c13"]} ${styles["rightgroup"]}`}></span>
                  <span className={`${styles["c14"]} ${styles["rightgroup"]}`}></span>
                  <span className={`${styles["c15"]} ${styles["rightgroup"]}`}></span>
                  <span className={`${styles["c16"]} ${styles["rightgroup"]}`}></span>
                  <span className={`${styles["c17"]} ${styles["rightgroup"]}`}></span>
                  <span className={`${styles["c18"]} ${styles["rightgroup"]}`}></span>
                  <span className={`${styles["c19"]} ${styles["rightgroup"]}`}></span>
                  <span className={`${styles["c20"]} ${styles["rightgroup"]}`}></span>
                </div>
              </article>
            );
          })}
      </div>
    </>
  );
};

export default memo(DivineMercyChannels);
