import React, { useState, useEffect, memo } from "react";
import { useGlobalContext } from "../../../Context";
// import { RosaryArrows } from "./rosary-arrows";
import { ParagraphParsing } from "../utils/paragraphParsing";
import { QuestionMarkBlue } from "../../../fonts/svg/questionMarkBlue";
import styles from "../../styles/Rosary.module.css";

// Font Awesome Icon Inserts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, fad } from "@awesome.me/kit-72510df5aa/icons";

const RosaryGraces = ({ sources, graces, appOfJesus, rosaryFillers }) => {
  // const { title, subtitle, apparition } = appOfJesus;
  const { windowDimensions, language } = useGlobalContext();
  // Orientation and setup UseStates
  const [selectedGraceQ, setSelectedGraceQ] = useState({ id: null, grace: null, fontSize: null });
  const [selectedArray, setSelectedArray] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [displayRosaryGraces, setDisplayRosaryGraces] = useState(true);
  // Quote Quotes Related
  const [selectedBibleQuote, setSelectedBibleQuote] = useState(0);
  const [sides, setSides] = useState({
    left: [],
    bottom: [],
    right: [],
    top: [],
    corner: [],
  });
  const [informationIconStatus, setInformationIconStatus] = useState({ selected: false, hasSelected: false });
  const [starPositions, setStarPositions] = useState([
    [30, 80],
    [2, 5],
    [6, 1],
    [4, 4],
    [2, 9],
    [12, 6],
    [14, 2],
  ]);
  const marianSymbol = "icon-MarianSymbol-cross ";
  const saintSymbol = "icon-saint ";

  useEffect(() => {
    if (graces) {
      const newArray = [];
      graces.forEach((element) => {
        newArray.push(false);
      });
      setSelectedArray(newArray);
      const data = [...graces];
      setSides({
        left: data.splice(0, 4),
        bottom: data.splice(0, 3),
        corner: data.splice(0, 1),
        right: data.splice(0, 4).reverse(),
        top: data.splice(0, 3).reverse(),
      });
    }
  }, [graces]);

  useEffect(() => {
    if (windowDimensions.width > 1199) {
      setDisplayRosaryGraces(true);
    }
  }, [windowDimensions.width]);

  useEffect(() => {
    setInformationIconStatus({ ...informationIconStatus, selected: false });
  }, [displayRosaryGraces]);

  useEffect(() => {
    const starEffect = setInterval(() => {
      const newStarPositions = [];
      for (let i = 0; i < starPositions.length; i++) {
        newStarPositions.push([Math.floor(Math.random() * 100), Math.floor(Math.random() * 99)]);
      }
      setStarPositions(newStarPositions);
    }, 4000);
    return () => clearInterval(starEffect);
  }, []);

  // If language changes, display no rosary graces selected
  useEffect(() => {
    setSelectedGraceQ({ id: null, grace: null, fontSize: null });
  }, [language]);

  const UserSelectGrace = (grace, id) => {
    // To adjust the size of the grace quote
    let fontSize = ".75em";
    if (grace.length < 200) {
      fontSize = "1em";
    } else if (grace.length < 250) {
      fontSize = ".9em";
    } else {
      fontSize = ".75em";
    }
    // Lights up the buttons blue if already selected
    setSelectedGraceQ({ grace: grace, id: id, fontSize: fontSize });
    selectedArray[id] = true;
    setSelectedArray(selectedArray);
    // For the corner button in the Q
    let allSelected = true;
    selectedArray.forEach((obj) => {
      if (!obj) {
        allSelected = false;
      }
    });
    if (allSelected) {
      setAllSelected(true);
    }
  };

  const ConvertGraceTitle = (id) => {
    const newId = parseInt(id) + 1;
    if (newId === 1) {
      return "1st";
    } else if (newId === 2) {
      return "2nd";
    } else if (newId === 3) {
      return "3rd";
    } else {
      return newId + "th";
    }
  };

  return (
    <div id="rosary-graces-section" className={styles["rosary-graces"]}>
      {/* star effect */}
      <i
        style={{ top: `${starPositions[0][0]}%`, right: `${starPositions[0][1]}%` }}
        className={"icon-star " + styles["rosary-graces-stars"] + " " + styles["star1"]}
      ></i>
      <i
        style={{ top: `${starPositions[1][0]}%`, right: `${starPositions[1][1]}%` }}
        className={"icon-star " + styles["rosary-graces-stars"] + " " + styles["star2"]}
      ></i>{" "}
      <i
        style={{ top: `${starPositions[2][0]}%`, right: `${starPositions[2][1]}%` }}
        className={"icon-star " + styles["rosary-graces-stars"] + " " + styles["star3"]}
      ></i>{" "}
      <i
        style={{ top: `${starPositions[3][0]}%`, right: `${starPositions[3][1]}%` }}
        className={"icon-star " + styles["rosary-graces-stars"] + " " + styles["star4"]}
      ></i>{" "}
      <i
        style={{ top: `${starPositions[4][0]}%`, right: `${starPositions[4][1]}%` }}
        className={"icon-star " + styles["rosary-graces-stars"] + " " + styles["star5"]}
      ></i>{" "}
      <i
        style={{ top: `${starPositions[5][0]}%`, right: `${starPositions[5][1]}%` }}
        className={"icon-star " + styles["rosary-graces-stars"] + " " + styles["star6"]}
      ></i>{" "}
      <i
        style={{ top: `${starPositions[6][0]}%`, right: `${starPositions[6][1]}%` }}
        className={"icon-star " + styles["rosary-graces-stars"] + " " + styles["star7"]}
      ></i>
      {/* end of stars */}
      <div className={styles["rosary-graces-header"]}>
        {rosaryFillers && displayRosaryGraces && (
          <h2 className={styles["section-title"]} style={{ color: "var(--glory-blue-100)" }}>
            {rosaryFillers.gracesTitle}
          </h2>
        )}

        {displayRosaryGraces && (
          <button
            onClick={() => setInformationIconStatus({ selected: !informationIconStatus.selected, hasSelected: true })}
            className={
              !informationIconStatus.hasSelected
                ? styles["information-icon"] + " " + styles["icon-bounce"]
                : styles["information-icon"]
            }
          >
            <QuestionMarkBlue />
          </button>
        )}

        {informationIconStatus.selected && displayRosaryGraces && (
          <div className={styles["information-msg"]}>
            <p>{sources && sources.informational + " "}</p>
            <div className={styles["information-icons"]}>
              {/* <i
                onClick={() => setInformationIconStatus({ ...informationIconStatus, selected: false })}
                style={{ color: "var(--glory-blue-200)", cursor: "pointer", position: "relative", top: ".25em" }}
                className="fa-solid fa-xmark"
              ></i> */}
              <FontAwesomeIcon
                onClick={() => setInformationIconStatus({ ...informationIconStatus, selected: false })}
                style={{ color: "var(--glory-blue-200)", cursor: "pointer", position: "relative", top: ".25em" }}
                icon={fas.faXmark}
              />
              <a href={sources.link} target="_blank">
                {/* <i
                  style={{ color: "var(--glory-blue-200)", cursor: "pointer" }}
                  className="fa-solid fa-arrow-up-right-from-square"
                ></i> */}
                <FontAwesomeIcon
                  style={{ color: "var(--glory-blue-200)", cursor: "pointer" }}
                  icon={fas.faArrowUpRightFromSquare}
                />
              </a>
            </div>
          </div>
        )}
      </div>
      {/* Section for the Q Quotes*/}
      <div className={styles["queen-center"]}>
        {displayRosaryGraces ? (
          <div className={styles["rosary-graces-grid"]}>
            {/* first row */}
            <div className={styles["row"]}>
              <div className={styles["col"]}></div>
              <div className={styles["col"] + " " + styles["midCol"]}>
                {sides &&
                  sides.top.map((grace) => {
                    const idStyle =
                      selectedArray.length > 0 && selectedArray[grace.id - 1]
                        ? "var(--glory-blue-100)"
                        : "var(--purple)";
                    const classStyle =
                      selectedArray.length > 0 && selectedArray[grace.id - 1] ? styles["sc-grace"] : styles["nc-grace"];
                    const iconStyle =
                      selectedArray.length > 0 && selectedArray[grace.id - 1]
                        ? saintSymbol + " " + styles["blue"]
                        : marianSymbol + " " + styles["purple"];
                    const animationStyle = styles[`glow-${grace.id}`];
                    return (
                      <div
                        style={{ color: idStyle }}
                        onClick={() => UserSelectGrace(grace.grace, grace.id - 1)}
                        key={grace.id}
                        className={animationStyle + " " + styles["r-grace"] + " " + classStyle}
                      >
                        <i className={iconStyle}></i>
                      </div>
                    );
                  })}
              </div>
              <div className={styles["col"]}></div>
            </div>
            {/* Second row */}
            <div className={styles["row"]}>
              <div className={styles["col"] + " " + styles["left"]}>
                {sides &&
                  sides.left.map((grace) => {
                    const idStyle =
                      selectedArray.length > 0 && selectedArray[grace.id - 1]
                        ? "var(--glory-blue-100)"
                        : "var(--purple)";
                    const classStyle =
                      selectedArray.length > 0 && selectedArray[grace.id - 1] ? styles["sc-grace"] : styles["nc-grace"];
                    const iconStyle =
                      selectedArray.length > 0 && selectedArray[grace.id - 1]
                        ? saintSymbol + styles["blue"]
                        : marianSymbol + styles["purple"];
                    const animationStyle = styles[`glow-${grace.id}`];
                    return (
                      <div
                        style={{ color: idStyle }}
                        onClick={() => UserSelectGrace(grace.grace, grace.id - 1)}
                        key={grace.id}
                        className={animationStyle + " " + styles["r-grace"] + " " + classStyle}
                      >
                        <i className={iconStyle}></i>
                      </div>
                    );
                  })}
              </div>
              <div className={styles["col"] + " " + styles["grace-quote-cell"]}>
                {selectedGraceQ.id !== null && (
                  <>
                    <div className={styles["grace-quote-header"]}>
                      <p className={styles["grace-quote"]}>
                        {ConvertGraceTitle(selectedGraceQ.id) + " " + rosaryFillers.grace}
                      </p>
                      {/* <i className="fa-solid fa-gift"></i> */}
                      <FontAwesomeIcon icon={fas.faGift} />
                    </div>
                    <p style={{ margin: 0, fontSize: selectedGraceQ.fontSize }} className={styles["grace-quote"]}>
                      {selectedGraceQ.grace}
                    </p>
                  </>
                )}
              </div>
              <div className={styles["col"] + " " + styles["right"]}>
                {sides &&
                  sides.right.map((grace) => {
                    const idStyle =
                      selectedArray.length > 0 && selectedArray[grace.id - 1]
                        ? "var(--glory-blue-100)"
                        : "var(--purple)";
                    const classStyle =
                      selectedArray.length > 0 && selectedArray[grace.id - 1] ? styles["sc-grace"] : styles["nc-grace"];
                    const iconStyle =
                      selectedArray.length > 0 && selectedArray[grace.id - 1]
                        ? saintSymbol + styles["blue"]
                        : marianSymbol + styles["purple"];
                    const animationStyle = styles[`glow-${grace.id}`];
                    return (
                      <div
                        style={{ color: idStyle }}
                        onClick={() => UserSelectGrace(grace.grace, grace.id - 1)}
                        key={grace.id}
                        className={animationStyle + " " + styles["r-grace"] + " " + classStyle}
                      >
                        <i className={iconStyle}></i>
                      </div>
                    );
                  })}
              </div>
            </div>
            {/* Third row */}
            <div className={styles["row"]}>
              <div className={styles["col"]}></div>
              <div className={styles["col"] + " " + styles["midCol"]}>
                {sides &&
                  sides.bottom.map((grace) => {
                    const idStyle =
                      selectedArray.length > 0 && selectedArray[grace.id - 1]
                        ? "var(--glory-blue-100)"
                        : "var(--purple)";
                    const classStyle =
                      selectedArray.length > 0 && selectedArray[grace.id - 1] ? styles["sc-grace"] : styles["nc-grace"];
                    const iconStyle =
                      selectedArray.length > 0 && selectedArray[grace.id - 1]
                        ? saintSymbol + styles["blue"]
                        : marianSymbol + styles["purple"];
                    const animationStyle = styles[`glow-${grace.id}`];
                    return (
                      <div
                        style={{ color: idStyle }}
                        onClick={() => UserSelectGrace(grace.grace, grace.id - 1)}
                        key={grace.id}
                        className={animationStyle + " " + styles["r-grace"] + " " + classStyle}
                      >
                        <i className={iconStyle}></i>
                      </div>
                    );
                  })}
                <div
                  style={{ color: allSelected ? "var(--glory-blue-100)" : "var(--purple)" }}
                  className={
                    allSelected
                      ? styles["r-last-piece"] + " " + styles["glow-8"] + " " + styles["sc-grace"]
                      : styles["r-last-piece"] + " " + styles["glow-8"] + " " + styles["nc-grace"]
                  }
                ></div>
              </div>
              <div className={styles["col"] + " " + styles["corner"]}>
                {sides.corner.map((grace) => {
                  const idStyle =
                    selectedArray.length > 0 && selectedArray[grace.id - 1] ? "var(--glory-blue-100)" : "var(--purple)";
                  const classStyle =
                    selectedArray.length > 0 && selectedArray[grace.id - 1] ? styles["sc-grace"] : styles["nc-grace"];
                  const iconStyle =
                    selectedArray.length > 0 && selectedArray[grace.id - 1]
                      ? saintSymbol + styles["blue"]
                      : marianSymbol + styles["purple"];
                  const animationStyle = styles[`glow-${grace.id}`];
                  return (
                    <div
                      style={{ color: idStyle }}
                      onClick={() => UserSelectGrace(grace.grace, grace.id - 1)}
                      key={grace.id}
                      className={animationStyle + " " + styles["corner-grace"] + " " + classStyle}
                    >
                      <i className={iconStyle}></i>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <div className={styles["queen-quotes"]}>
            <div className={styles["q-quote"]}>
              <p style={{ margin: 0 }} className={styles["q-quote-verse"]}>
                {appOfJesus && appOfJesus.title}
              </p>
              <p
                style={{ fontSize: ".8em", marginTop: 0, marginBottom: "1em", color: "rgba(93, 63, 211)" }}
                className={styles["q-quote-verse"]}
              >
                {appOfJesus && appOfJesus.subtitle}
              </p>
              {/* <p style={{ fontSize: "1em" }} className={styles["q-quote-content"]}>
                {appOfJesus.appariton}
              </p> */}
              {appOfJesus && <ParagraphParsing paragraph={appOfJesus.appariton} txtClass={styles["q-quote-content"]} />}
            </div>
            {/* <div className={styles["queen-q-links"]}>
              {queenQuotes.map((obj, index) => {
                const classStyle =
                  selectedBibleQuote === index
                    ? styles["queen-s-link"] + " " + styles["queen-link"]
                    : styles["queen-ns-link"] + " " + styles["queen-link"];
                return <div onClick={() => setSelectedBibleQuote(index)} key={index} className={classStyle}></div>;
              })}
            </div> */}
          </div>
        )}

        <div className={styles["mb-screen-hidden-graces"]}>
          <div className={styles["queen-quotes"]}>
            <div className={styles["q-quote"]}>
              <p style={{ margin: 0 }} className={styles["q-quote-verse"]}>
                {appOfJesus && appOfJesus.title}
              </p>
              <p
                style={{ fontSize: ".75em", marginTop: 0, marginBottom: ".7em", color: "rgba(93, 63, 211)" }}
                className={styles["q-quote-verse"]}
              >
                {appOfJesus && appOfJesus.subtitle}
              </p>

              {/* <p style={{ fontSize: "1em" }} className={styles["q-quote-content"]}>
                {appOfJesus.appariton}
              </p> */}
              {appOfJesus && <ParagraphParsing paragraph={appOfJesus.appariton} txtClass={styles["q-quote-content"]} />}
            </div>
            {/* <div className={styles["queen-q-links"]}>
              {queenQuotes.map((obj, index) => {
                const classStyle =
                  selectedBibleQuote === index
                    ? styles["queen-s-link"] + " " + styles["queen-link"]
                    : styles["queen-ns-link"] + " " + styles["queen-link"];
                return <div onClick={() => setSelectedBibleQuote(index)} key={index} className={classStyle}></div>;
              })}
            </div> */}
          </div>
        </div>
      </div>
      {windowDimensions.width < 1200 && (
        <div
          className={styles["message-container"]}
          onClick={() =>
            setDisplayRosaryGraces((prevState) => {
              return !prevState;
            })
          }
        >
          {/* <i
            style={{ color: "var(--glory-blue-300)", marginBottom: ".25em" }}
            className={"fa-solid fa-heart-pulse " + styles["chess-q"]}
          ></i> */}
          {displayRosaryGraces ? (
            <>
              <FontAwesomeIcon
                className={styles["chess-q"]}
                style={{ color: "var(--glory-blue-300)", marginBottom: ".25em" }}
                icon={fas.faHeartPulse}
              />
              <div className={styles["message-blue"]}>
                <p> {rosaryFillers.linkMessage1}</p>
              </div>
            </>
          ) : (
            <>
              <FontAwesomeIcon
                className={styles["chess-q"]}
                style={{ color: "var(--glory-blue-300)", marginBottom: ".25em" }}
                icon={fas.faGift}
              />
              <div className={styles["message-blue"]}>
                <p> {rosaryFillers.linkMessage2}</p>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(RosaryGraces);
