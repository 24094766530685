/* eslint-disable */
import React, { useState, useEffect, useRef, memo } from "react";
import { useGlobalContext } from "../Context";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLoaderData } from "react-router-dom";
import TheStoryOverview from "./TheStory";
import { getImageUrlSV4, getJSON } from "./components/utils/s3-content";

import styles from "./styles/ChristPassion.module.css";

const JSONData = {
  englishKey: "the-passion/christ-passion.json",
  spanishKey: "the-passion/christ-passion.json",
};

// import PassData from "../christ-passion.json";

const ChristPassion = () => {
  const { language, setLanguage, setHomePage, ReportToGA } = useGlobalContext();
  const { data, lang } = useLoaderData();

  // const data = PassData;
  // const lang = "en";
  // console.log(data);

  useEffect(() => {
    window.scrollTo(0, 0);
    setHomePage(true);
    if (lang === "sp") {
      setLanguage("sp");
    } else {
      setLanguage("en");
    }
    ReportToGA(window.location.pathname);
  }, []);

  if (data) {
    return (
      <HelmetProvider>
        <Helmet>
          <title>{language === "en" ? "The Story" : "La Estoria"}</title>
          <meta name="description" content="The Story told by the Saints." />
          <meta
            name="keywords"
            content={[
              "Jesus",
              "Jesús",
              "God",
              "Christian",
              "Mercy",
              "misericordia",
              "Dios",
              "Passion",
              "Blood",
              "Christ",
              "Lord",
              "Love",
              "zeal",
              "king",
              "Dios",
              "amor",
            ]}
          />
        </Helmet>
        <main className={styles["christ-passion-page"]}>
          <TheStoryOverview payload={data} />
        </main>
      </HelmetProvider>
    );
  } else {
    return <div></div>;
  }
};

export async function ChristPassionLoader({ params }) {
  var data = {};

  //// UNCOMMMENT CODE BELOW AND RETURN DATA INSTEAD OF ""
  const GetJSONData = async () => {
    if (params.lang === "sp") {
      //2. Call function to retrieve data from S3
      const result = await getJSON(JSONData["spanishKey"]);
      return JSON.parse(result);
    } else {
      const result = await getJSON(JSONData["englishKey"]);
      return JSON.parse(result);
    }
  };

  data.data = await GetJSONData();
  // data.images = await SignImgUrls();
  data.lang = params.lang;
  return data;
}

export default memo(ChristPassion);
