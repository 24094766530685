import React, { useState, useEffect, memo } from "react";
import { SacredHeartOfJesus } from "../../../fonts/svg/sacredHeartJesus";
import { QuestionMarkGold } from "../../../fonts/svg/questionMarkGold";
import { useGlobalContext } from "../../../Context";
import { HolyTrinity } from "../../../fonts/svg/HolyTrinity";

// Font Awesome Icon Inserts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@awesome.me/kit-72510df5aa/icons";

import styles from "../../styles/DivineMercy.module.css";
const DivineMercyQuotes = ({ dmFillers, sources, props, spanish = false }) => {
  const { windowDimensions, mustReadNotebooks, allSelectedCards } = useGlobalContext();
  const [displayCard, setDisplayCard] = useState(false);
  const [selectedCard, setSelectedCard] = useState({});
  const [informationIconStatus, setInformationIconStatus] = useState({ selected: false, hasSelected: false });
  const [lightAllCirclesGold, setLightAllCirclesGold] = useState(false);
  const DMQuotes = props;

  useEffect(() => {
    if (DMQuotes) {
      const newSelectedCards = [];
      DMQuotes.map((qte) => {
        newSelectedCards.push(false);
        return qte;
      });
      allSelectedCards.current = newSelectedCards;
    }
  }, [props]);

  // Used to determine whether all cards have been selected or not
  useEffect(() => {
    let allCardsAreSelected = true;
    if (allSelectedCards.length > 0) {
      for (let i = 0; i < allSelectedCards.length; i++) {
        if (allSelectedCards[i] === false) {
          allCardsAreSelected = false;
        }
      }

      if (allCardsAreSelected) setLightAllCirclesGold(true);
    }
  }, [displayCard]);

  const SelectCard = (card) => {
    //Every time a card is selected we remove it from the mustReadNotebooks, if it exists there
    mustReadNotebooks.current = mustReadNotebooks.current.filter((entry) => entry !== card.journalEntry);
    setSelectedCard(card);
    setDisplayCard(true);
    // Populate the selected circles array
    allSelectedCards.current[card.id - 1] = true;
  };

  const UnselectCard = () => {
    setDisplayCard(false);
  };

  const DetermineCircleStyle = (obj) => {
    const { journalEntry, type } = obj;

    let basicStyling = `${styles["quote-circle"]} ${styles["circle-type"]}`;
    // If all cards have been selected
    if (lightAllCirclesGold) {
      return basicStyling + `${styles["qc-selected"]} ${styles["qc-all-selected"]}`;
    }
    // If the object has been selected
    if (allSelectedCards.current[obj.id - 1] === true) {
      return basicStyling + " " + styles["qc-selected"];
    }

    basicStyling = basicStyling + " " + styles["letter-circle-spacing"];
    if (!spanish) {
      // If the object isn't the next guiding quote
      basicStyling = basicStyling + " " + styles["eng-circle-spacing"];
      if (mustReadNotebooks.current.length < 1 || mustReadNotebooks.current[0] !== journalEntry) {
        let decoration = type === "trust" || type === "graces" ? styles["qc-trust"] : styles["qc-channel"];
        return basicStyling + " " + decoration;
      }
      // Class Assignment for guiding circles
      if (type === "trust" || type === "graces") {
        return mustReadNotebooks.current[0] === journalEntry
          ? basicStyling + " " + styles["qc-trust"] + " " + styles["qc-trust-animation"]
          : basicStyling + " " + styles["qc-trust"];
      } else {
        return mustReadNotebooks.current[0] === journalEntry
          ? basicStyling + " " + styles["qc-channel"] + " " + styles["qc-channel-animation"]
          : basicStyling + " " + styles["qc-channel"];
      }
    } else {
      // If the object isn't the next guiding quote
      if (mustReadNotebooks.current.length < 1 || mustReadNotebooks.current[0] !== journalEntry) {
        let decoration = type === "confianza" || type === "gracias" ? styles["qc-trust"] : styles["qc-channel"];
        return basicStyling + " " + decoration;
      }
      // Normal Assignment of classes for circles
      if (type === "confianza" || type === "gracias") {
        return mustReadNotebooks.current[0] === journalEntry
          ? basicStyling + " " + styles["qc-trust"] + " " + styles["qc-trust-animation"]
          : basicStyling + " " + styles["qc-trust"];
      } else {
        return mustReadNotebooks.current[0] === journalEntry
          ? basicStyling + " " + styles["qc-channel"] + " " + styles["qc-channel-animation"]
          : basicStyling + " " + styles["qc-channel"];
      }
    }
  };

  return (
    <div className={styles["dm-quotes-section"]}>
      <div id="dm-quotes-checkpoint" className={styles["dm-heading"]}>
        <h2 className={styles["section-title"]}>{dmFillers && dmFillers.quotesTitle}</h2>
        {/* Section for the Informational Icon */}

        <button
          onClick={() => setInformationIconStatus({ selected: !informationIconStatus.selected, hasSelected: true })}
          className={
            !informationIconStatus.hasSelected
              ? `${styles["information-icon"]} ${styles["icon-bounce"]}`
              : styles["information-icon"]
          }
        >
          <QuestionMarkGold />
        </button>
        {informationIconStatus.selected && (
          <div className={styles["information-msg-dm"]}>
            <p>{sources.informational + " "}</p>

            <div className={styles["information-icons"]}>
              {/* <i
                onClick={() => setInformationIconStatus({ ...informationIconStatus, selected: false })}
                style={{ color: "var(--cross-gold-300)", cursor: "pointer", position: "relative", top: ".25em" }}
                className="fa-solid fa-xmark"
              ></i> */}
              <FontAwesomeIcon
                onClick={() => setInformationIconStatus({ ...informationIconStatus, selected: false })}
                style={{ color: "var(--cross-gold-300)", cursor: "pointer", position: "relative", top: ".25em" }}
                icon={fas.faXmark}
              />
              <a href={sources.link} target="_blank">
                {/* <i
                  style={{ color: "var(--cross-gold-300)", cursor: "pointer" }}
                  className="fa-solid fa-arrow-up-right-from-square"
                ></i> */}
                <FontAwesomeIcon
                  style={{ color: "var(--cross-gold-300)", cursor: "pointer" }}
                  icon={fas.faArrowUpRightFromSquare}
                />
              </a>
            </div>
          </div>
        )}
      </div>
      <section className={styles["dm-quotes-center"]}>
        <div className={styles["dm-quote-circles"]}>
          {DMQuotes &&
            DMQuotes.map((obj) => {
              const circleStyles = DetermineCircleStyle(obj);
              return (
                <button key={obj.id} onClick={() => SelectCard(obj)} className={circleStyles}>
                  <div className={styles["dm-circle-qte-box"]}>
                    <p>{obj.type}</p>
                  </div>
                </button>
              );
            })}

          {displayCard && (
            <article key={selectedCard.id} className={styles["dm-quote-card"]}>
              {/* <i onClick={UnselectCard} className={`fa-solid fa-circle-arrow-left ${styles["card-arrow"]}`}></i> */}
              <FontAwesomeIcon onClick={UnselectCard} className={styles["card-arrow"]} icon={fas.faCircleArrowLeft} />
              <div className={`${styles["quote-title"]} ${styles["card-font"]}`}>
                <p>{dmFillers.dmJournalEntry + " [" + [selectedCard.journalEntry] + "]"}</p>
              </div>
              {/* <i className={"icon-holyTrinity " + styles["holyTrinity-icon"]}></i> */}
              <div className={styles["holyTrinity-icon"]}>
                <HolyTrinity />
              </div>
              <div className={styles["quote"]}>
                <p
                  className={
                    selectedCard.JQuote.length + selectedCard.fQuote.length > 799
                      ? `${styles["sm-font"]} ${styles["card-font"]}`
                      : styles["card-font"]
                  }
                >
                  {selectedCard.fQuote && selectedCard.fQuote}
                  <span className={styles["bold-text"]}>{selectedCard.JQuote}</span>
                </p>
              </div>
              <div className={styles["quote-description"]}>
                <div className={styles["quote-description-element"]}>
                  {windowDimensions.width > 330 ? (
                    <p className={styles["card-font"]}>{dmFillers.dmNotebook + " " + selectedCard.notebook}</p>
                  ) : (
                    <p>NB {selectedCard.notebook}</p>
                  )}
                </div>
                {/* sacred heart */}
                <SacredHeartOfJesus />
                <div className={`${styles["quote-description-element"]} ${styles["card-font"]}`}>
                  <p className={styles["dm-date"]}>{selectedCard.date}</p>
                </div>
              </div>
            </article>
          )}
        </div>
      </section>
    </div>
  );
};

export default memo(DivineMercyQuotes);
