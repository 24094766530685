import React, { useState, useContext, useEffect, useRef } from "react";
import { getImageUrlSV4 } from "./pages/components/utils/s3-content";
import ReactGA from "react-ga";
const AppContext = React.createContext();

const logoKeys = [
  { index: 1, key: "other/zeal-official.png" },
  { index: 2, key: "other/zeal-dm.svg" },
];

const HomePosterSVGKey = "other/zeal-official-poster.png";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

const AppProvider = ({ children }) => {
  const [homePage, setHomePage] = useState(true);
  const logoImagesRef = useRef();
  const HomePosterSVGRef = useRef();
  const DivineMercyLogoRef = useRef(null);
  const ReportedPageRef = useRef();

  // const [coloredZealLogo, setColoredZealLogo] = useState(false);
  // const [selectedMiracles, setSelectedMiracles] = useState([]);
  const selectedMiraclesRef = useRef([]);

  const mustReadNotebooks = useRef([
    "1146",
    "1182",
    "299",
    "1541",
    "1784",
    "206",
    "47-48",
    "50",
    "699",
    "1578",
    "1728",
    "378",
  ]);
  const allSelectedCards = useRef([]);
  const [selectedQuote, setSelectedQuote] = useState({ ind: null, qte: null });
  const [selectedConvos, setSelectedConvos] = useState([false, false, false, false]);
  const [initialConvoRendered, setInitialConvoRendered] = useState(false);

  // language is either english or spanish right now, controlled in navbar
  const [language, setLanguage] = useState("en");

  //carries width, height properties inside windowDimensions
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  //will trigger regathering of window properties
  function handleResize() {
    setWindowDimensions(getWindowDimensions());
  }
  //places eventlisteners to monitor window resizing;
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  //Overlay
  // const [htOlayRendered, setHtOlayRendered] = useState(false);
  const htOverlayRef = useRef(true);
  const dwOverlayRef = useRef(true);

  // Home page already visited
  const [medVidLoaded, setMedVidLoaded] = useState(false);

  //Retrieve the zeal logo (including dm logo)
  useEffect(() => {
    SignLogoImgUrls();
    GetPosterSVGUrl();
    const refereshLogos = setInterval(() => {
      if (logoImagesRef.current == []) {
        SignLogoImgUrls();
      }
    }, 1000);
    return () => clearInterval(refereshLogos);
  }, []);

  function SignLogoImgUrls() {
    const results = logoKeys.map(async ({ key }) => {
      return getImageUrlSV4(key);
    });
    Promise.all(results).then((signedImages) => {
      const logos = [];
      for (let i = 0; i < signedImages.length; i++) {
        logos.push(signedImages[i]);
      }
      logoImagesRef.current = logos;
    });
  }

  function GetPosterSVGUrl() {
    HomePosterSVGRef.current = getImageUrlSV4(HomePosterSVGKey);
  }

  function ReportToGA(path) {
    if (path != ReportedPageRef.current) {
      ReportedPageRef.current = path;
      if (path === "/") {
        ReactGA.pageview(path);
      } else {
        const pathArr = path.substring(1).split("/");
        ReactGA.pageview(pathArr[0]);
      }
    }
  }

  return (
    <AppContext.Provider
      value={{
        homePage,
        setHomePage,
        logoImagesRef,
        HomePosterSVGRef,
        DivineMercyLogoRef,
        ReportToGA,
        windowDimensions,
        mustReadNotebooks,
        selectedQuote,
        setSelectedQuote,
        selectedConvos,
        setSelectedConvos,
        initialConvoRendered,
        setInitialConvoRendered,
        allSelectedCards,
        selectedMiraclesRef,
        language,
        setLanguage,
        htOverlayRef,
        dwOverlayRef,
        medVidLoaded,
        setMedVidLoaded,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
