// Font Awesome Icon Inserts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@awesome.me/kit-72510df5aa/icons";

export const RetrieveIcon = ({ theme_id }) => {
  if (theme_id === 1 || theme_id === 8) {
    return <FontAwesomeIcon icon={fas.faSwordsLaser} />;
  } else if (theme_id === 2 || theme_id === 9) {
    return <FontAwesomeIcon icon={fas.faFireFlame} />;
  } else if (theme_id === 3 || theme_id === 10) {
    return <FontAwesomeIcon icon={fas.faGem} />;
  } else if (theme_id === 4 || theme_id === 11) {
    return <FontAwesomeIcon icon={fas.faSparkles} />;
  } else if (theme_id === 5 || theme_id === 12) {
    return <FontAwesomeIcon icon={fas.faFlatbread} />;
  } else if (theme_id === 6 || theme_id === 13) {
    return <FontAwesomeIcon icon={fas.faChessKnight} />;
  } else if (theme_id === 7 || theme_id === 14) {
    return <FontAwesomeIcon icon={fas.faSolarSystem} />;
  } else {
    return <FontAwesomeIcon icon={fas.faGem} />;
  }
};
