/* eslint-disable */
import React, { useEffect, useState, memo } from "react";
import { useLoaderData } from "react-router-dom";
import { useGlobalContext } from "../Context";
import PowerfulThemes from "./components/powerfulLessons/themes";
import PowerfulConversations from "./components/powerfulLessons/conversations";
import { FooterEnglish, FooterSpanish } from "../data/footer";
import Footer from "./components/footer.js";
import { getJSON } from "./components/utils/s3-content";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga";
import styles from "./styles/PowerfulLessons.module.css";

const JSONData = {
  englishKey: "powerful-lessons/lessons-english.json",
  spanishKey: "powerful-lessons/lessons-spanish.json",
};

const PowerfulLessons = () => {
  const { setLanguage, language, setHomePage, ReportToGA } = useGlobalContext();
  // const [data, setData] = useState(null);
  const [stopConversation, setStopConversation] = useState(false);
  const { data, lang } = useLoaderData();

  // const GetJSONData = async () => {
  //   if (language === "english") {
  //     //2. Call function to retrieve data from S3
  //     const result = await getJSON(JSONData["englishKey"]);
  //     setData(JSON.parse(result));
  //   } else {
  //     const result = await getJSON(JSONData["spanishKey"]);
  //     setData(JSON.parse(result));
  //   }
  // };

  // useEffect(() => {
  //   GetJSONData();
  // }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setHomePage(false);
    if (lang === "sp") {
      setLanguage("sp");
    } else {
      setLanguage("en");
    }
    ReportToGA(window.location.pathname);
  }, []);

  if (data) {
    return (
      <HelmetProvider>
        <Helmet>
          <title>{language === "en" ? "Letters & Scrolls" : "Cartas y Pergaminos"}</title>
          <meta name="description" content="Words from our Merciful God." />
          <meta
            name="keywords"
            content={[
              "God",
              "Dios",
              "Apostles",
              "Divine Mercy",
              "Divina Misericordia",
              "Apparitions",
              "Apariciones",
              "Love",
              "zeal",
              "christian",
              "christiano",
            ]}
          />
        </Helmet>
        <main className={styles["powerful-lessons-page"]}>
          {data && (
            <>
              {/* <section className={styles["powerful-conversations-section"]}>
                <PowerfulConversations
                  title={data.ConversationsTitle}
                  initialConversationData={data.InitialConversation}
                  welcomeBackConversation={data.WelcomeBackConversation}
                  conversationsData={data.PowerfulLessonsConversations}
                  stopConversation={stopConversation}
                  callBack={setStopConversation}
                />
              </section> */}
              <section className={styles["powerful-lessons-section"]}>
                <PowerfulThemes
                  apostolicFiller={data.ApostolicLessonsFiller}
                  themesProperties={data.ThemeProperties}
                  callBack={setStopConversation}
                />
              </section>
            </>
          )}
          <Footer theme={"light"} footer={language === "en" ? FooterEnglish : FooterSpanish} />
        </main>
      </HelmetProvider>
    );
  } else {
    return <div></div>;
  }
};

export async function PowerfulLessonsLoader({ params }) {
  // const { setLanguage, setHomePage, setColoredZealLogo } = useGlobalContext();
  var data = {};

  // Set the Language in Global Context, Query the data for the HT stories
  const GetJSONData = async () => {
    if (params.lang === "sp") {
      const result = await getJSON(JSONData["spanishKey"]);
      // console.log(JSON.parse(result));
      return JSON.parse(result);
    } else {
      const result = await getJSON(JSONData["englishKey"]);
      // console.log(JSON.parse(result));
      return JSON.parse(result);
    }
  };

  data.data = await GetJSONData();
  data.lang = params.lang;
  return data;
}

export default memo(PowerfulLessons);
