import React, { useState, memo } from "react";
import { getImageUrlSV4 } from "../utils/s3-content";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../../styles/DivineMercy.module.css";

// Font Awesome Icon Inserts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@awesome.me/kit-72510df5aa/icons";

const DivineMercyMemorial = ({ props, dmFillers }) => {
  const [selectedQuote, setSelectedQuote] = useState(0);
  const DMMemorialQuotes = props;
  const saintFaustina = getImageUrlSV4("our-greatest-shield/saint-faustina.jpg");
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToHeavenlyPowerPage = () => {
    // Extract the current url
    const url = location.pathname;
    const pathSegments = url.split("/");

    // Extract the language if applicable
    const potentialLang = pathSegments[pathSegments.length - 1];
    const lang = (potentialLang === "en") | (potentialLang === "sp") ? potentialLang : "en";

    // Examine the number of elements in list
    // You will navigate back (num_elements - 1) parent directores
    const pagesToNavigateBack = pathSegments.length - 1;
    const urlToNavigateTo = ".\\" + "..\\".repeat(pagesToNavigateBack) + `letters-and-scrolls\\${lang}`;
    navigate(urlToNavigateTo);
  };

  const changeSizeGrid = (id) => {
    var gridStyles = "";
    for (let i = 1; i <= DMMemorialQuotes.length; i = i + 1) {
      if (i === id && id !== selectedQuote) {
        gridStyles = gridStyles.concat("auto", " ");
      } else {
        gridStyles = gridStyles.concat("1fr", " ");
      }
    }
    setSelectedQuote((prevState) => {
      if (prevState === id) {
        setSelectedQuote(0);
      } else {
        setSelectedQuote(id);
      }
    });
    document.getElementById("memorial-quotes-container").style.gridTemplateRows = gridStyles;
  };

  return (
    <div className={styles["dm-mercy-memorial"]}>
      <h2 className={styles["section-title"]}> {dmFillers.memTitle} </h2>

      <div className={styles["attribute-container"]}>
        <article className={styles["card"]}>
          <div className={`${styles["card-side"]} ${styles["card-front"]}`}>
            <img src={saintFaustina} className={styles["obituary-memorial-img"]} alt={dmFillers.memCardFrontTitle} />
            <div className={styles["card-front-text"]}>
              <h5 className={styles["card-front-title"]}>{dmFillers.memCardFrontTitle} </h5>
              <h6 className={styles["card-front-subtitle"]}>{dmFillers.memCardFrontSubtitle} </h6>
            </div>
          </div>

          <div className={`${styles["card-side"]} ${styles["card-back"]}`}>
            <div className={styles["card-back-text"]}>
              <h6 className={styles["card-back-title"]}>{dmFillers.memCardBackTitle}</h6>
              <p className={styles["obituary-body-text"]}>{dmFillers.memCardBackContent}</p>
            </div>
          </div>
        </article>
        <div onClick={() => navigateToHeavenlyPowerPage("./../../heavenly-power")} className={styles["bible_link_box"]}>
          {/* <i className="fa-solid fa-book-bible"></i> */}
          <FontAwesomeIcon icon={fas.faBookBible} />
        </div>

        <div id="memorial-quotes-container" className={styles["memorial-quotes-container"]}>
          {DMMemorialQuotes &&
            DMMemorialQuotes.map((obj) => {
              const { id, notebook, journalEntry, date, fQuote, jQuote } = obj;
              const style =
                selectedQuote === id ? `${styles["memorial-quote"]} ${styles["view-quote"]}` : styles["memorial-quote"];

              return (
                <div key={id} onClick={() => changeSizeGrid(id)} className={style}>
                  <h5>
                    {/* {selectedQuote === id ? (
                      <i className={`fa-solid fa-crown ${styles["memorial-quote-caret-icon"]}`}></i>
                      <FontAwesomeIcon className={styles["memorial-quote-caret-icon"]} icon={fas.faCrown} />
                    ) : (
                      <i className={`fa-solid fa-crown ${styles["memorial-quote-caret-icon"]}`}></i>
                    )} */}
                    <FontAwesomeIcon className={styles["memorial-quote-caret-icon"]} icon={fas.faCrown} />
                    {dmFillers.dmJournalEntry + " [" + [journalEntry] + "]"}
                  </h5>
                  <p className={styles["memorial-body-text"]}>
                    {fQuote}
                    <span className={styles["bold"]}>{jQuote}</span>
                  </p>
                  <div className={styles["memorial-quote-description"]}>
                    <p className={styles["memorial-body-text"]}>{dmFillers.dmNotebook + " " + notebook}</p>
                    <p className={styles["memorial-body-text"]}>{date}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default memo(DivineMercyMemorial);
