/* eslint-disable */
import React, { useState, useEffect, useRef, memo } from "react";
import { useGlobalContext } from "../Context";
// Components
import { FooterEnglish, FooterSpanish } from "../data/footer";
import Footer from "./components/footer.js";
import { getImageUrlSV4, getJSON } from "./components/utils/s3-content";
import { HtImageKeys, HtJSONData, DwJSONData, ItalicsInParagraph, ItalicsInSources } from "./components/utils/content";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLoaderData } from "react-router-dom";

// CSS
import styles from "./styles/Article.module.css";

// Font Awesome Icon Inserts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, fad } from "@awesome.me/kit-72510df5aa/icons";

const LinkTitleMap = new Map([
  ["Links", "Sources"],
  ["Enlaces", "Fuentes"],
]);

const ToggleCollapse = (id) => {
  const btn = document.getElementById(`B${id}`);
  if (btn) {
    if (btn.style.background === "var(--cross-gold-200)") {
      btn.style.background = "#00958a";
      btn.style.rotate = "90deg";
    } else {
      btn.style.background = "var(--cross-gold-200)";
      btn.style.rotate = "0deg";
    }
  }

  const content = document.getElementById(id);
  if (content) {
    if (content.style.display === "none") {
      content.style.display = "block";
    } else {
      content.style.display = "none";
    }
  }
};

export const HtArticle = () => {
  const { windowDimensions, language, setLanguage, setHomePage, ReportToGA } = useGlobalContext();
  const [dataSt, setDataSt] = useState(false);
  const { lang, data, search_name, modal } = useLoaderData();

  useEffect(() => {
    window.scrollTo(0, 0);
    setHomePage(false);
    if (lang === "sp") {
      setLanguage("sp");
    } else {
      setLanguage("en");
    }
    ReportToGA(window.location.pathname);
  }, []);

  useEffect(() => {
    if (data.content) setDataSt(true);
  }, [data]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{data.eventName || "Article Not Found"}</title>
        <meta
          // name={`Zeal Article - ${title}`}
          name={data.eventName || "Article Not Found"}
          content={"Christian Article related to recent apparitions and artifacts."}
        />
        <meta
          name="keywords"
          content={[
            "Jesus",
            "Jesús",
            "God",
            "Christian",
            "Mercy",
            "misericordia",
            "Dios",
            "Shield",
            "Lord",
            "Love",
            "zeal",
            "king",
            "Dios",
            "amor",
          ]}
        />
      </Helmet>
      <main className={styles["article-page"]}>
        {dataSt ? (
          <section className={styles["article-section"]}>
            <div className={styles["article-nav"]}></div>
            <div className={styles["article-content"]}>
              {data.content.map((perspective, index) => {
                const { title, content } = perspective;
                const sourceTitle = LinkTitleMap.get(title);
                const collapseID = `S${index}`;
                return (
                  <article key={index}>
                    <h3 key={`T${index}`}>{sourceTitle || title}</h3>
                    {windowDimensions.width < 700 ? (
                      <>
                        <button
                          style={{ background: "var(--cross-gold-200)" }}
                          id={`B${collapseID}`}
                          onClick={() => ToggleCollapse(collapseID)}
                          className={styles["collapse-btn"]}
                        >
                          <i className="fa-duotone fa-ellipsis"></i>
                          <FontAwesomeIcon icon={fad.faEllipsis} />
                        </button>

                        <div id={collapseID} style={{ display: "none" }} className={styles["collapse-section"]}>
                          <Perspective paragraphs={content} key={index} pkey={index} title={title} />
                        </div>
                      </>
                    ) : (
                      <div className={styles["collapse-section"]}>
                        <Perspective paragraphs={content} key={index} pkey={index} title={title} />
                      </div>
                    )}
                  </article>
                );
              })}
            </div>
            {windowDimensions.width < 700 && <ArticleModal language={language} modal={modal} />}
          </section>
        ) : (
          <div className={styles["article-not-found"]}>
            <h4>
              {language === "sp"
                ? `Disculpe las molestias, pero no parece que contengamos el artículo '${search_name}'`
                : `Sorry for the inconvienence, but we don't seem to contain the article '${search_name}'`}
            </h4>
          </div>
        )}
      </main>
    </HelmetProvider>
  );
};

export const DwArticle = () => {
  const { windowDimensions, language, setLanguage, setHomePage, ReportToGA } = useGlobalContext();
  const [dataSt, setDataSt] = useState(false);
  const { lang, data, search_name, modal } = useLoaderData();

  useEffect(() => {
    window.scrollTo(0, 0);
    setHomePage(false);
    if (lang === "sp") {
      setLanguage("sp");
    } else {
      setLanguage("en");
    }
    ReportToGA(window.location.pathname);
  }, []);

  useEffect(() => {
    if (data.story) setDataSt(true);
  }, [data]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{data.title || "Article Not Found"}</title>
        <meta
          // name={`Zeal Article - ${title}`}
          name={data.title || "Article Not Found"}
          content={"Christian Article related to recent apparitions and artifacts."}
        />
        <meta
          name="keywords"
          content={[
            "Jesus",
            "Jesús",
            "God",
            "Christian",
            "Mercy",
            "misericordia",
            "Dios",
            "Shield",
            "Lord",
            "Love",
            "zeal",
            "king",
            "Dios",
            "amor",
          ]}
        />
      </Helmet>
      <main className={styles["article-page"]}>
        {dataSt ? (
          <section className={styles["article-section"]}>
            <div className={styles["article-nav"]}></div>
            <div className={styles["article-content"]}>
              <article key={0}>
                <h3 key={"T0"}>{data.title}</h3>
                {windowDimensions.width < 700 ? (
                  <>
                    <button
                      style={{ background: "var(--cross-gold-200)" }}
                      id={`BS0`}
                      onClick={() => ToggleCollapse(`S0`)}
                      className={styles["collapse-btn"]}
                    >
                      {/* <i className="fa-duotone fa-ellipsis"></i> */}
                      <FontAwesomeIcon icon={fad.faEllipsis} />
                    </button>
                    <div id={"S0"} style={{ display: "none" }} className={styles["collapse-section"]}>
                      <Perspective paragraphs={data.story} pkey={0} title={"na"} />
                    </div>
                  </>
                ) : (
                  <div className={styles["collapse-section"]}>
                    <Perspective paragraphs={data.story} pkey={0} title={"na"} />
                  </div>
                )}

                <h3 key={"T1"}>{language === "sp" ? "Fuentes" : "Sources"}</h3>
                {windowDimensions.width < 700 ? (
                  <>
                    <button
                      style={{ background: "var(--cross-gold-200)" }}
                      id={`BS1`}
                      onClick={() => ToggleCollapse(`S1`)}
                      className={styles["collapse-btn"]}
                    >
                      {/* <i className="fa-duotone fa-ellipsis"></i> */}
                      <FontAwesomeIcon icon={fad.faEllipsis} />
                    </button>
                    <div id={"S1"} style={{ display: "none" }} className={styles["collapse-section"]}>
                      <Perspective paragraphs={data.sources} pkey={1} title={"na"} dw_article={true} />
                    </div>
                  </>
                ) : (
                  <div className={styles["collapse-section"]}>
                    <Perspective paragraphs={data.sources} pkey={1} title={"na"} dw_article={true} />
                  </div>
                )}
              </article>
            </div>
            {windowDimensions.width < 700 && <ArticleModal language={language} modal={modal} />}
          </section>
        ) : (
          <div className={styles["article-not-found"]}>
            <h4>
              {language === "sp"
                ? `Disculpe las molestias, pero no parece que contengamos el artículo '${search_name}'`
                : `Sorry for the inconvienence, but we don't seem to contain the article '${search_name}'`}
            </h4>
          </div>
        )}
      </main>
    </HelmetProvider>
  );
};

const ArticleModal = ({ language, modal }) => {
  const [showModal, setShowModal] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowModal(false);
    }, [5000]);
    return () => clearTimeout(timeout);
  }, []);
  if (showModal) {
    return (
      <div className={styles["modal-container"]}>
        <img src={modal} alt={"modal"} />
        <div className={styles["modal-msg"]}>
          {language === "sp" ? (
            <>
              <p>Seleccione los botones</p>
              {/* <i className="fa-duotone fa-ellipsis"></i> */}
              <FontAwesomeIcon icon={fad.faEllipsis} />
              <p>para ver cada sección</p>
            </>
          ) : (
            <>
              <p>Select the ellipsis </p>
              {/* <i className="fa-duotone fa-ellipsis"></i> */}
              <FontAwesomeIcon icon={fad.faEllipsis} />
              <p>to view each section</p>
            </>
          )}
        </div>
        <button onClick={() => setShowModal(false)} className={styles["modal-x"]}>
          {/* <i className="fa-sharp fa-solid fa-circle-x"></i> */}
          <FontAwesomeIcon icon={fas.faCircleX} />
        </button>
      </div>
    );
  } else {
    return <></>;
  }
};

const Perspective = ({ paragraphs, pkey, title, dw_article = null }) => {
  // paragraphs is an array of objects {subtitle, text}
  const { language } = useGlobalContext();
  if (dw_article) {
    // For Sources in the dw_articles (uses `links` & `titles`)
    return (
      <>
        {paragraphs.map((obj, index) => {
          const { link, title } = obj;
          // "ht-back-links"
          if (link) {
            //Web Sources
            return (
              <div key={index}>
                <ItalicsInSources
                  content={index + 1 + ". " + title}
                  cls={styles["article-links"]}
                  url={link}
                  lang={language}
                />
              </div>
            );
          } else if (title.charAt(0) === "#") {
            //Book Sources, we still want numbered
            return (
              <div key={index}>
                <ItalicsInSources
                  content={index + 1 + ". " + title.substr(1, title.length - 1)}
                  cls={styles["article-links"]}
                />
              </div>
            );
          } else {
            //Photo credits or any comments we don't want to number
            return (
              <div key={index}>
                <ItalicsInSources content={title} cls={styles["article-links"]} />
              </div>
            );
          }
        })}
      </>
    );
  } else if (title != "Links" && title != "Enlaces") {
    // For stories both in ht_article and dw_article
    return (
      <>
        {paragraphs.map((obj, index) => {
          const { subTitle, text } = obj;

          return (
            <div key={pkey + index}>
              {subTitle && <ItalicsInParagraph content={subTitle} ct={true} className={styles["paragraph-subtitle"]} />}
              <ItalicsInParagraph content={text} key={index} pkey={index} className={styles["paragraph-text"]} />
            </div>
          );
        })}
      </>
    );
  } else {
    return (
      <>
        {paragraphs.map((obj, index) => {
          const { source, url } = obj;
          // "ht-back-links"
          if (url) {
            //Web Sources
            return (
              <div key={index}>
                <ItalicsInSources
                  content={index + 1 + ". " + source}
                  cls={styles["article-links"]}
                  url={url}
                  lang={language}
                />
              </div>
            );
          } else if (source.charAt(0) === "#") {
            //Book Sources, we still want numbered
            return (
              <div key={index}>
                <ItalicsInSources
                  content={index + 1 + ". " + source.substr(1, source.length - 1)}
                  cls={styles["article-links"]}
                />
              </div>
            );
          } else {
            //Photo credits or any comments we don't want to number
            return (
              <div key={index}>
                <ItalicsInSources content={source} cls={styles["article-links"]} />
              </div>
            );
          }
        })}
      </>
    );
  }
};

export async function HtArticleLoader({ params }) {
  var payload = {};

  const GetJSONData = async () => {
    if (params.lang === "sp") {
      const result = await getJSON(HtJSONData["spanishKey"]);
      let ht_objs = JSON.parse(result);
      ht_objs = ht_objs["Miracles"].filter((obj) => obj["image"] === params.name);
      return ht_objs.length > 0 ? ht_objs[0] : [];
    } else {
      const result = await getJSON(HtJSONData["englishKey"]);
      let ht_objs = JSON.parse(result);
      ht_objs = ht_objs["Miracles"].filter((obj) => obj["image"] === params.name);
      return ht_objs.length > 0 ? ht_objs[0] : [];
    }
  };

  // const SignImgUrls = () => {
  //   const signedUrlArr = HtImageKeys.filter((obj) => obj["name"] === params.name);
  //   return signedUrlArr.length > 0 ? getImageUrlSV4(signedUrlArr[0].key) : [];
  // };
  payload.search_name = params.name;
  payload.lang = params.lang;
  payload.data = await GetJSONData();
  payload.modal = getImageUrlSV4("other/ArticleModal.svg");

  console.log(payload);
  return payload;
}

export async function DwArticleLoader({ params }) {
  var payload = {};

  const GetJSONData = async () => {
    if (params.lang === "sp") {
      const result = await getJSON(DwJSONData["spanishKey"]);
      const dw_json = JSON.parse(result);
      let dw_objs = dw_json["MariamApparitionsData"].concat(dw_json["RosaryInspirationalData"]);
      dw_objs = dw_objs.filter((obj) => obj["img"] === params.name);
      return dw_objs.length > 0 ? dw_objs[0] : [];
    } else {
      const result = await getJSON(DwJSONData["englishKey"]);
      const dw_json = JSON.parse(result);
      let dw_objs = dw_json["MariamApparitionsData"].concat(dw_json["RosaryInspirationalData"]);
      dw_objs = dw_objs.filter((obj) => obj["img"] === params.name);
      return dw_objs.length > 0 ? dw_objs[0] : [];
    }
  };

  payload.search_name = params.name;
  payload.lang = params.lang;
  payload.data = await GetJSONData();
  payload.modal = getImageUrlSV4("other/ArticleModal.svg");

  return payload;
}

/*
1. Modify the image key in both insp & marian storys
2. Develop DWLoader function to query AWS for either eng or sp file
3. Merge both article sections into one array
4. filter articles by params.name
5. return similar data object 


*/
