import React, { useEffect, useState, lazy, Suspense, memo } from "react";
import Loading from "../fonts/svg/apparitions/Loading";
import styles from "./styles/Medjugorje.module.css";
import { useGlobalContext } from "../Context";
import { Link } from "react-router-dom";
import WesternAppVideo from "./WesternAppVideo";

// Font Awesome Icon Inserts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, fad, far } from "@awesome.me/kit-72510df5aa/icons";

const Medjugorje = ({ data }) => {
  const { language, windowDimensions } = useGlobalContext();
  const [app, setApp] = useState(0);

  // ** SUMMARY **
  // SumPane1 - The color lights, that are buttons for corresponding apparitions (comp)
  // SumPane2 - The video component and the apparitions (comp)
  // SumPane3 - The text summary of each apparation (comp)
  // VideoComp - Video for both big & small scrn (subcomp)
  // AppComp - Renders Apparation SVG's for big scrn only (comp)

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }, []);

  return (
    <div className={styles["sum-section"]}>
      <button className={styles["sum-home-btn"]}>
        <Link to="/">
          {/* <i className="fa-solid fa-house"></i> */}
          <FontAwesomeIcon icon={fas.faHouse} />
        </Link>
      </button>
      <SumInfoBtn app={app} />
      {windowDimensions.width < 1200 ? (
        <>
          <SumPane1 app={app} setApp={setApp} />
          <SumPane2 app={app} />
          {app > 0 && <SumPane3 data={data[app - 1]} />}
        </>
      ) : (
        <div className={styles["bg-sum-panes"]}>
          <SumPane1 app={app} setApp={setApp} />
          <div className={styles["bg-lt-sum-panes"]}>
            <VideoComp app={app} />
          </div>
          <div className={styles["bg-rt-sum-panes"]}>
            <AppComp app={app} />
          </div>
          {app > 0 && <SumPane3 data={data[app - 1]} />}
        </div>
      )}
    </div>
  );
};

const SumInfoBtn = ({ app }) => {
  const [moveInfoUp, setMoveInfoUp] = useState(false);
  const [sumInfoBtnCls, setSumInfoBtnCls] = useState(styles["sum-info-btn"] + " " + styles["vid-hdn"]);

  useEffect(() => {
    if (app > 0) {
      // setSumInfoBtnCls(styles["sum-info-btn"] + " " + styles["sum-info-btn-g"]);
      moveInfo(false);
    } else {
      setSumInfoBtnCls(styles["sum-info-btn"] + " " + styles["vid-hdn"]);
    }
  }, [app]);

  const moveInfo = (moveUp) => {
    if (moveUp) {
      document.getElementById("sum-info-btn").style.top = "24%";
      document.getElementById("sum-pane-3").style.top = "26%";
      setSumInfoBtnCls(styles["sum-info-btn"]);
    } else {
      document.getElementById("sum-info-btn").style.top = "90%";
      document.getElementById("sum-pane-3").style.top = "92%";
      setSumInfoBtnCls(styles["sum-info-btn"] + " " + styles["sum-info-btn-g"]);
    }
    setMoveInfoUp(moveUp);
  };

  return (
    <>
      {moveInfoUp ? (
        <button id={"sum-info-btn"} onClick={() => moveInfo(false)} className={sumInfoBtnCls}>
          {/* <i className="fa-regular fa-chevrons-down"></i> */}
          <FontAwesomeIcon icon={far.faHouse} />
        </button>
      ) : (
        <button id={"sum-info-btn"} onClick={() => moveInfo(true)} className={sumInfoBtnCls}>
          {/* <i className="fa-regular fa-chevrons-up"></i> */}
          <FontAwesomeIcon icon={far.faChevronsUp} />
        </button>
      )}
    </>
  );
};

const SumPane1 = ({ app, setApp }) => {
  const [appTrack, setAppTrack] = useState([false, false, false, false, false, false, false, false, false]);
  const [medVidLoadedSt, setMedVidLoadedSt] = useState(false);
  const { medVidLoaded } = useGlobalContext();

  useEffect(() => {
    if (medVidLoaded) setMedVidLoadedSt(true);
  }, [medVidLoaded]);

  const appArr = [
    [1, "green"],
    [2, "blue"],
    [3, "green"],
    [4, "blue"],
    [5, "green"],
    [6, "blue"],
    [7, "red"],
    [8, "purple"],
    [9, "blood"],
  ];

  const selectApp = (id) => {
    setApp(id);
    setAppTrack((appTrack) => {
      appTrack[id - 1] = true;
      return appTrack;
    });
  };

  if (medVidLoadedSt) {
    return (
      <div className={styles["sum-pane-1"]}>
        <div className={styles["sum-lights"]}>
          {appTrack &&
            appTrack.map((state, i) => {
              const id = appArr[i][0];
              const color = appArr[i][1];

              var css = styles[`light`] + " " + styles[`l${id}`];
              if (app === 0) css += " " + styles[`${color}-anim-${id}`]; //If nothing has been selected
              if (state) css += " " + styles[`${color}-light`]; //If state exist

              return (
                <div key={id} onClick={() => selectApp(id)} className={css}>
                  <span>{id}</span>
                </div>
              );
            })}
        </div>
      </div>
    );
  } else {
    return <div className={styles["sum-pane-1"]}>{/* <div className={styles["sum-lights"]}></div>{" "} */}</div>;
  }
};

const SumPane2 = ({ app }) => {
  const Apparitions = lazy(() => import("../fonts/svg/apparitions/Apparitions"));
  const [innApp, setInnApp] = useState(0);

  useEffect(() => {
    setInnApp(app);
  }, [app]);

  return (
    <div className={app > 0 ? styles["sum-pane-2"] : styles["sum-pane-2-vid"]}>
      {innApp > 0 ? (
        <Suspense
          fallback={
            <div className={styles["sum-loading"]}>
              <Loading />
            </div>
          }
        >
          <Apparitions id={innApp} />
        </Suspense>
      ) : (
        <Suspense
          fallback={
            <div className={styles["sum-loading"]}>
              <Loading />
            </div>
          }
        >
          <VideoComp app={innApp} />
        </Suspense>
      )}
    </div>
  );
};

const VideoComp = ({ app }) => {
  const { language, windowDimensions } = useGlobalContext();
  const [headingClass, setHeadingClass] = useState();

  useEffect(() => {
    const BgC = styles["sum-heading"] + " " + styles["sum-hd-bg"];
    const MbC = styles["sum-heading"] + " " + styles["sum-hd-mb-hdn"];
    const cls = app > 0 && windowDimensions.width < 1200 ? MbC : BgC;
    setHeadingClass(cls);
  }, [app]);

  return (
    <div style={{ top: "0" }} className={styles["sum-pane-2-vid"]}>
      <div
        style={{
          opacity: "0",
          animation: "OpacityOn 2.5s linear .5s forwards, darkBg 2s linear 3s forwards",
          // animation: "flicker 1.5s linear 1s forwards, darkBg 2s linear 3s forwards",
        }}
        className={headingClass}
      >
        {/* Gold Star color - #FFCC33 , Purple Star - #4900FC, Old Blue - #00958a */}
        <h4 style={{ color: "#FFCC33" }}>{language === "en" ? "Western Apparitions" : "Apariciones Occidentales"} &</h4>
        <h5 style={{ color: "#6545e8" }}>{language === "en" ? "Medjugorje" : "Medjugorje"}</h5>
        {/* "#03989e" */}
      </div>
      <WesternAppVideo />
    </div>
  );
};

const AppComp = ({ app }) => {
  const [innApp, setInnApp] = useState(0);

  useEffect(() => {
    setInnApp(app);
  }, [app]);

  const Apparitions = lazy(() => import("../fonts/svg/apparitions/Apparitions"));
  return (
    <div className={styles["sum-pane-2-app"]}>
      <Suspense
        fallback={
          <div className={styles["sum-loading"]}>
            <Loading />
          </div>
        }
      >
        <Apparitions id={innApp} />
      </Suspense>
    </div>
  );
};

const SumPane3 = ({ data }) => {
  const [appInfo, setAppInfo] = useState(null);
  const [subtitleCls, setSubtitleCls] = useState("sub-green");
  // green, blue, purple, red, gold
  const colors = ["rgb(2, 158, 148)", "var(--glory-blue-200)", "#545ee2", "#d30e0e", "#ccb200"];
  // for Bibilography and link colors
  const [themeColor, setThemeColor] = useState(colors[0]);

  useEffect(() => {
    setAppInfo(data);
  }, [data]);

  useEffect(() => {
    if (data["id"] === 9) {
      setThemeColor(colors[4]);
      setSubtitleCls(styles["sub-blood"]);
    } else if (data["id"] === 8) {
      setThemeColor(colors[2]);
      setSubtitleCls(styles["sub-purple"]);
    } else if (data["id"] === 7) {
      setThemeColor(colors[3]);
      setSubtitleCls(styles["sub-white"]);
    } else if (data["id"] % 2 === 1) {
      setThemeColor(colors[0]);
      setSubtitleCls(styles["sub-green"]);
    } else {
      setThemeColor(colors[1]);
      setSubtitleCls(styles["sub-blue"]);
    }
  }, [data]);

  return (
    <div id="sum-pane-3" className={styles["sum-pane-3"]}>
      {appInfo && (
        <div className={styles["sumName"]}>
          <h3>{appInfo["title"]}</h3>
          <h5 className={subtitleCls}>{appInfo["location"]}</h5>
        </div>
      )}

      {appInfo &&
        appInfo["story"].map((ele, i) => {
          return (
            <div key={i} className={styles["sumDesc"]}>
              <ArenaTxt txt={ele["txt"]} k={i} />
              <ArenaTxt ol={ele["ol"]} k={i} />
              <ArenaTxt subheader={ele["sumLinkSubhead"]} themeColor={themeColor} k={i} />
              <ArenaTxt bibliography={ele["bibliography"]} themeColor={themeColor} k={i} />
            </div>
          );
        })}
    </div>
  );
};

const ArenaTxt = ({ txt, ol, subheader, bibliography, k, themeColor }) => {
  if (txt) {
    let contentArr = txt.split("@");
    return (
      <p key={k}>
        {contentArr.map((txt, index) => {
          // normal, bold, italic condition statement
          if (index % 2 === 0) {
            return <span key={index}>{txt}</span>;
          } else if (txt.charAt(0) === "^") {
            return (
              <span key={index} style={{ fontWeight: "bold" }}>
                {txt.substr(1, txt.length - 1)}
              </span>
            );
          } else {
            return (
              <span key={index} style={{ fontStyle: "italic" }}>
                {txt}
              </span>
            );
          }
        })}
      </p>
    );
  } else if (ol) {
    return (
      <ol key={`${k}b`}>
        {ol.map((li, index) => {
          let contentArr = li.split("@");
          return (
            <li key={`l${index}`}>
              {contentArr.map((txt, index) => {
                // normal, bold, italic condition statement
                if (index % 2 === 0) {
                  return <span key={index}>{txt}</span>;
                } else if (txt.charAt(0) === "^") {
                  return (
                    <span key={index} style={{ fontWeight: "bold" }}>
                      {txt.substr(1, txt.length - 1)}
                    </span>
                  );
                } else {
                  return (
                    <span key={index} style={{ fontStyle: "italic" }}>
                      {txt}
                    </span>
                  );
                }
              })}
            </li>
          );
        })}
      </ol>
    );
  } else if (bibliography) {
    return (
      <div className={styles["sumLinks"]}>
        {bibliography.map((src, i) => {
          return <ArenaTxtLinks source={src.source} k={i} key={i} linkColor={themeColor} />;
        })}
      </div>
    );
  } else if (subheader) {
    return <h4 style={{ color: themeColor }}>{subheader}</h4>;
  }
};

const ArenaTxtLinks = ({ source, k, linkColor }) => {
  let contentArr = source.title.split("@");
  let linkStr = source.linkStr;
  let link = source.link;

  if (source.hasSublink) {
    return (
      <>
        <p key={k}>
          {contentArr.map((txt, index) => {
            // normal, bold, italic condition statement
            if (index % 2 === 0) {
              return <span key={index}>{txt}</span>;
            } else if (txt.charAt(0) === "*") {
              return (
                <span key={index} style={{ fontStyle: "italic", color: linkColor }}>
                  {txt.substr(1, txt.length - 1)}
                </span>
              );
            } else if (txt.charAt(0) === "^") {
              return (
                <span key={index} style={{ fontWeight: "bold" }}>
                  {txt.substr(1, txt.length - 1)}
                </span>
              );
            } else {
              return (
                <span key={index} style={{ fontStyle: "italic" }}>
                  {txt}
                </span>
              );
            }
          })}
        </p>
        <ul key={`${k}ul`}>
          {source.listlinks.map((lnk, i) => {
            const { title, link, linkstr } = lnk;
            return (
              <li key={i} style={{ fontStyle: "italic" }}>
                {title}
                <a href={link} style={{ color: linkColor }} target="_blank">
                  {" " + linkstr}
                </a>
              </li>
            );
          })}
        </ul>
      </>
    );
  } else {
    return (
      <p key={k}>
        {contentArr.map((txt, index) => {
          // normal, bold, italic condition statement
          if (index % 2 === 0) {
            return <span key={index}>{txt}</span>;
          } else if (txt.charAt(0) === "*") {
            return (
              <span key={index} style={{ fontStyle: "italic", color: linkColor }}>
                {txt.substr(1, txt.length - 1)}
              </span>
            );
          } else if (txt.charAt(0) === "^") {
            return (
              <span key={index} style={{ fontWeight: "bold" }}>
                {txt.substr(1, txt.length - 1)}
              </span>
            );
          } else {
            return (
              <span key={index} style={{ fontStyle: "italic" }}>
                {txt}
              </span>
            );
          }
        })}
        {linkStr && (
          <a href={" " + link} style={{ color: linkColor }} target="_blank">
            {linkStr}
          </a>
        )}
      </p>
    );
  }
};

export default memo(Medjugorje);
