import React from "react";

export const GetAmountSlideStoryByVertical = (element, bottomElement) => {
  let cardElementBtm = element.getBoundingClientRect().bottom;
  let buttonsBelow = bottomElement.getBoundingClientRect().top;
  let yMove = 0;
  if (cardElementBtm > buttonsBelow) {
    yMove = (buttonsBelow - cardElementBtm) * -1;
  } else {
    yMove = cardElementBtm - buttonsBelow;
  }
  return yMove;
};
