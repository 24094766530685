/* eslint-disable */
import React from "react";
import { ReactComponent as Svg } from "./StairCaseComplete.svg";
import styles from "./stairCaseComplete.module.css";

export const StairCaseComplete = () => {
  return (
    <svg
      id="staircasecomplete-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 400 400"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
    >
      <g id={styles["staircasecomplete-svg-staircase"]} transform="translate(.000001 0)">
        <g id="staircasecomplete-svg-bottomstep" transform="translate(0-267.922223)">
          <line
            id="staircasecomplete-svg-line1"
            x1="-10.752157"
            y1="10.92251"
            x2="10.752157"
            y2="-11.07749"
            transform="matrix(1.029171 0 0 1 112.752157 641.000287)"
            stroke="#00bff1"
            strokeOpacity="0.2"
            strokeMiterlimit="1"
          />
          <line
            id="staircasecomplete-svg-copy-of-line"
            x1="-10.752157"
            y1="10.92251"
            x2="10.752157"
            y2="-11.07749"
            transform="matrix(-1.029171 0 0 1 287.247841 641.000293)"
            stroke="#00bff1"
            strokeOpacity="0.2"
            strokeMiterlimit="1"
          />
          <rect
            id="staircasecomplete-svg-rect1"
            width="200"
            height="22"
            rx="0"
            ry="0"
            transform="translate(100 630)"
            opacity="0.25"
            fill="#00bff1"
            fillOpacity="0"
            stroke="#00bff1"
          />
          <polygon
            id="staircasecomplete-svg-copy-of-triangle-8"
            points="35,69.758723 19,85.758723 51,85.758723 35,69.758723"
            transform="matrix(-.707107-.707107-.707107 0.707107 374.389251 605.266891)"
            stroke="#000"
          />
          <polygon
            id="staircasecomplete-svg-copy-of-triangle-9"
            points="35,69.758723 19,85.758723 51,85.758723 35,69.758723"
            transform="matrix(.707107-.707107 0.707107 0.707107 25.610749 605.266892)"
            stroke="#000"
          />
        </g>
        <g id="staircasecomplete-svg-2ndstep" transform="translate(0-244)">
          <line
            id="staircasecomplete-svg-copy-of-line2"
            x1="-10.752157"
            y1="6.92251"
            x2="10.752157"
            y2="-11.07749"
            transform="matrix(1.025255-.089698 0.087156 0.996195 118.752157 574.000287)"
            stroke="#00bff1"
            strokeOpacity="0.2"
            strokeLinecap="round"
            strokeMiterlimit="1"
          />
          <rect
            id={styles["staircasecomplete-svg-rect2"]}
            width="185"
            height="20"
            rx="0"
            ry="0"
            transform="translate(108 563)"
            opacity="0.25"
            fill="#00bff1"
            fillOpacity="0"
            stroke="#00bff1"
          />
          <polygon
            id="staircasecomplete-svg-copy-of-triangle-6"
            points="34.5,69.758723 19,84.758723 50,84.758723 34.5,69.758723"
            transform="matrix(.707107-.707107 0.707107 0.707107 33.610749 536.266891)"
          />
          <polygon
            id="staircasecomplete-svg-copy-of-triangle-7"
            points="34.5,69.758723 19,84.758723 50,84.758723 34.5,69.758723"
            transform="matrix(-.707107-.707107-.707107 0.707107 367.389308 536.266891)"
          />
          <line
            id="staircasecomplete-svg-copy-of-line-2"
            x1="-10.752157"
            y1="6.92251"
            x2="10.752157"
            y2="-11.07749"
            transform="matrix(-1.025255-.089698-.087156 0.996195 282.613092 574.078301)"
            stroke="#00bff1"
            strokeOpacity="0.2"
            strokeLinecap="round"
            strokeMiterlimit="1"
          />
        </g>
        <g id="staircasecomplete-svg-3rdstep" transform="matrix(.94 0 0 0.94 12.03-249.68)">
          <line
            id="staircasecomplete-svg-copy-of-line3"
            x1="-10.752157"
            y1="6.92251"
            x2="10.752157"
            y2="-11.07749"
            transform="matrix(1.025255-.089698 0.087156 0.996195 118.752157 574.000287)"
            stroke="#00bff1"
            strokeOpacity="0.2"
            strokeLinecap="round"
            strokeMiterlimit="1"
          />
          <rect
            id={styles["staircasecomplete-svg-rect22"]}
            width="185"
            height="20"
            rx="0"
            ry="0"
            transform="translate(108 563)"
            opacity="0.25"
            fill="#00bff1"
            fillOpacity="0"
            stroke="#00bff1"
          />
          <polygon
            id="staircasecomplete-svg-copy-of-triangle-62"
            points="34.5,69.758723 19,84.758723 50,84.758723 34.5,69.758723"
            transform="matrix(.707107-.707107 0.707107 0.707107 33.610749 536.266891)"
          />
          <polygon
            id="staircasecomplete-svg-copy-of-triangle-72"
            points="34.5,69.758723 19,84.758723 50,84.758723 34.5,69.758723"
            transform="matrix(-.707107-.707107-.707107 0.707107 367.389308 536.266891)"
          />
          <line
            id="staircasecomplete-svg-copy-of-line-22"
            x1="-10.752157"
            y1="6.92251"
            x2="10.752157"
            y2="-11.07749"
            transform="matrix(-1.025255-.089698-.087156 0.996195 282.613092 574.078301)"
            stroke="#00bff1"
            strokeOpacity="0.2"
            strokeLinecap="round"
            strokeMiterlimit="1"
          />
        </g>
        <g id="staircasecomplete-svg-4thstep" transform="translate(0-190)">
          <rect
            id={styles["staircasecomplete-svg-rect4"]}
            width="155"
            height="15"
            rx="0"
            ry="0"
            transform="translate(122.857866 426.648329)"
            opacity="0.25"
            fill="#00bff1"
            fillOpacity="0"
            stroke="#00bff1"
          />
          <polygon
            id="staircasecomplete-svg-copy-of-triangle-4"
            points="30.5,69.758723 19,81.758723 42,81.758723 30.5,69.758723"
            transform="matrix(.707107 0.707107-.707107 0.707107 306.962904 354.769425)"
          />
          <polygon
            id="staircasecomplete-svg-copy-of-triangle-5"
            points="31,69.758723 19,81.758723 43,81.758723 31,69.758723"
            transform="matrix(.707107-.707107 0.707107 0.707107 50.610691 398.266903)"
          />
          <line
            id="staircasecomplete-svg-copy-of-line-5"
            x1="-10.752157"
            y1="-10.07749"
            x2="10.752157"
            y2="-11.07749"
            transform="matrix(.721369-.660474 0.642902 0.702176 136.869979 442.110899)"
            stroke="#00bff1"
            strokeOpacity="0.2"
            strokeLinecap="round"
            strokeMiterlimit="1"
          />
          <line
            id="staircasecomplete-svg-copy-of-line-6"
            x1="-10.752157"
            y1="-10.053189"
            x2="9.632848"
            y2="-11.07749"
            transform="matrix(-.725599-.655825-.638376 0.706293 262.935826 441.351162)"
            stroke="#00bff1"
            strokeOpacity="0.2"
            strokeLinecap="round"
            strokeMiterlimit="1"
          />
        </g>
        <g id="staircasecomplete-svg-5thstep" transform="matrix(.89 0 0 0.94 22.055-201.93)">
          <rect
            id={styles["staircasecomplete-svg-rect5"]}
            width="155"
            height="15"
            rx="0"
            ry="0"
            transform="translate(122.857866 426.648329)"
            opacity="0.25"
            fill="#00bff1"
            fillOpacity="0"
            stroke="#00bff1"
          />
          <polygon
            id="staircasecomplete-svg-copy-of-triangle-42"
            points="30.5,69.758723 19,81.758723 42,81.758723 30.5,69.758723"
            transform="matrix(.707107 0.707107-.707107 0.707107 306.962904 354.769425)"
          />
          <polygon
            id="staircasecomplete-svg-copy-of-triangle-52"
            points="31,69.758723 19,81.758723 43,81.758723 31,69.758723"
            transform="matrix(.707107-.707107 0.707107 0.707107 50.610691 398.266903)"
          />
          <line
            id="staircasecomplete-svg-copy-of-line-52"
            x1="-10.752157"
            y1="-10.07749"
            x2="10.752157"
            y2="-11.07749"
            transform="matrix(.721369-.660474 0.642902 0.702176 136.869979 442.110899)"
            stroke="#00bff1"
            strokeOpacity="0.2"
            strokeLinecap="round"
            strokeMiterlimit="1"
          />
          <line
            id="staircasecomplete-svg-copy-of-line-62"
            x1="-10.752157"
            y1="-10.053189"
            x2="9.632848"
            y2="-11.07749"
            transform="matrix(-.725599-.655825-.638376 0.706293 262.935826 441.351162)"
            stroke="#00bff1"
            strokeOpacity="0.2"
            strokeLinecap="round"
            strokeMiterlimit="1"
          />
        </g>
        <g id="staircasecomplete-svg-6thstep" transform="matrix(.8 0 0 0.84 40.1-192.48)">
          <rect
            id={styles["staircasecomplete-svg-rect6"]}
            width="155"
            height="15"
            rx="0"
            ry="0"
            transform="translate(122.857866 426.648329)"
            opacity="0.25"
            fill="#00bff1"
            fillOpacity="0"
            stroke="#00bff1"
          />
          <line
            id="staircasecomplete-svg-copy-of-line-53"
            x1="-10.752157"
            y1="-10.07749"
            x2="10.752157"
            y2="-11.07749"
            transform="matrix(.721369-.660474 0.642902 0.702176 136.869979 442.110899)"
            stroke="#00bff1"
            strokeOpacity="0.2"
            strokeLinecap="round"
            strokeMiterlimit="1"
          />
          <line
            id="staircasecomplete-svg-copy-of-line-63"
            x1="-10.752157"
            y1="-10.053189"
            x2="9.632848"
            y2="-11.07749"
            transform="matrix(-.725599-.655825-.638376 0.706293 262.935826 441.351162)"
            stroke="#00bff1"
            strokeOpacity="0.2"
            strokeLinecap="round"
            strokeMiterlimit="1"
          />
          <polygon
            id="staircasecomplete-svg-copy-of-triangle-53"
            points="31,69.758723 19,81.758723 43,81.758723 31,69.758723"
            transform="matrix(.707107-.707107 0.707107 0.707107 50.610691 398.266903)"
          />
          <polygon
            id="staircasecomplete-svg-copy-of-triangle-63"
            points="31,69.758723 19,81.758723 43,81.758723 31,69.758723"
            transform="matrix(.707107 0.707107-.707107 0.707107 306.08489 353.842593)"
          />
        </g>
        <g id="staircasecomplete-svg-7thstep" transform="matrix(.72 0 0 0.7 56.14-164.65)">
          <rect
            id={styles["staircasecomplete-svg-rect7"]}
            width="155"
            height="15"
            rx="0"
            ry="0"
            transform="translate(122.857866 426.648329)"
            opacity="0.25"
            fill="#00bff1"
            fillOpacity="0"
            stroke="#00bff1"
          />
          <polygon
            id="staircasecomplete-svg-copy-of-triangle-43"
            points="30.5,69.758723 19,81.758723 42,81.758723 30.5,69.758723"
            transform="matrix(.707107 0.707107-.707107 0.707107 306.962904 354.769425)"
          />
          <polygon
            id="staircasecomplete-svg-copy-of-triangle-54"
            points="31,69.758723 19,81.758723 43,81.758723 31,69.758723"
            transform="matrix(.707107-.707107 0.707107 0.707107 50.610691 398.266903)"
          />
          <line
            id="staircasecomplete-svg-copy-of-line-54"
            x1="-10.752157"
            y1="-10.07749"
            x2="10.752157"
            y2="-11.07749"
            transform="matrix(.721369-.660474 0.642902 0.702176 136.869979 442.110899)"
            stroke="#00bff1"
            strokeOpacity="0.2"
            strokeLinecap="round"
            strokeMiterlimit="1"
          />
          <line
            id="staircasecomplete-svg-copy-of-line-64"
            x1="-10.752157"
            y1="-10.053189"
            x2="9.632848"
            y2="-11.07749"
            transform="matrix(-.725599-.655825-.638376 0.706293 262.935826 441.351162)"
            stroke="#00bff1"
            strokeOpacity="0.2"
            strokeLinecap="round"
            strokeMiterlimit="1"
          />
        </g>
      </g>
      <g id="staircasecomplete-svg-leftrail">
        <line
          id={styles["staircasecomplete-svg-line2"]}
          x1="0"
          y1="42.986148"
          x2="-0.746896"
          y2="-46.106236"
          transform="translate(88.372643 344.79924)"
          fill="#00bff1"
          stroke="#00bff1"
          strokeWidth="3"
          strokeDashoffset="89.1"
          strokeDasharray="89.095515"
        />
        <line
          id={styles["staircasecomplete-svg-rail"]}
          x1="0"
          y1="42.986148"
          x2="0"
          y2="-42.986148"
          transform="matrix(.961262 0.275637-.581902 2.029335 112.656916 185.295923)"
          fill="#00bff1"
          stroke="#00bff1"
          strokeWidth="3"
          strokeDashoffset="85.972296"
          strokeDasharray="85.972296"
        />
        <path
          id={styles["staircasecomplete-svg-handle"]}
          d="M28.65534,62.973699c.142454,16.053861,30.409269,14.764029,36.897178,2.201058s-3.40217-27.653655-18.832914-23.841864-11.308379,24.480722.190344,24.571361s17.097085-14.187379,5.802193-17.857312-10.526623,12.351624-2.413104,8.097915"
          transform="matrix(-.951057 0.309017-.309017-.951057 134.338538 323.56577)"
          fill="none"
          stroke="#00bff1"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDashoffset="180"
          strokeDasharray="180"
        />
      </g>
      <g id="staircasecomplete-svg-copy-of-leftrail" transform="matrix(-1 0 0 1 399.999994 0.00001)">
        <line
          id={styles["staircasecomplete-svg-line3"]}
          x1="0"
          y1="42.986148"
          x2="-0.746896"
          y2="-46.106236"
          transform="translate(88.372643 344.79924)"
          fill="#00bff1"
          stroke="#00bff1"
          strokeWidth="3"
          strokeDashoffset="89.1"
          strokeDasharray="89.095515"
        />
        <line
          id={styles["staircasecomplete-svg-rail2"]}
          x1="0"
          y1="42.986148"
          x2="0"
          y2="-42.986148"
          transform="matrix(.961262 0.275637-.581902 2.029335 112.656916 185.295923)"
          fill="#00bff1"
          stroke="#00bff1"
          strokeWidth="3"
          strokeDashoffset="85.972296"
          strokeDasharray="85.972296"
        />
        <path
          id={styles["staircasecomplete-svg-handle2"]}
          d="M28.65534,62.973699c.142454,16.053861,30.409269,14.764029,36.897178,2.201058s-3.40217-27.653655-18.832914-23.841864-11.308379,24.480722.190344,24.571361s17.097085-14.187379,5.802193-17.857312-10.526623,12.351624-2.413104,8.097915"
          transform="matrix(-.951057 0.309017-.309017-.951057 134.338538 323.56577)"
          fill="none"
          stroke="#00bff1"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDashoffset="180"
          strokeDasharray="180"
        />
      </g>
    </svg>
  );
};
