export const FooterEnglish = {
  alertMessage: "Email Copied To Clipboard Clipboard",
  connectTitle: "Connect with us",
  referencesTitle: "References & Attributions",
  supportTitle: "Support us",
  copyRightTitle: "This work is under the protection of Creative Commons License CC BY-NC-SA.",
  copyRightMsg:
    "The work of Zeal is intended to spread the message of Divine Mercy, Gospel, Devotion to our Blessed Mother, and knowledge of Catholic Historical Events. Therefore, using the work of Zeal under good faith for non-commercial purposes is encouraged as long as attribution is given.",
};

export const FooterSpanish = {
  alertMessage: "Correo electrónico copiado al portapapeles",
  connectTitle: "Conecta con nosotros",
  referencesTitle: "Referencias y atribuciones",
  supportTitle: "Apoyanos",
  copyRightTitle: "Esta obra está bajo la protección de Creative Commons License CC BY-NC-SA.",
  copyRightMsg:
    "La obra de Zeal está destinada a difundir el mensaje de la Divina Misericordia, el Evangelio, la Devoción a nuestra Santísima Madre y el conocimiento de los Acontecimientos Históricos Católicos.",
};
