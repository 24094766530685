import React from "react";

export const SupportEnglish = {
  SupportTitle: "Support Us",
  Greeting:
    "Donations of any size are greatly appreciated. 100% of all donations will be used for the funding of servers to host the website. The direct impact of any donation is as follows (USD currency):",
  DonationImpact: [
    "$1 - Serves the project to 2,000 more viewers",
    "$5 - Serves the project to 10,000 more viewers",
    "$20 - Serves the project to 40,000 more viewers",
    "$50 - Serves the project to 100,000 more viewers",
  ],
  DonationAmount: "Donation Amount",
  DonationCurrency: "Donation Currency",
  DonationCompleted:
    "Thank you for your support in helping us spread Divine Mercy, Devotion to the Rosary, and building the faith. Don't forget to follow us on social media where we will give notifications regarding new miraculous stories.",
  Ready: "Donate",

  ContactTitle: "Contact Us",
};

export const SupportSpanish = {
  SupportTitle: "Apoyanos",
  Greeting:
    "Las donaciones de cualquier tamaño son muy apreciadas. El 100% de todas las donaciones se utilizará para la financiación de servidores para alojar el sitio web. El impacto directo de cualquier donación es el siguiente (moneda USD):",
  DonationImpact: [
    "$1 - Sirve el proyecto a 10 personas más.",
    "$5 - Sirve el proyecto a 50 personas más.",
    "$20 - Sirve el proyecto a 200 personas más.",
    "$50 - Sirve el proyecto a 500 personas más.",
  ],
  DonationAmount: "Monto de donación",
  DonationCurrency: "Moneda de donación",
  DonationCompleted:
    "Gracias por su apoyo para ayudarnos a difundir la Divina Misericordia, la Devoción al Rosario y la edificación de la fe. No olvides seguirnos en las redes sociales donde daremos notificaciones sobre nuevas historias milagrosas.",
  Ready: "Donar",
  ContactTitle: "Contacta con nosotros",
};
