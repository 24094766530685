import React, { useState, useEffect, memo } from "react";
import { useGlobalContext } from "../../../Context";
import { First5SatHeart } from "../../../fonts/svg/first5SatHeart";
import { FlameOfLoveHeart } from "../../../fonts/svg/flameOfLoveHeart";
import { ItalicsInParagraph } from "../heavenlyTraces/ht-card";
// import { RosaryArrows } from "./rosary-arrows";

// Font Awesome Icon Inserts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, fad } from "@awesome.me/kit-72510df5aa/icons";

import styles from "../../styles/Rosary.module.css";

const CardHeader = ({ other, setDisplayInfo }) => {
  return (
    <div className={styles["rep-back-header"]}>
      {/* <i
        onClick={() => setDisplayInfo(0)}
        style={{ color: other.color, justifySelf: "center", marginTop: ".3em" }}
        className="fa-solid fa-circle-arrow-left"
      ></i> */}
      <FontAwesomeIcon
        onClick={() => setDisplayInfo(0)}
        style={{ color: other.color, justifySelf: "center", marginTop: ".3em" }}
        icon={fas.faCircleArrowLeft}
      />
      <p className={styles["rep-back-title"]}>{other.setting}</p>
      <a href={other.link} target="_blank">
        {/* <i style={{ color: other.color }} className="fa-solid fa-arrow-up-right-from-square"></i> */}
        <FontAwesomeIcon style={{ color: other.color }} icon={fas.faArrowUpRightFromSquare} />
      </a>
    </div>
  );
};

const FirstFiveSat = ({ data, other }) => {
  const [displayInfo, setDisplayInfo] = useState(0);
  return (
    <div className={styles[other.class] + " " + styles["rep-card"]}>
      {displayInfo === 1 ? (
        <div className={styles["rep-back-card"]}>
          <CardHeader other={other} setDisplayInfo={setDisplayInfo} />
          <div className={styles["rep-info-text-body"]}>
            {data.map((obj, index) => {
              const { JesusSpeaking, MarySpeaking, text } = obj;
              let paragraphText = styles["rep-txt"];
              if (JesusSpeaking) {
                paragraphText += " " + styles["bold-Jesus"];
              } else if (MarySpeaking) {
                paragraphText += " " + styles["bold-Mary"];
              }
              return <ItalicsInParagraph key={index} content={text} indent={false} className={paragraphText} />;
            })}
          </div>
        </div>
      ) : (
        <div className={styles["rep-front-card"]}>
          <First5SatHeart />
          <button className={styles["first5SatBtn"]} onClick={() => setDisplayInfo(1)}>
            {other.name}
          </button>
        </div>
      )}
    </div>
  );
};
const FlameOfLove = ({ data, other }) => {
  const [displayInfo, setDisplayInfo] = useState(0);
  return (
    <div className={styles[other.class] + " " + styles["rep-card"]}>
      {displayInfo === 1 ? (
        <div className={styles["rep-back-card"]}>
          <CardHeader other={other} setDisplayInfo={setDisplayInfo} />
          <div className={styles["rep-info-text-body"]}>
            {data.map((obj, index) => {
              if (index !== data.length - 1) {
                return (
                  <div key={index} className={styles["rep-txt"]}>
                    {/* <span className={index === data.length - 2 ? "italic" : ""}>{obj[0]}</span> */}
                    <ItalicsInParagraph key={index} content={obj[0]} indent={false} />
                    <span className={styles["bold-Mary"]}>{obj[1]}</span>
                  </div>
                );
              } else {
                return (
                  <div key={index}>
                    <p
                      style={{ marginBottom: ".1em", marginTop: "0" }}
                      className={styles["rep-txt"] + " " + styles["italic"] + " " + styles["bold-Jesus"]}
                    >
                      {obj[0]}
                    </p>
                    <p
                      style={{ marginBottom: ".1em", marginTop: "0" }}
                      className={styles["rep-txt"] + " " + styles["italic"] + " " + styles["bold-Jesus"]}
                    >
                      {obj[1]}
                    </p>
                    <p
                      style={{ marginBottom: ".1em", marginTop: "0" }}
                      className={styles["rep-txt"] + " " + styles["italic"] + " " + styles["bold-Jesus"]}
                    >
                      {obj[2]}
                    </p>
                    <p
                      style={{ marginBottom: ".1em", marginTop: "0" }}
                      className={styles["rep-txt"] + " " + styles["italic"] + " " + styles["bold-Jesus"]}
                    >
                      {obj[3]}
                    </p>
                    <p
                      style={{ marginBottom: ".1em", marginTop: "0" }}
                      className={styles["rep-txt"] + " " + styles["italic"] + " " + styles["bold-Jesus"]}
                    >
                      {obj[4]}
                    </p>
                    <p
                      style={{ marginBottom: ".1em", marginTop: "0" }}
                      className={styles["rep-txt"] + " " + styles["italic"] + " " + styles["bold-Jesus"]}
                    >
                      {obj[5]}
                    </p>
                    <p
                      style={{ marginBottom: ".1em", marginTop: "0" }}
                      className={styles["rep-txt"] + " " + styles["italic"] + " " + styles["bold-Jesus"]}
                    >
                      {obj[6]}
                    </p>
                    <p
                      style={{ marginBottom: ".1em", marginTop: "0" }}
                      className={styles["rep-txt"] + " " + styles["italic"] + " " + styles["bold-Jesus"]}
                    >
                      {obj[7]}
                    </p>
                  </div>
                );
              }
            })}
          </div>
        </div>
      ) : (
        <div className={styles["rep-front-card"]}>
          <FlameOfLoveHeart />
          <button className={styles["flameOfLoveBtn"]} onClick={() => setDisplayInfo(1)}>
            {other.name}
          </button>
        </div>
      )}
    </div>
  );
};

const ReparationsCard = ({ data, other }) => {
  if (other.class === "first5-saturdays") {
    return <FirstFiveSat data={data} other={other} />;
  } else {
    return <FlameOfLove data={data} other={other} />;
  }
};

const Reparations = ({ reparations }) => {
  const { title, firstFiveSaturdaysOther, flameOfImmaculateHeartOther, firstFiveSaturdays, flameOfImmaculateHeart } =
    reparations;
  //0 -> first5sat, 1 -> flameOfLove
  const [repMobileScreen, setRepMobileScreen] = useState(false);
  const [cardSelection, setCardSelection] = useState(true);
  const { windowDimensions } = useGlobalContext();
  useEffect(() => {
    if (windowDimensions.width < 800) {
      setRepMobileScreen(true);
    } else {
      setRepMobileScreen(false);
    }
  }, [windowDimensions.width]);

  return (
    <div className={styles["reparations-section"]}>
      <h1 className={styles["section-title"]}>{title && title}</h1>
      <div className={styles["reparations-container"]}>
        {repMobileScreen ? (
          <ReparationsCard
            data={cardSelection ? firstFiveSaturdays : flameOfImmaculateHeart}
            other={cardSelection ? firstFiveSaturdaysOther : flameOfImmaculateHeartOther}
          />
        ) : (
          <>
            <ReparationsCard data={firstFiveSaturdays} other={firstFiveSaturdaysOther} />
            <ReparationsCard data={flameOfImmaculateHeart} other={flameOfImmaculateHeartOther} />
          </>
        )}
      </div>
      {repMobileScreen && (
        <div
          style={{ marginTop: "1.2em" }}
          className={styles["message-container"]}
          onClick={() =>
            setCardSelection((prevState) => {
              return !prevState;
            })
          }
        >
          {/* <i
            style={{ color: "var(--cross-gold-300)", marginBottom: ".25em" }}
            className={"fa-duotone fa-fire " + styles["fire"]}
          ></i> */}
          {cardSelection ? (
            <FontAwesomeIcon
              style={{ color: "var(--orange)", marginBottom: ".25em" }}
              className={styles["fire"]}
              icon={fad.faFire}
            />
          ) : (
            <FontAwesomeIcon
              style={{ color: "var(--cross-gold-300)", marginBottom: ".25em" }}
              className={styles["clock"]}
              icon={fas.faClockFive}
            />
          )}

          <div className={styles["message"]}>
            <p> {cardSelection ? flameOfImmaculateHeartOther.arrowMessage : firstFiveSaturdaysOther.arrowMessage}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(Reparations);
