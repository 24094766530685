import React, { useState, useEffect, memo } from "react";
import { useGlobalContext } from "../../../Context";
import Card from "./ht-card";
import { GetAmountSlideStoryByVertical } from "../utils/moveStoriesVertically";
import { ParagraphParsing } from "../utils/paragraphParsing";
import styles from "../../styles/HeavenlyTraces.module.css";

// Font Awesome Icon Inserts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, fad } from "@awesome.me/kit-72510df5aa/icons";

const OverlayData = {
  1: {
    st1: "Regarding the articles of this page @(each card contains the story, research, and sources relating to it)@, it's important to communicate that several stories should be emphasized, more than the rest and are the following:",
    st2: [
      "Our Queen of Peace, Medjugorje",
      "Our Lady of Mount Carmel, Garabandal",
      "(U.S.) - Son of the Republic, Philadelphia",
    ],
    st3: "Select the X to continue",
  },
  2: {
    st1: "Respecto a los artículos de esta página @(cada ficha contiene la historia, la investigación y las fuentes relacionadas con la misma)@, es importante comunicar que se deben destacar varias historias, más que el resto y son las siguientes:",
    st2: [
      "Nuestra Reina de la Paz, Medjugorje",
      "Nuestra Señora del Monte Carmen, Garabandal",
      "(Estados Unidos) - Hijo de la República, Filadelfia",
    ],
    st3: "Seleccione la X para continuar",
  },
};

const HeavenlyEvents = ({ props, title, images }) => {
  const { language, windowDimensions, selectedMiraclesRef } = useGlobalContext();
  const [isMobileScreen, setStoryMobileScreen] = useState(false);
  const [selectedStory, setSelectedStory] = useState(0);

  const htEvents = props;

  useEffect(() => {
    const loaded_miracles = [];
    htEvents.map((obj) => {
      loaded_miracles.push(false);
    });
    selectedMiraclesRef.current = loaded_miracles;
  }, [props]);

  useEffect(() => {
    if (windowDimensions.width < 715) {
      setStoryMobileScreen(true);
    } else {
      setStoryMobileScreen(false);
    }
  }, [windowDimensions.width]);

  const scrollStoryIntoView = (id) => {
    selectedMiraclesRef.current[id] = true;
    // setSelectedMiracles(selectedMiracles);
    setSelectedStory(id);
    var container = document.querySelector(`#heavenly-traces-container`);
    var element = document.querySelector(`#trace-${id}`);
    var btmElement = document.querySelector("#heavenly-traces-btns");
    let yMove = GetAmountSlideStoryByVertical(element, btmElement);
    // add 20 for padding between buttons and card
    yMove += 20;
    container.scrollBy(0, yMove);
  };

  const CardVisitedCallback = (index) => {
    selectedMiraclesRef.current[index] = true;
    // setSelectedMiracles(selectedMiracles);
    setSelectedStory(index);
  };

  return (
    <>
      <section className={styles["heavenly-traces-center"]}>
        {/* <h1 className="section-title">{title}</h1> */}
        <Overlay isMobileScreen={isMobileScreen} />
        <div id="heavenly-traces-container" className={styles["heavenly-flex-container"]}>
          {htEvents.map((event, ind) => {
            return (
              //Test how images gets index with new code
              <Card
                key={ind}
                props={event}
                image={images.get(event.id)}
                index={ind}
                cardVisitedCallback={CardVisitedCallback}
                language={language}
              />
            );
          })}
        </div>
        <div id="heavenly-traces-btns" className={styles["heavenly-traces-btns"]}>
          {isMobileScreen &&
            htEvents.map((obj, index) => {
              let classStyle =
                selectedStory === index
                  ? styles["ht-story-btn-s"] + " " + styles["ht-story-btn"]
                  : styles["ht-story-btn-ns"] + " " + styles["ht-story-btn"];

              if (selectedStory !== index && selectedMiraclesRef[index]) {
                classStyle = styles["ht-story-btn-s-before"] + " " + styles["ht-story-btn"];
              }

              return <div onClick={() => scrollStoryIntoView(index)} key={index} className={classStyle}></div>;
            })}
        </div>
      </section>
    </>
  );
};

const Overlay = memo(({ isMobileScreen }) => {
  const { language, htOverlayRef } = useGlobalContext();
  const [htOverlaySt, setHtOverlaySt] = useState(htOverlayRef.current);

  const removeOverlay = () => {
    setHtOverlaySt(false);
    htOverlayRef.current = false;
  };

  if (htOverlaySt) {
    return (
      <div id="overlayX" className={styles["ht-overlay"]}>
        <ParagraphParsing
          paragraph={OverlayData[1]["st1"]}
          // txtClass={styles["rosary-context-info-full"]}
        />
        <ul className={styles["ht-overlay-list"]}>
          {OverlayData[1]["st2"].map((story, index) => {
            if (index == OverlayData[1]["st2"].length - 1) {
              const [comment, title] = story.split("-");
              return (
                <li key={index}>
                  <span style={{ fontStyle: "italic" }}>{comment}</span>
                  <span style={{ fontWeight: "bold" }}>{title}</span>
                </li>
              );
            } else {
              return (
                <li key={index}>
                  <span style={{ fontWeight: "bold" }}>{story}</span>
                </li>
              );
            }
          })}
        </ul>

        {/* <i
          onClick={removeOverlay}
          style={{ cursor: "pointer", position: "relative" }}
          className={`fa-solid fa-xmark ${styles["overlay-btn"]}`}
        ></i> */}
        <FontAwesomeIcon
          onClick={removeOverlay}
          style={{ cursor: "pointer", position: "relative" }}
          icon={fas.faXmark}
          className={styles["overlay-btn"]}
        />
      </div>
    );
  } else {
    return <div></div>;
  }
});

// <div id="overlayX" className={styles["ht-overlay"]}>
//   <p>
//     {language === "en" ? OverlayData[1]["st1"] : OverlayData[2]["st1"]}
//     {isMobileScreen ? (
//       <span style={{ fontStyle: "italic" }}>
//         (
//         {language === "en"
//           ? OverlayData[1]["st2"] + ", " + OverlayData[1]["st3"]
//           : OverlayData[2]["st2"] + ", " + OverlayData[2]["st3"]}
//         )
//       </span>
//     ) : (
//       <span style={{ fontStyle: "italic" }}>
//         ({language === "en" ? OverlayData[1]["st3"] : OverlayData[2]["st3"]})
//       </span>
//     )}
//   </p>
//   <i
//     onClick={removeOverlay}
//     style={{ cursor: "pointer", position: "relative" }}
//     className={`fa-solid fa-xmark ${styles["overlay-btn"]}`}
//   ></i>
// </div>

export default memo(HeavenlyEvents);
