import React, { useState, useEffect, useRef, memo } from "react";
import { useGlobalContext } from "../../../Context";
// import RosaryStory from "./rosary-story";
// import RosaryPrayer from "./rosary-prayer";
// import { RosaryArrows } from "./rosary-arrows";
import { ParagraphParsing } from "../utils/paragraphParsing";
import styles from "../../styles/Rosary.module.css";

// Font Awesome Icon Inserts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@awesome.me/kit-72510df5aa/icons";

const RosaryContext = ({ context, queensQuotes, rosaryFillers }) => {
  const { windowDimensions } = useGlobalContext();
  const [selectedQte, setSelectedQte] = useState("about"); //about = 0, qte[0] = 1 and so on...
  const [bgScrnSelectedQte, setBGScrnSelectedQte] = useState(0);
  const [displayEntireCntxt, setDisplayEntireCntxt] = useState(false);
  const userBtnInteraction = useRef(false);

  // useEffect(() => {
  //   if (windowDimensions.width > 999) {
  //     setDisplayOriginCard(true);
  //   }
  // }, [windowDimensions.width]);

  // const scrollToDMQuoteSection = () => {
  //   var dmQuoteElement = document.querySelector("#rosary-graces-section");
  //   dmQuoteElement.scrollIntoView({ behavior: "smooth", block: "center" });
  // };

  // ONLY Valuable for Mobile User Exp
  const selectbtnOrContextPane = (index) => {
    if (index === "about" && selectedQte === "about") {
      setDisplayEntireCntxt(true);
    } else {
      setSelectedQte(index);
      userBtnInteraction.current = true;
    }
  };

  if (windowDimensions.width < 1200) {
    // Logic to change size of Visionary (subtitle) when text is greater than 45 characters, and width of the screen is 450
    var rosaryVisionaryClass = styles["rosary-context-visionary"];
    if (selectedQte !== "about" && queensQuotes[selectedQte].visionary.length > 45 && windowDimensions.width < 450) {
      rosaryVisionaryClass = styles["rosary-context-visionary-lt"];
    }

    return (
      // Create Card
      <div className={styles["rosary-context"]}>
        <h1 className={styles["section-title"]}>{context.title}</h1>
        <div className={styles["rosary-context-info-pane"]}>
          {selectedQte === "about" ? (
            <div onClick={() => selectbtnOrContextPane("about")}>
              {/* <ParagraphParsing
                paragraph={displayEntireCntxt === false ? context.overview.substr(0, 583) + " ..." : context.overview}
                txtClass={styles["rosary-context-info-full"]}
              /> */}
              <ParagraphParsing paragraph={context.overview} txtClass={styles["rosary-context-info-full"]} />
            </div>
          ) : (
            <>
              <p className={rosaryVisionaryClass}>{queensQuotes[selectedQte].visionary}</p>
              <p className={styles["rosary-context-visionary-loc"]}>{queensQuotes[selectedQte].locationYear}</p>
              <ParagraphParsing paragraph={queensQuotes[selectedQte].quote} txtClass={styles["rosary-context-info"]} />
            </>
          )}
        </div>
        <div className={styles["rosary-context-btn-container"]}>
          <div
            onClick={() => selectbtnOrContextPane("about")}
            className={
              selectedQte === "about"
                ? styles["rosary-context-btn-about"] + " " + styles["context-btn-about-s"]
                : styles["rosary-context-btn-about"] + " " + styles["context-btn-about-ns"]
            }
          >
            {/* <i id="nav-icons" className="fa-solid fa-swords"></i> */}
            <FontAwesomeIcon id="nav-icons" icon={fas.faSwords} />
            <span>{context.btnTitle}</span>
          </div>
          {queensQuotes.map((quote, index) => {
            var btnClass = styles["rosary-context-btn-quote"];
            if (index === 0 && !userBtnInteraction.current) {
              btnClass = btnClass + " " + styles["context-btn-quote-dec-provoke"];
            } else if (index === selectedQte) {
              btnClass = btnClass + " " + styles["context-btn-quote-dec-s"];
            } else {
              btnClass = btnClass + " " + styles["context-btn-quote-dec-ns"];
            }
            return <div onClick={() => selectbtnOrContextPane(index)} className={btnClass}></div>;
          })}
        </div>
        {/* <button className={styles["invisible-btn"]} onClick={scrollToDMQuoteSection}>
          <p className={styles["rosary-context-subheader"]}>{rosaryFillers.originSubTitle}</p>
        </button> */}
      </div>
    );
  } else {
    return (
      <div className={styles["rosary-context"]}>
        <h1 className={styles["section-title"]}>{context.title}</h1>
        <div className={styles["rosary-context-info-pane"]}>
          <div className={styles["context-bgscrn-pane1"]}>
            <p className={styles["rosary-context-about-title"]}>{context.btnOverviewTitle}</p>
            <ParagraphParsing paragraph={context.overview} txtClass={styles["rosary-context-info-full"]} />
          </div>
          <div className={styles["context-bgscrn-pane2"]}>
            <p className={styles["rosary-context-visionary"]}>{queensQuotes[bgScrnSelectedQte].visionary}</p>
            <p className={styles["rosary-context-visionary-loc"]}>{queensQuotes[bgScrnSelectedQte].locationYear}</p>
            <ParagraphParsing
              paragraph={queensQuotes[bgScrnSelectedQte].quote}
              txtClass={styles["rosary-context-info"]}
            />
            <div className={styles["rosary-context-btn-container"]}>
              {queensQuotes.map((quote, index) => {
                var btnClass = styles["rosary-context-btn-quote"];

                if (index === bgScrnSelectedQte) {
                  btnClass = btnClass + " " + styles["context-btn-quote-dec-s"];
                } else {
                  btnClass = btnClass + " " + styles["context-btn-quote-dec-ns"];
                }
                return <div onClick={() => setBGScrnSelectedQte(index)} className={btnClass}></div>;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default memo(RosaryContext);
