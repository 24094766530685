import React from "react";

export const PrayerParsing = ({ paragraph, txtClass, k = 0 }) => {
  let verseArr = paragraph.split("~");
  return (
    <>
      {verseArr.map((verse, index) => {
        return <ParagraphParsing paragraph={verse} txtClass={txtClass} k={k} />;
      })}
    </>
  );
};

export const ParagraphParsing = ({ paragraph, txtClass, k = 0 }) => {
  // @^ ... @ -> Gold Text
  // @ ... @ -> Italicized
  // @%r ... @ -> Red Text(r) , Green Text(g)

  let contentArr = paragraph.split("@");

  const colorMap = { r: "#cf1111", g: "#e1bd45" };
  return (
    <p key={k} className={txtClass}>
      {contentArr.map((txt, index) => {
        // normal, bold, italic condition statement
        if (index % 2 === 0) {
          return <span key={`${k}${index}`}>{txt}</span>;
        } else if (txt.charAt(0) === "^") {
          return (
            <span
              key={`${k}${index}`}
              style={{
                color: "#d8b012e8",
                // textShadow: "0 0 0.03em black, 0 0 0.04em #341e8a, 0 0 0.10em #341e8a",
                // textShadow: "0 0 0.05em black, 0 0 0.1em #00662c, 0 0 0.15em #3a3210, 0 0 0.2em #c35d04",
                fontWeight: "bold",
                letterSpacing: ".015em",
              }}
            >
              {txt.substr(1, txt.length - 1)}
            </span>
          );
        } else if (txt.charAt(0) === "%") {
          //Extract color from letter after %
          const colorLetter = txt.charAt(1);
          var colorForText = "black";
          if (Object.hasOwn(colorMap, colorLetter)) {
            colorForText = colorMap[colorLetter];
          }
          return (
            <span key={`${k}${index}`} style={{ fontWeight: "bold", color: colorForText }}>
              {txt.substr(2, txt.length - 1)}
            </span>
          );
        } else if (txt.charAt(0) === "$") {
          //Extract color from letter after %
          const colorLetter = txt.charAt(1);
          var colorForText = "black";
          if (Object.hasOwn(colorMap, colorLetter)) {
            colorForText = colorMap[colorLetter];
          }
          return (
            <span key={`${k}${index}`} style={{ fontWeight: "bold", color: colorForText, fontStyle: "italic" }}>
              {txt.substr(2, txt.length - 1)}
            </span>
          );
        } else {
          return (
            <span key={`${k}${index}`} style={{ fontStyle: "italic" }}>
              {txt}
            </span>
          );
        }
      })}
    </p>
  );
};
