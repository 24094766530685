/* eslint-disable */
import React, { useEffect, useState, memo } from "react";
import { useNavigate, useLocation, useLoaderData } from "react-router-dom";
// import { Link } from "react-router-dom";
import { useGlobalContext } from "../Context";
// import { LandingPageDM } from "../fonts/svg/landingPageDM";
// import { LandingPageDMBG } from "../fonts/svg/landingPageDMBG";
import { PassionCarryCross } from "../fonts/svg/passion/passionCarryCross";
import { HomePosterDM } from "../fonts/svg/home/HomePosterDM";
import HomeVideo from "./HomeVideo";
import { News } from "../fonts/svg/News";
import { ZealDMLogoPoster } from "../fonts/svg/home/ZealDMLogoPoster";
import styles from "./styles/Attributions.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, fad } from "@awesome.me/kit-72510df5aa/icons";

const HomeDM = () => {
  const { language, setLanguage, setHomePage, windowDimensions, logoImagesRef, DivineMercyLogoRef, ReportToGA } =
    useGlobalContext();
  const [stateLanguage, setStateLanguage] = useState("en");
  const [isMobile, setIsMobile] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { lang } = useLoaderData();

  useEffect(() => {
    setHomePage(true);
    if (lang === "sp") {
      setLanguage("sp");
    } else {
      setLanguage("en");
    }
    ReportToGA(window.location.pathname);
  }, []);

  useEffect(() => {
    const scrnRatio = windowDimensions.width / windowDimensions.height;
    console.log(scrnRatio);
    if (scrnRatio > 0.9) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [windowDimensions]);

  useEffect(() => {
    let myBtns = document.querySelectorAll(".landPgBtns");
    if (windowDimensions.width >= 1000 && language === "en") {
      for (let i = 0; i < myBtns.length; i++) {
        myBtns[i].style.width = "12.5em";
      }
    } else if (windowDimensions.width >= 1000 && language === "sp") {
      for (let i = 0; i < myBtns.length; i++) {
        myBtns[i].style.width = "15em";
      }
    } else if (language === "en") {
      for (let i = 0; i < myBtns.length; i++) {
        myBtns[i].style.width = "10.75em";
      }
    } else {
      for (let i = 0; i < myBtns.length; i++) {
        myBtns[i].style.width = "12.75em";
      }
    }
    if (language === "sp") {
      const timeout = setTimeout(() => {
        setStateLanguage(language);
      }, 500);
    } else {
      setStateLanguage(language);
    }
  }, [language]);

  const navHover = (id) => {
    document.getElementById(`nav${id}`).style.letterSpacing = ".2em";
    document.getElementById(`nav${id}i`).style.color = "#c5ab42";
  };
  const navLeave = (id) => {
    document.getElementById(`nav${id}`).style.letterSpacing = ".08em";
    document.getElementById(`nav${id}i`).style.color = "#c5ab42";
  };

  const navClicked = (pg) => {
    if (pg !== "medjugorje") setHomePage(false);
    const numParentPaths = location.pathname.split("/");
    const hasLanguageEncoded = location.pathname.includes("en") || location.pathname.includes("sp");

    // Set DivineMercy in Global context to load svg faster
    DivineMercyLogoRef.current = (pg === "our-divine-shield") | (pg === "heavenly-power") ? true : false;

    if (numParentPaths.length === 3) {
      navigate("../../" + pg + "/" + language);
    } else if (location.pathname.includes("home") || hasLanguageEncoded) {
      navigate("../" + pg + "/" + language);
    } else if (urlHasWildCardRef) {
      const url = numberOfBackPathing(numParentPaths) + pg + "/" + language;
      navigate(url);
    } else {
      navigate(pg + "/" + language);
    }
  };

  return (
    <main className={styles["home-page"]}>
      {isMobile ? (
        <div id="home-page-logo" className={styles["home-page-logo"]}>
          {/* <img src={HomePosterSVGRef.current} /> */}
          <HomePosterDM />
        </div>
      ) : (
        <div id="home-page-logo" className={styles["home-page-logo-desktop"]}>
          {/* <img src={HomePosterSVGRef.current} /> */}
          <ZealDMLogoPoster />
        </div>
      )}
      <HomeVideo type={"dm"} />
      <section className={styles["landingSection"]}>
        {/* <div className={styles["landingPageAnimations-dm"]}>
            <LandingPageDM />
          </div> */}
        {/* <img src={logoImagesRef.current[1]} alt="zeal logo" className={styles["hm-pg-logo-dm"]} /> */}
        <div
          id="Medjugorje-news"
          className={styles["Medjugorje-news"] + " " + styles["Medbtn-dm"] + " " + styles["Medjugorje-news-dm-av"]}
        >
          <div id="medbtn" onClick={() => navClicked("medjugorje")} className="landPgBtns">
            <News />
            <span id="arenaLnk">{stateLanguage === "en" ? "Global Battle Arena" : "Campo de Batalla Mundial"}</span>
          </div>
        </div>
        <div className={styles["landingPageButtons-dm"]}>
          <div
            id="btn1"
            key={1}
            onMouseEnter={() => navHover(1)}
            onMouseLeave={() => navLeave(1)}
            onClick={() => navClicked("heavenly-traces")}
            className="landPgBtns"
          >
            {/* <i id="nav1i" className="fa-solid fa-fingerprint"></i> */}
            <FontAwesomeIcon id="nav1i" icon={fas.faFingerprint} />
            <span id="nav1" style={{ letterSpacing: ".08em" }}>
              {stateLanguage === "sp" ? "Huellas de Cielo" : "Heavenly Traces"}
            </span>
          </div>

          <div
            id="btn2"
            key={2}
            onMouseEnter={() => navHover(2)}
            onMouseLeave={() => navLeave(2)}
            onClick={() => navClicked("our-divine-shield")}
            className="landPgBtns"
          >
            {/* <i id="nav2i" className="fa-solid fa-shield-cross"></i> */}
            <FontAwesomeIcon id="nav2i" icon={fas.faShieldCross} />
            <span id="nav2" style={{ letterSpacing: ".08em" }}>
              {stateLanguage === "sp" ? "Nuestra Protección Divina" : "Our Divine Shield"}
            </span>
          </div>

          <div
            id="btn3"
            key={3}
            onMouseEnter={() => navHover(3)}
            onMouseLeave={() => navLeave(3)}
            onClick={() => navClicked("our-divine-weapon")}
            className="landPgBtns"
          >
            {/* <i id="nav3i" className="fa-solid fa-swords"></i> */}
            <FontAwesomeIcon id="nav3i" icon={fas.faSwords} />
            <span id="nav3" style={{ letterSpacing: ".08em" }}>
              {stateLanguage === "sp" ? "Nuestra Arma Divina" : "Our Divine Weapon"}
            </span>
          </div>

          <div
            id="btn4"
            key={4}
            onMouseEnter={() => navHover(4)}
            onMouseLeave={() => navLeave(4)}
            onClick={() => navClicked("heavenly-power")}
            className="landPgBtns"
          >
            {/* <i id="nav4i" className="fa-sharp fa-solid fa-sparkles"></i> */}
            <FontAwesomeIcon id="nav4i" icon={fas.faSparkles} />
            <span id="nav4" style={{ letterSpacing: ".08em" }}>
              {stateLanguage === "sp" ? "Poder Celestial" : "Heavenly Power"}
            </span>
          </div>
          <section className={styles["toggle_section"]}>
            <ToggleLandingBtns />
            {stateLanguage === "en" ? (
              <label onChange={() => setLanguage("sp")} className={`${styles.switch} ${styles.language}`}>
                <input style={{ opacity: 0 }} type="checkbox" />
                <span id="slider" className={`${styles["slider-dm"]} ${styles["round"]}`}></span>
                <span style={{ color: "white" }} id={"slider-hm-label"} className={styles["eng-hm-label"]}>
                  Eng
                </span>
              </label>
            ) : (
              <label onChange={() => setLanguage("en")} className={`${styles.switch} ${styles.language}`}>
                <input style={{ opacity: 0 }} type="checkbox" checked />
                <span id="slider" className={`${styles["slider"]} ${styles["round"]}`}></span>
                <span style={{ color: "white" }} id={"slider-hm-label"} className={styles["sp-hm-label"]}>
                  Esp
                </span>
              </label>
            )}
            <section id="passion-icon" onClick={() => navClicked("the-story")} className={styles["passion-cross-cont"]}>
              <PassionCarryCross />
            </section>
          </section>
        </div>
      </section>
    </main>
  );
};

const ToggleLandingBtns = () => {
  const [displayLandingBtns, setDisplayLandingBtns] = useState(true);

  useEffect(() => {
    let logo = document.getElementById(`home-page-logo`);
    let btn1 = document.getElementById("btn1");
    let btn2 = document.getElementById("btn2");
    let btn3 = document.getElementById("btn3");
    let btn4 = document.getElementById("btn4");
    let medbtn = document.getElementById("medbtn");
    let sliderbtn = document.getElementById("slider");
    let sliderlabelbtn = document.getElementById("slider-hm-label");
    // let chckbtn = document.getElementById("chckbox");
    let passionIcon = document.getElementById("passion-icon");

    if (displayLandingBtns) {
      logo.style.opacity = 1;
      btn1.style.opacity = 1;
      btn2.style.opacity = 1;
      btn3.style.opacity = 1;
      btn4.style.opacity = 1;
      medbtn.style.opacity = 1;
      sliderbtn.style.opacity = 1;
      sliderlabelbtn.style.opacity = 1;
      // chckbtn.style.opacity = 1;
      passionIcon.style.opacity = 1;
    } else {
      logo.style.opacity = 0;
      btn1.style.opacity = 0;
      btn2.style.opacity = 0;
      btn3.style.opacity = 0;
      btn4.style.opacity = 0;
      medbtn.style.opacity = 0;
      sliderbtn.style.opacity = 0;
      sliderlabelbtn.style.opacity = 0;
      // chckbtn.style.opacity = 0;
      passionIcon.style.opacity = 0;
    }
  }, [displayLandingBtns]);

  return (
    <button className={styles["toggleLandingPgBtns"]} onClick={() => setDisplayLandingBtns(!displayLandingBtns)}>
      {/* {(displayLandingBtns && <i className="fa-sharp fa-regular fa-eye-slash"></i>) || (
        <i className="fa-sharp fa-regular fa-eye"></i>
      )} */}
      {(displayLandingBtns && <FontAwesomeIcon icon={fad.faEyeSlash} />) || <FontAwesomeIcon icon={fad.faBars} />}
    </button>
  );
};

export default memo(HomeDM);
