import React, { useEffect, useState, memo, lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { getImageUrlSV4 } from "./components/utils/s3-content";
import { useGlobalContext } from "../Context";
import styles from "./styles/Attributions.module.css";

const HomeVideo = ({ type }) => {
  const { windowDimensions, HomePosterSVGRef } = useGlobalContext();
  const [data, setData] = useState(null);
  const [scrnRatio, setScrnRatio] = useState(0);

  useEffect(() => {
    setScrnRatio(windowDimensions.width / windowDimensions.height);
    if (type === "norm") {
      setVideoUrls();
    } else if (type === "dm") {
      setVideoUrlsDM();
    }
    // setVideoUrls();
    let delay = 2000;
    const timeout = setTimeout(() => {
      var vid = document.getElementById("home-page-vid");
      if (vid) {
        vid.muted = true;
        vid.play();
      }
    }, delay);
    return () => clearTimeout(timeout);
  }, []);

  const setVideoUrls = async () => {
    const mobileVidTall = await getImageUrlSV4("video/medjugorjeCityMBTall_dev.mp4");
    const mobileVid = await getImageUrlSV4("video/medjugorjeCity_dev.mp4");
    const tabletVid = await getImageUrlSV4("video/medjugorjeCityTablet_dev.mp4");
    // const desktopVid = await getImageUrlSV4("video/ZealVideoDesktop.mp4");
    setData({ mobileTall: mobileVidTall, mobile: mobileVid, tablet: tabletVid, desktop: mobileVid });
  };

  const setVideoUrlsDM = async () => {
    const mobileVidTall = await getImageUrlSV4("video/divineMercyCityMBTall.mp4");
    const mobileVid = await getImageUrlSV4("video/divineMercyCity.mp4");
    const tabletVid = await getImageUrlSV4("video/divineMercyCityTablet.mp4");
    // const desktopVid = await getImageUrlSV4("video/ZealVideoDesktop.mp4");
    setData({ mobileTall: mobileVidTall, mobile: mobileVid, tablet: tabletVid, desktop: mobileVid });
  };

  // Executed from all devices when video has ended.
  const pauseVideo = () => {
    // var vid = document.getElementById("home-page-vid");
    // vid.currentTime = 33;
    // vid.pause();
  };

  //   key={1}
  // id="med-vid"
  // autoPlay={false}
  // onPlay={() => setMedVidLoaded(true)}
  // muted
  // playsInline
  // width="100%"
  // preload="auto"

  if (data && scrnRatio < 0.54) {
    // MobileTall
    return (
      <div className={styles["home-page-vid"]}>
        <video
          key={1}
          id="home-page-vid"
          autoPlay={true}
          muted
          playsInline
          width="100%"
          height="100%"
          preload="auto"
          // onEnded={() => pauseVideo()}
        >
          <source src={data.mobileTall} type="video/mp4" />
        </video>
      </div>
    );
  } else if (data && windowDimensions.width < 500) {
    // Mobile
    return (
      <div className={styles["home-page-vid"]}>
        <video
          key={2}
          id="home-page-vid"
          autoPlay={true}
          muted
          playsInline
          width="100%"
          height="100%"
          preload="auto"
          // onEnded={() => pauseVideo()}
        >
          <source src={data.mobile} type="video/mp4" />
        </video>
      </div>
    );
  } else if (data && scrnRatio < 0.9) {
    // Tablet
    return (
      <div className={styles["home-page-vid"]}>
        <video
          key={3}
          id="home-page-vid"
          autoPlay={true}
          muted
          playsInline
          width="100%"
          height="100%"
          preload="auto"
          // onEnded={() => pauseVideo()}
        >
          <source src={data.tablet} type="video/mp4" />
        </video>
      </div>
    );
  } else {
    <div></div>;
  }
};

export default HomeVideo;
