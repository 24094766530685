/* eslint-disable */
import React, { useEffect, useState, memo } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../Context";
// Import Rosary components
import RosaryContext from "./components/rosary/rosary-context";
import RosaryGraces from "./components/rosary/rosary-graces";
import RosaryInspirations from "./components/rosary/rosary-inspirations";
import Reparations from "./components/rosary/reparations";
import MariamApparitions from "./components/rosary/mariam-app";
import { FooterEnglish, FooterSpanish } from "../data/footer";
import Footer from "./components/footer.js";
import { getImageUrlSV4, getJSON } from "./components/utils/s3-content";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga";
// import data from "./gw-english-dev.json";
// Import Css
import styles from "./styles/Rosary.module.css";
import hStyles from "./styles/HeavenlyTraces.module.css";

// Font Awesome Icon Inserts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, fad } from "@awesome.me/kit-72510df5aa/icons";

const InspirationImgKeys = [
  { index: 1, key: "our-powerful-weapon/rosary-las-lajas.jpg" },
  { index: 2, key: "our-powerful-weapon/rosary-bilocation.png" },
  { index: 3, key: "our-powerful-weapon/rosary-malta.png" },
  { index: 4, key: "our-powerful-weapon/rosary-lepanto.jpeg" },
  { index: 5, key: "our-powerful-weapon/rosary-Bartolo-Longo.jpg" },
];

const MarianImgKeys = [
  { index: 1, key: "our-powerful-weapon/marian-china.jpg" },
  { index: 2, key: "our-powerful-weapon/marian-india.jpg" },
  { index: 3, key: "our-powerful-weapon/marian-revelation.jpg" },
  { index: 4, key: "our-powerful-weapon/marian-sinners.jpg" },
  { index: 5, key: "our-powerful-weapon/marian-lebanon.jpg" },
  { index: 6, key: "our-powerful-weapon/marian-willow-tree.jpg" },
  { index: 7, key: "our-powerful-weapon/marian-guadalupe.png" },
];

const JSONData = {
  englishKey: "our-powerful-weapon/gw-english-dev.json",
  spanishKey: "our-powerful-weapon/gw-spanish-dev.json",
};

const OverlayData = {
  1: {
    st1: "The following sections contain not only context about the Rosary Devotion, but also real quotes and stories related to the Blessed Virgin Mary that many are still unaware of ",
    st2: "Select the X to continue",
  },
  2: {
    st1: "Las siguientes secciones contienen no sólo contexto sobre la devoción del Rosario, sino también citas e historias reales relacionadas con la Santísima Virgen María que muchos aún desconocen ",
    st2: "Seleccione la X para continuar",
  },
};

const GreatestWeapon = () => {
  const { language, setLanguage, setHomePage, ReportToGA } = useGlobalContext();
  const { data, marianImages, inspirationImages, lang } = useLoaderData();

  useEffect(() => {
    window.scrollTo(0, 0);
    setHomePage(false);
    if (lang === "sp") {
      setLanguage("sp");
    } else {
      setLanguage("en");
    }
    ReportToGA(window.location.pathname);
  }, []);

  if (data) {
    return (
      <HelmetProvider>
        <Helmet>
          <title>{language === "en" ? "Our Divine Weapon" : "Nuestra Protección Divina"}</title>
          <meta name="description" content="Introducing the world to it's Mother." />
          <meta
            name="keywords"
            content={[
              "Blessed",
              "Virgin",
              "Mary",
              "Mother",
              "weapon",
              "Queen",
              "Love",
              "zeal",
              "maria",
              "madre",
              "arma",
              "reina",
              "christian",
            ]}
          />
        </Helmet>
        <main className={styles["rosary-page"]}>
          <Overlay />
          <RosaryContext
            context={data.RosaryContext}
            queensQuotes={data.QueenQuotes}
            rosaryFillers={data.RosaryFillers}
          />
          <RosaryGraces
            sources={data.RosaryGracesSource}
            rosaryFillers={data.RosaryFillers}
            graces={data.RosaryGracesData}
            appOfJesus={data.JesusAppartionToMary}
          />

          <RosaryInspirations
            inspirations={data.RosaryInspirationalData}
            rosaryFillers={data.RosaryFillers}
            images={inspirationImages}
            BtmNav={BottomNavigation}
          />
          <Reparations reparations={data.ReparationsInitiatives} />
          <MariamApparitions
            apparitions={data.MariamApparitionsData}
            rosaryFillers={data.RosaryFillers}
            images={marianImages}
            BtmNav={BottomNavigation}
          />
          <Footer theme={"dark"} footer={language === "en" ? FooterEnglish : FooterSpanish} />
        </main>
      </HelmetProvider>
    );
  } else {
    return <div></div>;
  }
};

const BottomNavigation = ({ name }) => {
  const { language } = useGlobalContext();
  const navigate = useNavigate();
  const readOnPage = () => {
    navigate(`../dw_article/${name}/${language || "en"}`);
  };
  return (
    <div onClick={() => readOnPage()} className={hStyles["ht-btm-nav-container"]}>
      {language === "sp" ? <p>Leer Artículo en Pantalla Completa</p> : <p>Read Article on Full Screen</p>}
    </div>
  );
};

const Overlay = memo(() => {
  const { language, dwOverlayRef } = useGlobalContext();
  const [dwOverlaySt, setDwOverlaySt] = useState(dwOverlayRef.current);

  const removeOverlay = () => {
    setDwOverlaySt(false);
    dwOverlayRef.current = false;
  };

  if (dwOverlaySt) {
    return (
      <div id={"overlayX"} className={hStyles["ht-overlay"]}>
        <p>
          {language === "en" ? OverlayData[1]["st1"] : OverlayData[2]["st1"]}

          <span style={{ fontStyle: "italic" }}>
            ({language === "en" ? OverlayData[1]["st2"] : OverlayData[2]["st2"]})
          </span>
        </p>

        {/* <i
          onClick={() => removeOverlay(true)}
          style={{ color: "var(--glory-blue-300)", cursor: "pointer", position: "relative" }}
          className="fa-solid fa-xmark"
        ></i> */}
        <FontAwesomeIcon
          onClick={() => removeOverlay(true)}
          style={{ color: "var(--glory-blue-300)", cursor: "pointer", position: "relative" }}
          icon={fas.faXmark}
        />
      </div>
    );
  } else {
    return <div></div>;
  }
});

export async function DivineWeaponLoader({ params }) {
  var data = {};

  // Set the Language in Global Context, Query the data for the HT stories
  const GetJSONData = async () => {
    if (params.lang === "sp") {
      const result = await getJSON(JSONData["spanishKey"]);
      // console.log(JSON.parse(result));
      return JSON.parse(result);
    } else {
      const result = await getJSON(JSONData["englishKey"]);
      // console.log(JSON.parse(result));
      return JSON.parse(result);
    }
  };

  const SignInspImgUrls = async () => {
    let imageList = [];
    const results = InspirationImgKeys.map(async ({ key }) => {
      return getImageUrlSV4(key);
    });
    Promise.all(results).then((signedImages) => {
      for (let i = 0; i < signedImages.length; i++) {
        imageList.push(signedImages[i]);
      }
    });
    return imageList;
  };
  const SignMarianImgUrls = async () => {
    let imageList = [];
    const results = MarianImgKeys.map(async ({ key }) => {
      return getImageUrlSV4(key);
    });
    Promise.all(results).then((signedImages) => {
      for (let i = 0; i < signedImages.length; i++) {
        imageList.push(signedImages[i]);
      }
    });
    return imageList;
  };

  data.data = await GetJSONData();
  data.inspirationImages = await SignInspImgUrls();
  data.marianImages = await SignMarianImgUrls();
  data.lang = params.lang;
  return data;
}

export default memo(GreatestWeapon);
