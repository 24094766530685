/* eslint-disable */
import React from "react";
import { ReactComponent as Svg } from "./Loading.svg";
// import "./Loading.css";

const Loading = () => {
  return <Svg />;
};

export default Loading;
