import React from "react";
import AWS from "aws-sdk";
import S3 from "aws-sdk/clients/s3";

const credentials = {
  accessKeyId: "AKIAVVG2MA3HYFDS733C",
  secretAccessKey: "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",
};
const MRAP = "m1d55hhoqezkw";
const Endpoint = "mrap.accesspoint.s3-global.amazonaws.com/";
const Region = "us-east-2";

const JsonEndpoint = "m1d55hhoqezkw.mrap.accesspoint.s3-global.amazonaws.com/";
const JsonBucket = "s3-global";

export const getImageUrlSV4 = (key) => {
  AWS.config.update(credentials);
  const clientParams = {
    apiVersion: "2006-03-01",
    region: Region,
    endpoint: Endpoint,
    signatureVersion: "v4",
  };
  var getObjectParams = {
    Bucket: MRAP,
    Key: key,
  };
  var s3 = new S3(clientParams);
  return s3.getSignedUrl("getObject", getObjectParams);
};

export const getJSON = async (key) => {
  AWS.config.update(credentials);
  var s3 = new S3({
    apiVersion: "2006-03-01",
    region: Region,
    endpoint: Endpoint,
  });
  var bucketParams = {
    Bucket: MRAP,
    Key: key,
  };

  try {
    let result = await s3.getObject(bucketParams).promise();
    result = await Decode(result);
    return result;
  } catch (err) {
    console.log(err);
  }
};

const Decode = async (payload) => {
  const asciiDecoder = new TextDecoder("utf8");
  return asciiDecoder.decode(payload.Body);
};
