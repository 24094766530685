export const HtJSONData = {
  englishKey: "heavenly-traces/ht-english-dev.json",
  spanishKey: "heavenly-traces/ht-spanish-dev.json",
};

export const DwJSONData = {
  englishKey: "our-powerful-weapon/gw-english-dev.json",
  spanishKey: "our-powerful-weapon/gw-spanish.json",
};

export const HtImageKeys = [
  { index: 1, name: "shroud_of_turin", key: "heavenly-traces/ht-shroud-turin.jpg" },
  { index: 2, name: "our_lady_of_guadalupe", key: "heavenly-traces/ht-our-lady-guadalupe.jpg" },
  { index: 3, name: "miracle_of_fatima", key: "heavenly-traces/ht-fatima.jpg" },
  { index: 4, name: "miracle_of_lanciano", key: "heavenly-traces/ht-lanciano.jpeg" },
  { index: 5, name: "miracle_of_vistula", key: "heavenly-traces/ht-miracle-of-vistula-left.jpeg" },
  { index: 7, name: "salvation_of_the_phillipines", key: "heavenly-traces/ht-manila.jpg" },
  { index: 6, name: "our_lady_of_zeitoun", key: "heavenly-traces/ht-zeitoun.jpg" },
  { index: 8, name: "medjugorje", key: "heavenly-traces/ht-medjugorje.png" },
  { index: 9, name: "apparitions_to_george_washington", key: "heavenly-traces/ht-georgeWashington.jpg" },
  { index: 10, name: "miracle_of_tixtla", key: "heavenly-traces/ht-tixtla.jpg" },
  { index: 11, name: "apparitions_in_ireland", key: "heavenly-traces/ht-ireland.jpg" },
  { index: 12, name: "miracles_of_bueno_aires", key: "heavenly-traces/ht-bueno-aires.jpg" },
  { index: 13, name: "sacred_stigmata", key: "heavenly-traces/ht-stigmata.jpg" },
  { index: 14, name: "non-decaying_saints", key: "heavenly-traces/ht-incorruptible-saint.png" },
  { index: 15, name: "our_lady_of_la_vang", key: "heavenly-traces/ht-la-vang.jpg" },
  { index: 16, name: "our_lady_of_ta_pao", key: "heavenly-traces/ht-tapao.jpg" },
  { index: 17, name: "our_lady_of_garbandal", key: "heavenly-traces/ht-garabandal.png" },
];

//Values are the array positions in the Miracles Array in the ht-english/spanish file

export const ItalicsInParagraph = ({ content, indent, pkey, className = "", ct = false }) => {
  let contentArr = content.split("@");
  if (indent) {
    return (
      <p key={pkey}>
        {contentArr.map((txt, index) => {
          // normal, bold, italic condition statement
          if (index % 2 === 0) {
            return (
              <span key={index} className={className}>
                {txt}
              </span>
            );
          } else if (txt.charAt(0) === "^") {
            return (
              <span key={index} style={{ fontWeight: "bold" }} className={className}>
                {txt.substr(1, txt.length - 1)}
              </span>
            );
          } else {
            return (
              <span key={index} style={{ fontStyle: "italic" }} className={className}>
                {txt}
              </span>
            );
          }
        })}
      </p>
    );
  } else {
    // To be able to italize parts of subtitles, ct = class title
    if (ct) {
      return (
        <p key={pkey} className={className}>
          {contentArr.map((txt, index) => {
            // normal, bold, italic condition statement
            // !!!!! Write ternary operator for txt if it has a #
            txt = txt.charAt(0) === "#" ? txt.substr(1, txt.length - 1) : txt;
            if (index % 2 === 0) {
              return <span key={index}>{txt}</span>;
            } else {
              return (
                <span key={index} style={{ fontStyle: "italic" }}>
                  {txt}
                </span>
              );
            }
          })}
        </p>
      );
    }
    return (
      <p key={pkey} style={{ textIndent: "0px" }}>
        {contentArr.map((txt, index) => {
          // normal, bold, italic condition statement
          if (index % 2 === 0) {
            return (
              <span key={index} className={className}>
                {txt}
              </span>
            );
          } else if (txt.charAt(0) === "^") {
            return (
              <span key={index} style={{ fontWeight: "bold" }} className={className}>
                {txt.substr(1, txt.length - 1)}
              </span>
            );
          } else {
            return (
              <span key={index} style={{ fontStyle: "italic" }} className={className}>
                {txt}
              </span>
            );
          }
        })}
      </p>
    );
  }
};

export const ItalicsInSources = ({ content, cls, lang, url }) => {
  let contentArr = content.split("$");

  return (
    <p className={cls}>
      {contentArr.map((txt, index) => {
        // normal, bold, italic condition statement
        if (index % 2 === 0) {
          return (
            <span style={{ fontWeight: "bold" }} key={index}>
              {txt}
            </span>
          );
        } else if (txt.charAt(0) === "^") {
          return (
            <span key={index} style={{ fontWeight: "bold" }}>
              {txt.substr(1, txt.length - 1)}
            </span>
          );
        } else {
          return (
            <span key={index} style={{ fontStyle: "Italic" }}>
              {txt}
            </span>
          );
        }
      })}
      {url && (
        <a href={url} target="_blank">
          {lang == "en" ? " - Link" : " - Fuente"}
        </a>
      )}
    </p>
  );
};
