import React, { useState, useEffect, memo } from "react";
import { useGlobalContext } from "../../../Context";
import styles from "../../styles/DivineMercy.module.css";

const DivineMercyLastQuote = ({ lastQuotes }) => {
  const [endQuoteIndex, setEndQuoteIndex] = useState(0);
  const { windowDimensions } = useGlobalContext();
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (endQuoteIndex === 1) {
        setEndQuoteIndex(0);
      } else {
        setEndQuoteIndex(1);
      }
    }, 24000);
    return () => clearTimeout(timeout);
  }, [endQuoteIndex, windowDimensions.width]);

  return (
    <div className={styles["dm-last-quote"]}>
      <>
        <h3>{lastQuotes && lastQuotes[endQuoteIndex].journalEntry}</h3>
        <h1
          className={
            lastQuotes[endQuoteIndex].Quote.length < 250
              ? styles["last-quote-small-paragraph"]
              : styles["last-quote-big-paragraph"]
          }
        >
          {lastQuotes && lastQuotes[endQuoteIndex].Quote}
        </h1>
      </>
    </div>
  );
};

export default memo(DivineMercyLastQuote);
