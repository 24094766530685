import React, { useState, useRef, useEffect, memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ZealNavLogo } from "../../fonts/svg/home/ZealNavLogo";
import { ZealNavDMLogo } from "../../fonts/svg/home/ZealNavDMLogo";

// import ZealLogo from "../../images/zeal-official.png";
// import ZealLogoDM from "../../images/zeal-dm.png";
// import { SocialLinks } from "../../data/social-links";
import { useGlobalContext } from "../../Context";

// Font Awesome Icon Inserts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@awesome.me/kit-72510df5aa/icons";

// Have a map of urls to whether they show divine mercy logo or not
const URLS = new Map();
URLS.set("heavenly-traces", false);
URLS.set("our-divine-weapon", false);
URLS.set("our-divine-shield", true);
URLS.set("heavenly-power", true);
URLS.set("letters-and-scrolls", true);

const Navbar = () => {
  const { language, setLanguage, logoImagesRef, DivineMercyLogoRef } = useGlobalContext();
  const [divineMercy, setDivineMercy] = useState(false);
  const [showLinks, setShowLinks] = useState(false);
  const linksContainerRef = useRef(null);
  const linksRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const url = location.pathname;
    const pathSegments = url.split("/");
    let baseUrl = "";

    //if url includes a forward slash, and some pathing after it from navbar
    if (pathSegments.length === 3) {
      baseUrl = pathSegments[1];
    } else {
      baseUrl = pathSegments[1];
    }
    // console.log("use effect base Url", baseUrl);
    setDivineMercy(URLS.get(baseUrl));
  }, [location.pathname]);

  useEffect(() => {
    if (DivineMercyLogoRef.current) {
      setDivineMercy(DivineMercyLogoRef.current);
    }
  }, [DivineMercyLogoRef.current]);

  useEffect(() => {
    var toggle = document.getElementById("toggle");
    const linksHeight = linksRef.current.getBoundingClientRect().height;
    if (showLinks) {
      toggle.classList.add("nav-toggle-click");
      linksContainerRef.current.style.height = `${linksHeight}px`;
    } else {
      toggle.classList.remove("nav-toggle-click");
      linksContainerRef.current.style.height = `0px`;
    }
  }, [showLinks]);

  const constructPathForNav = (url) => {
    if (!url) {
      // if page provided is null

      const pathSegments = location.pathname.split("/");
      console.log(pathSegments);
      if (pathSegments.length === 4) {
        return `${pathSegments[1]}/${pathSegments[2]}`;
      } else if (pathSegments.length === 3) {
        // if current url has language encoded
        return pathSegments[1];
      } // if current url has no language is encoded
      return location.pathname;
    } else {
      // if page provided already
      return url;
    }
  };

  //Use navigate will navigate to the new page, then logo is changed in this function
  const mobileNavbarItemSelected = (pg, lang) => {
    let Page = constructPathForNav(pg);
    let Lang = lang === null ? language : lang;
    // console.log(pg + "/" + Lang);
    navigate(Page + "/" + Lang);

    if (pg === "heavenly-traces") {
      setDivineMercy(false);
    } else if (pg === "our-divine-shield") {
      setDivineMercy(true);
    } else if (pg === "our-divine-weapon") {
      setDivineMercy(false);
    } else if (pg === "powerful-lessons") {
      setDivineMercy(true);
    } else if (pg === "heavenly-power") {
      setDivineMercy(true);
    }

    if (lang !== language) setLanguage(lang);
    // window.scrollTo(0, 0);

    const timeout = setTimeout(() => {
      setShowLinks(false);
    }, 300);
    return () => clearTimeout(timeout);
  };

  const LanguageIconOnNavbar = () => {
    // console.log(location.pathname.split("/")[1]);
    if (
      location.pathname.split("/")[1] !== "our-divine-shield" &&
      location.pathname.split("/")[1] !== "heavenly-power"
    ) {
      if (language === "sp") {
        return (
          <li onClick={() => mobileNavbarItemSelected(null, "en")}>
            <div className="lang nav-info-item">
              {/* <i style={{ color: "var(--jade)" }} id="nav-icons" className="fa-solid fa-earth-americas"></i> */}
              <FontAwesomeIcon style={{ color: "var(--jade)" }} id="nav-icons" icon={fas.faEarthAmericas} />
              <span>Esp</span>
            </div>
          </li>
        );
      } else {
        return (
          <li onClick={() => mobileNavbarItemSelected(null, "sp")}>
            <div className="lang nav-info-item">
              {/* <i style={{ color: "var(--cross-blue-300)" }} id="nav-icons" className="fa-solid fa-earth-americas"></i> */}
              <FontAwesomeIcon style={{ color: "var(--cross-blue-300)" }} id="nav-icons" icon={fas.faEarthAmericas} />
              <span>Eng</span>
            </div>
          </li>
        );
      }
    }
  };

  return (
    <nav>
      <div className="nav-center">
        <div className="nav-header">
          <div
            className="nav-svg"
            onClick={() => mobileNavbarItemSelected(divineMercy ? "/home-dm" : "/home", language)}
          >
            {divineMercy ? (
              // <img src={logoImagesRef.current[1]} alt="zeal dm logo" className="logo" />
              <ZealNavDMLogo />
            ) : (
              // <img src={logoImagesRef.current[0]} alt="zeal logo" className="logo" />
              <ZealNavLogo />
            )}
          </div>

          <button
            id="toggle"
            className={divineMercy ? "nav-toggle nav-toggle-dm" : "nav-toggle nav-toggle-rosary"}
            onClick={() => setShowLinks(!showLinks)}
          >
            {/* <i className="fas fa-bars"></i> */}
            <FontAwesomeIcon icon={fas.faBars} />
          </button>
        </div>

        <div className={divineMercy ? "nav-links dm-bg" : "nav-links rosary-bg"} ref={linksContainerRef}>
          <ul className="links" ref={linksRef}>
            <li>
              <div className="nav-info-item" onClick={() => mobileNavbarItemSelected("heavenly-traces", language)}>
                {/* <i id="nav-icons" className="fa-solid fa-fingerprint"></i> */}
                <FontAwesomeIcon id="nav-icons" icon={fas.faFingerprint} />
                <span>{language === "sp" ? "Huellas de Cielo" : "Heavenly Traces"}</span>
              </div>
            </li>
            <li>
              <div className="nav-info-item" onClick={() => mobileNavbarItemSelected("our-divine-shield", language)}>
                {/* <i id="nav-icons" className="fa-solid fa-shield-cross"></i> */}
                <FontAwesomeIcon id="nav-icons" icon={fas.faShieldCross} />
                <span>{language === "sp" ? "Nuestra Protección Divina" : "Our Divine Shield"}</span>
              </div>
            </li>
            <li>
              <div className="nav-info-item" onClick={() => mobileNavbarItemSelected("our-divine-weapon", language)}>
                {/* <i id="nav-icons" className="fa-solid fa-swords"></i> */}
                <FontAwesomeIcon id="nav-icons" icon={fas.faSwords} />
                <span>{language === "sp" ? "Nuestra Arma Divina" : "Our Divine Weapon"}</span>
              </div>
            </li>
            <li>
              <div className="nav-info-item" onClick={() => mobileNavbarItemSelected("heavenly-power", language)}>
                {/* <i id="nav-icons" className="fa-sharp fa-solid fa-sparkles"></i> */}
                <FontAwesomeIcon id="nav-icons" icon={fas.faSparkles} />
                <span>{language === "sp" ? "Poder Celestial" : "Heavenly Power"}</span>
              </div>
            </li>
            <LanguageIconOnNavbar />
          </ul>
        </div>

        {/* <ul className="social-icons">
          {SocialLinks.map(({ media, url }, index) => {
            return (
              <li key={index}>
                <a href={url} target="_blank">
                  <i className={`fab fa-${media}`}></i>
                </a>
              </li>
            );
          })}
        </ul> */}
        {/* <Link className="globalArenaBtn" to="/medjugorje">
          <i id="nav-icons" className="fa-solid fa-power-off"></i>
          <span>{language === "english" ? "Global Battle Arena" : "Campo de Batalla Mundial"}</span>
        </Link> */}
      </div>
    </nav>
  );
};

export default memo(Navbar);
