import React, { useEffect, useState, memo } from "react";
import StarsRepresentingLessons from "./theme_stars";
import { QuestionMarkGold } from "../../../fonts/svg/questionMarkGold";
import { ItalicsInParagraph } from "../heavenlyTraces/ht-card";
import { getJSON } from "../utils/s3-content";
import styles from "../../styles/PowerfulLessons.module.css";
// import "./styles/themes.css";
import { RetrieveIcon } from "../utils/theme_icons";

const JSONData = {
  1: "powerful-lessons/lessons_sw.json",
  2: "powerful-lessons/lessons_prayer.json",
  3: "powerful-lessons/lessons_fundamental.json",
  4: "powerful-lessons/lessons_inspiring.json",
  5: "powerful-lessons/lessons_eucharist.json",
  6: "powerful-lessons/lessons_virtues.json",
  7: "powerful-lessons/lessons_prophecies.json",
  8: "powerful-lessons/lessons_sw_sp.json",
  9: "powerful-lessons/lessons_prayer_sp.json",
  10: "powerful-lessons/lessons_fundamental_sp.json",
  11: "powerful-lessons/lessons_inspiring_sp.json",
  12: "powerful-lessons/lessons_eucharist_sp.json",
  13: "powerful-lessons/lessons_virtues_sp.json",
  14: "powerful-lessons/lessons_prophecies_sp.json",
};

const PowerfulThemes = ({ themesProperties, apostolicFiller, callBack }) => {
  //Just the id
  const [themeNumSelected, setThemeNumSelected] = useState(null);
  //Properties of the selected theme
  const [selectedThemeProperty, setSelectedThemeProperty] = useState();
  //Actual quotes of the theme lesson
  const [selectedTheme, setSelectedTheme] = useState(null);
  //Information Display Explaining Section
  const [informationIconStatus, setInformationIconStatus] = useState({ selected: false, hasSelected: false });

  useEffect(() => {
    setThemeNumSelected(null);
  }, [themesProperties]);

  // Calculate # of rows for quotes depending on screen width
  const SelectTheme = async (id) => {
    // callBack(true);
    callBack(true);
    let data = { quotes: [] };
    if (id === 1) {
      data = await getJSON(JSONData[1]);
    } else if (id === 2) {
      data = await getJSON(JSONData[2]);
    } else if (id === 3) {
      data = await getJSON(JSONData[3]);
    } else if (id === 4) {
      data = await getJSON(JSONData[4]);
    } else if (id === 5) {
      data = await getJSON(JSONData[5]);
    } else if (id === 6) {
      data = await getJSON(JSONData[6]);
    } else if (id === 7) {
      data = await getJSON(JSONData[7]);
    } else if (id === 8) {
      data = await getJSON(JSONData[8]);
    } else if (id === 9) {
      data = await getJSON(JSONData[9]);
    } else if (id === 10) {
      data = await getJSON(JSONData[10]);
    } else if (id === 11) {
      data = await getJSON(JSONData[11]);
    } else if (id === 12) {
      data = await getJSON(JSONData[12]);
    } else if (id === 13) {
      data = await getJSON(JSONData[13]);
    } else if (id === 14) {
      data = await getJSON(JSONData[14]);
    }

    //Retrieves & sets the selected theme property
    const selectedThemeProperty = themesProperties.filter((obj) => obj.id === id)[0];
    setSelectedThemeProperty(selectedThemeProperty);
    //Sets the Data for the Selected Theme Topic
    setSelectedTheme(JSON.parse(data));
    setThemeNumSelected(id);
  };

  const backToThemesFunc = () => {
    setThemeNumSelected(null);
  };

  return (
    <section className={styles["powerful-themes-center"]}>
      <div className={styles["powerful-lessons-header"]}>
        <h2 style={{ marginBottom: "0" }} className={styles["section-title"]}>
          {apostolicFiller.ThemesTitle}
        </h2>
        <button
          onClick={() => setInformationIconStatus({ selected: !informationIconStatus.selected, hasSelected: true })}
          className={
            !informationIconStatus.hasSelected
              ? styles["information-icon"] + " " + styles["icon-bounce"]
              : styles["information-icon"]
          }
        >
          <QuestionMarkGold />
        </button>
        {informationIconStatus.selected && (
          <div className={styles["information-msg-dm"]}>
            <ItalicsInParagraph content={apostolicFiller.InformationDisplay} indent={false} />
            <div style={{ paddingBottom: ".25em" }} className={styles["information-icons"]}>
              <i
                onClick={() => setInformationIconStatus({ ...informationIconStatus, selected: false })}
                style={{ color: "var(--cross-gold-300)", cursor: "pointer", position: "relative", top: ".25em" }}
                className="fa-solid fa-xmark"
              ></i>
            </div>
          </div>
        )}
      </div>
      {/* Container for Icons */}
      {themeNumSelected == null ? (
        <div className={styles["powerful-themes-icon-container"]}>
          {themesProperties !== null &&
            themesProperties.map((obj) => {
              // let themeClass = themeNumSelected === obj.id ? "theme-button theme-button-selected" : "theme-button";
              const templeBtnArr = obj.class.split(" ");
              const templeWArr = obj.letterClass.split(" ");
              return (
                <button
                  key={obj.id}
                  onClick={() => SelectTheme(obj.id)}
                  style={{ border: `3px solid ${obj.color}` }}
                  className={
                    styles[templeBtnArr[0]] +
                    " " +
                    styles[templeBtnArr[1]] +
                    " " +
                    styles[templeBtnArr[2]] +
                    " " +
                    styles[templeBtnArr[3]]
                  }
                >
                  <p className={styles[templeWArr[0]] + " " + styles[templeWArr[1]] + " " + styles[templeWArr[2]]}>
                    {obj.theme}
                  </p>
                  <i className={obj.icon}></i>
                  <RetrieveIcon theme_id={obj.id} />
                </button>
              );
            })}
        </div>
      ) : (
        <article className={styles["powerful-themes-quotes"]}>
          {selectedTheme && (
            <StarsRepresentingLessons
              notebookName={apostolicFiller.Notebook}
              themeData={selectedTheme}
              themeProperty={selectedThemeProperty}
              backToThemePageFunc={() => backToThemesFunc()}
            />
          )}
        </article>
      )}
    </section>
  );
};

export default memo(PowerfulThemes);
