/* eslint-disable */
import React, { useEffect, memo } from "react";
import { useGlobalContext } from "../Context";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLoaderData } from "react-router-dom";
import { getImageUrlSV4, getJSON } from "./components/utils/s3-content";
import { HeavenlyPowerContext, HeavenlyPowerContextBG } from "./components/heavenlyPower/heavenlyPowerContext";
import HeavenlyPowerPrayers from "./components/heavenlyPower/heavenlyPowerPrayers";
import styles from "./styles/HeavenlyPower.module.css";

const ImageKeys = [{ index: 1, key: "heavenly-power/parchment_background.jpg" }];
const JSONData = {
  englishKey: "heavenly-power/heavenly-power-english.json",
  spanishKey: "heavenly-power/heavenly-power-spanish.json",
};

// import bg_img from "./parchment_background.jpg";
// import HeavenlyPowerData from "./heavenly-power.json";

const HeavenlyPower = () => {
  const { language, setLanguage, setHomePage, ReportToGA, windowDimensions } = useGlobalContext();

  const { data, images, lang } = useLoaderData();

  // const data = HeavenlyPowerData;
  // const lang = "en";

  useEffect(() => {
    window.scrollTo(0, 0);
    setHomePage(false);
    if (lang === "sp") {
      setLanguage("sp");
    } else {
      setLanguage("en");
    }
    ReportToGA(window.location.pathname);
  }, []);

  if (data) {
    return (
      <HelmetProvider>
        <Helmet>
          <title>{language === "en" ? "Precious Blood Devotion" : "Devoción a la Preciosa Sangre"}</title>
          <meta name="description" content="The Most Powerful and Last Devotion of the age." />
          <meta
            name="keywords"
            content={[
              "Jesus",
              "Jesús",
              "Precious",
              "Blood",
              "Precious Blood",
              "God",
              "Devotion",
              "Christian",
              "Mercy",
              "misericordia",
              "Dios",
              "Passion",
              "Blood",
              "Christ",
              "Lord",
              "Love",
              "zeal",
              "king",
              "Dios",
              "amor",
            ]}
          />
        </Helmet>
        <main className={styles["heavenly-power-page"]}>
          <section className={styles["heavenly-power-content"]}>
            <img src={images[0]} className={styles["heavenly-power-bg"]} alt={"parchment_background"} />
            {windowDimensions.width < 1200 ? (
              <HeavenlyPowerContext context={data["context"]} />
            ) : (
              <HeavenlyPowerContextBG context={data["context"]} />
            )}
          </section>
          <section className={styles["heavenly-power-prayer-content"]}>
            <img src={images[0]} className={styles["heavenly-power-bg-btm"]} alt={"parchment_background"} />
            <HeavenlyPowerPrayers prayers={data["prayers"]} prayerMsg={data.context.prayerMsg} />
          </section>
        </main>
      </HelmetProvider>
    );
  } else {
    return <div></div>;
  }
};

export async function HeavenlyPowerLoader({ params }) {
  var data = {};

  const SignImgUrls = async () => {
    let imageList = [];
    const results = ImageKeys.map(async ({ key }) => {
      return getImageUrlSV4(key);
    });
    Promise.all(results).then((signedImages) => {
      // setImages(signedImages);
      for (let i = 0; i < signedImages.length; i++) {
        imageList.push(signedImages[i]);
      }
      // imageList = signedImages;
    });
    return imageList;
  };

  //// UNCOMMMENT CODE BELOW AND RETURN DATA INSTEAD OF ""
  const GetJSONData = async () => {
    if (params.lang === "sp") {
      //2. Call function to retrieve data from S3
      const result = await getJSON(JSONData["spanishKey"]);
      return JSON.parse(result);
    } else {
      const result = await getJSON(JSONData["englishKey"]);
      return JSON.parse(result);
    }
  };

  data.data = await GetJSONData();
  data.images = await SignImgUrls();
  data.lang = params.lang;
  // data.data = "test";
  // data.lang = "en";
  return data;
}

export default memo(HeavenlyPower);
