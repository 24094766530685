import React, { useState, useEffect, memo } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/HeavenlyTraces.module.css";

// Font Awesome Icon Inserts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@awesome.me/kit-72510df5aa/icons";

const Card = ({ props, image, index, cardVisitedCallback, language }) => {
  const { id, color, eventName, year, content, minimizeHeader, location } = props;
  const [backContentId, setBackContentId] = useState(0);
  const [isStartOfContent, setIsStartOfContent] = useState(true);
  const [isEndOfContent, setIsEndOfContent] = useState(false);
  const [seenAllPagesOnCard, setSeenAllPagesOnCard] = useState(false);

  let leftArrowClass = isStartOfContent
    ? styles["grey-arrow"] + " " + styles["ht-arrow"]
    : styles[color + "-arrow"] + " " + styles["ht-arrow"];
  let rightArrowClass = isEndOfContent
    ? styles["grey-arrow"] + " " + styles["ht-arrow"]
    : styles[color + "-arrow"] + " " + styles["ht-arrow"];

  rightArrowClass = seenAllPagesOnCard ? rightArrowClass : rightArrowClass + " " + styles["bounce-arrow"];

  useEffect(() => {
    if (backContentId === content.length - 1) {
      setIsEndOfContent(true);
      setIsStartOfContent(false);
      setSeenAllPagesOnCard(true);
    } else if (backContentId === 0) {
      setIsEndOfContent(false);
      setIsStartOfContent(true);
    } else {
      setIsEndOfContent(false);
      setIsStartOfContent(false);
    }
  }, [backContentId]);

  const SwitchCardContent = (plusOrMinusOne) => {
    setBackContentId((prevState) => {
      var newId = prevState + plusOrMinusOne;
      if (newId >= content.length || newId < 0) {
        return prevState;
      } else {
        return newId;
      }
    });
  };

  const CardSelectedFunction = (index) => {
    cardVisitedCallback(index);
  };
  return (
    <article
      onMouseEnter={() => CardSelectedFunction(index)}
      id={`trace-${index}`}
      key={id}
      className={styles["ht-card"]}
    >
      {/* Front side of card */}
      <div className={styles[color + "-card"] + " " + styles["ht-card-front"] + " " + styles["ht-card-side"]}>
        <img src={image} className={year === 1920 ? styles["vist-img"] : styles["front-img"]} alt={eventName} />
        <div className={styles["ht-card-front-text"]}>
          <h5>{eventName}</h5>
          {location && <h5 style={{ fontStyle: "italic", letterSpacing: ".1em" }}>{location}</h5>}
          <h5>{year}</h5>
        </div>
      </div>
      <div className={styles[color + "-card"] + " " + styles["ht-card-back"] + " " + styles["ht-card-side"]}>
        <div className={styles["ht-card-back-header"]}>
          <div className={styles["ht-back-arrow"]}>
            {/* <i onClick={() => SwitchCardContent(-1)} className={leftArrowClass}></i> */}
            <FontAwesomeIcon
              onClick={() => SwitchCardContent(-1)}
              className={leftArrowClass}
              icon={fas.faCircleArrowLeft}
            />
          </div>
          <div className={styles["ht-back-title"]}>
            {backContentId !== content.length - 1 && minimizeHeader ? (
              <ItalicsInParagraph content={content[backContentId].title} indent={false} minimizeHeader={true} />
            ) : (
              <ItalicsInParagraph content={content[backContentId].title} indent={false} minimizeHeader={false} />
            )}
          </div>
          <div className={styles["ht-forward-arrow"]}>
            {/* <i onClick={() => SwitchCardContent(1)} className={rightArrowClass}></i> */}
            <FontAwesomeIcon
              onClick={() => SwitchCardContent(1)}
              className={rightArrowClass}
              icon={fas.faCircleArrowRight}
            />
          </div>
        </div>

        {/* Back side of card */}
        {content[backContentId].title !== "Links" && content[backContentId].title !== "Enlaces" ? (
          <div className={styles["ht-card-back-content"] + " " + styles[color]}>
            {content[backContentId].content.map((paragraph, index) => {
              const { subTitle, text } = paragraph;

              if (subTitle && subTitle.charAt(0) === "#") {
                // let subTitleArr = subTitle.substr(1, subTitle.length - 1).split("@");
                return (
                  <div key={index} className={styles["ht-content-paragraph"]}>
                    {/* <p className="center-txt">{subTitle.substr(1, subTitle.length - 1)}</p> */}
                    <ItalicsInParagraph content={subTitle} indent={false} className={styles["center-txt"]} ct={true} />
                    {/* <p style={{ textIndent: "0px" }}>{text}</p> */}
                    <ItalicsInParagraph content={text} indent={false} />
                  </div>
                );
              } else if (subTitle && subTitle.charAt(0) === "*") {
                return (
                  <div key={index} className={styles["ht-content-paragraph"]}>
                    <p style={{ marginTop: "1em" }} className={styles["center-txt"]}>
                      {subTitle.substr(1, subTitle.length - 1)}
                    </p>
                    {/* <p style={{ textIndent: "0px" }}>{text}</p> */}
                    <ItalicsInParagraph content={text} indent={true} />
                  </div>
                );
              } else {
                return (
                  <div key={index} className={styles["ht-content-paragraph"]}>
                    {subTitle && (
                      <ItalicsInParagraph
                        content={subTitle}
                        indent={false}
                        className={styles["center-txt"]}
                        ct={true}
                      />
                    )}
                    <ItalicsInParagraph content={text} indent={true} />
                  </div>
                );
              }
            })}
          </div>
        ) : (
          <div className={styles["ht-card-back-content"] + " " + styles[color]}>
            {content[backContentId].content.map((link, index) => {
              if (link.url) {
                //Web Sources
                return (
                  <div key={index} className={styles["ht-back-links"]}>
                    <a href={link.url} target="_blank">
                      <ItalicsInSources content={index + 1 + ". " + link.source} />
                    </a>
                  </div>
                );
              } else if (link.source.charAt(0) === "#") {
                //Book Sources, we still want numbered
                return (
                  <div key={index} className={styles["ht-back-links"]}>
                    <ItalicsInSources content={index + 1 + ". " + link.source.substr(1, link.source.length - 1)} />
                  </div>
                );
              } else {
                //Photo credits or any comments we don't want to number
                return (
                  <div key={index} className={styles["ht-back-links"]}>
                    <ItalicsInSources content={link.source} />
                  </div>
                );
              }
            })}
          </div>
        )}
        <BottomNavigation language={language} name={props.image} />
      </div>
    </article>
  );
};

const BottomNavigation = ({ language, name }) => {
  const navigate = useNavigate();
  const readOnPage = () => {
    navigate(`../ht_article/${name}/${language || "en"}`);
  };
  return (
    <div onClick={() => readOnPage()} className={styles["ht-btm-nav-container"]}>
      {language === "sp" ? <p>Leer Artículo en Pantalla Completa</p> : <p>Read Article on Full Screen</p>}
    </div>
  );
};

// If text found in content has an @, it will be italicized
// If text found in content has an @, followed by a ^, it will be bolded
export const ItalicsInParagraph = ({ content, indent, className = "", minimizeHeader = null, ct = false }) => {
  // console.log(content);
  let contentArr = content.split("@");
  if (indent) {
    return (
      <p>
        {contentArr.map((txt, index) => {
          // normal, bold, italic condition statement
          if (index % 2 === 0) {
            return (
              <span key={index} className={className}>
                {txt}
              </span>
            );
          } else if (txt.charAt(0) === "^") {
            return (
              <span key={index} style={{ fontWeight: "bold" }} className={className}>
                {txt.substr(1, txt.length - 1)}
              </span>
            );
          } else {
            return (
              <span key={index} style={{ fontStyle: "italic" }} className={className}>
                {txt}
              </span>
            );
          }
        })}
      </p>
    );
  } else if (minimizeHeader) {
    if (content !== "Study of Preservation" && content !== "Investigations") {
      return (
        <p>
          <span style={{ fontWeight: "bold" }} className={className + " " + styles["minimized-header-font"]}>
            {content}
          </span>
        </p>
      );
    } else {
      return (
        <p>
          <span className={className}>{content}</span>
        </p>
      );
    }
  } else {
    // To be able to italize parts of subtitles
    if (ct) {
      return (
        <p className={className}>
          {contentArr.map((txt, index) => {
            // normal, bold, italic condition statement
            // !!!!! Write ternary operator for txt if it has a #
            txt = txt.charAt(0) === "#" ? txt.substr(1, txt.length - 1) : txt;
            if (index % 2 === 0) {
              return <span key={index}>{txt}</span>;
            } else {
              return (
                <span key={index} style={{ fontStyle: "italic" }}>
                  {txt}
                </span>
              );
            }
          })}
        </p>
      );
    }
    return (
      <p style={{ textIndent: "0px" }}>
        {contentArr.map((txt, index) => {
          // normal, bold, italic condition statement
          if (index % 2 === 0) {
            return (
              <span key={index} className={className}>
                {txt}
              </span>
            );
          } else if (txt.charAt(0) === "^") {
            return (
              <span key={index} style={{ fontWeight: "bold" }} className={className}>
                {txt.substr(1, txt.length - 1)}
              </span>
            );
          } else {
            return (
              <span key={index} style={{ fontStyle: "italic" }} className={className}>
                {txt}
              </span>
            );
          }
        })}
      </p>
    );
  }
};

export const ItalicsInSources = ({ content }) => {
  let contentArr = content.split("$");

  return (
    <p>
      {contentArr.map((txt, index) => {
        // normal, bold, italic condition statement
        if (index % 2 === 0) {
          return <span key={index}>{txt}</span>;
        } else if (txt.charAt(0) === "^") {
          return (
            <span key={index} style={{ fontWeight: "bold" }}>
              {txt.substr(1, txt.length - 1)}
            </span>
          );
        } else {
          return (
            <span key={index} style={{ fontStyle: "Italic" }}>
              {txt}
            </span>
          );
        }
      })}
    </p>
  );
};

export default memo(Card);
