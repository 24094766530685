import React, { memo } from "react";

const DMGoldArrows = ({ props }) => {
  const { message, link, classStyle, btnStyle } = props;

  return (
    <div className={classStyle + " dm-link-container"}>
      <div id="arrowAnim">
        <div className="arrow delay1"></div>

        <div className="arrow delay2"></div>

        <div className="arrow delay3"></div>
      </div>
      <div id="message">
        <a href={link} target="_blank" className={btnStyle}>
          <p> {message}</p>
        </a>
      </div>
    </div>
  );
};

export default memo(DMGoldArrows);
