/* eslint-disable */
import React, { useState, useEffect, memo } from "react";
import { useGlobalContext } from "../Context";
// import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { FooterEnglish, FooterSpanish } from "../data/footer";
import Footer from "./components/footer.js";
import { SupportEnglish, SupportSpanish } from "../data/support";
import { getImageUrlSV4 } from "./components/utils/s3-content";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLoaderData } from "react-router-dom";
import styles from "./styles/Attributions.module.css";

// Font Awesome Icon Inserts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, fad } from "@awesome.me/kit-72510df5aa/icons";

const Support = () => {
  const { lang } = useLoaderData();
  const { setLanguage, language, setHomePage, ReportToGA } = useGlobalContext();
  const [data, setData] = useState(null);
  const [showEmail, setShowEmail] = useState(false);
  const venmoName = "@zealOfficial";
  const email = "projectzealdm@gmail.com";

  const CopyToClipBoard = (txt) => {
    if (txt === email) setShowEmail(true);

    navigator.clipboard.writeText(txt);
    let greeting = null;
    if (language === "sp") {
      greeting = "Copiado ";
    } else {
      greeting = "Copied ";
    }
    alert(greeting + txt);
  };

  useEffect(() => {
    if (language === "sp") {
      setData(SupportSpanish);
    } else {
      setData(SupportEnglish);
    }
  }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setHomePage(false);
    if (lang === "sp") {
      setLanguage("sp");
    } else {
      setLanguage("en");
    }
    ReportToGA(window.location.pathname);
  }, []);

  if (data) {
    return (
      <HelmetProvider>
        <Helmet>
          <title>{language === "en" ? "Provide Support" : "Dar Apoyo"}</title>
          <meta name="description" content="Defending the Faith Zealously." />
          <meta name="keywords" content={["zeal", "contact", "support", "apoyo"]} />
        </Helmet>
        <main className={styles["support-contact-page"]}>
          <section className={styles["support-section"]}>
            <h1 className={styles["section-title"]}>{data.SupportTitle}</h1>
            <div className={styles["support-box"]}>
              <div className={styles["support-greeting"]}>
                <p>{data.Greeting}</p>
                <div className={styles["support-impact"]}>
                  {data.DonationImpact.map((line, index) => {
                    const lineArray = line.split("-");
                    return (
                      <div className={styles["support-amount-box"]} key={index}>
                        <div className={styles["support-amount"]}>
                          {/* <i id={styles[`support-i-${index}`]} className="fa-duotone fa-hand-holding-heart"></i> */}
                          <FontAwesomeIcon id={styles[`support-i-${index}`]} icon={fad.faHandHoldingHeart} />
                          <p style={{ color: "var(--glory-blue-300)" }} className={styles["support-amount-text"]}>
                            {lineArray[0]}
                          </p>
                        </div>
                        <p className={styles["support-amount-text"]}>{lineArray[1]}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={styles["support-payments"]}>
                {/* <DonationComp data={data} language={language} /> */}
                <button onClick={() => CopyToClipBoard(venmoName)}>
                  <img className={styles["venmo-img"]} src={getImageUrlSV4("other/venmo.png")} alt="Venmo Logo" />
                  <span>{venmoName}</span>
                </button>
              </div>
            </div>
          </section>
          <section className={styles["contact-section"]}>
            <h1 className={styles["section-title"]}>{data.ContactTitle}</h1>
            {showEmail ? (
              <p>{email}</p>
            ) : (
              // <i onClick={() => CopyToClipBoard(email)} className="fa-solid fa-envelope"></i>
              <FontAwesomeIcon onClick={() => CopyToClipBoard(email)} icon={fas.faEnvelope} />
            )}
          </section>
          <Footer theme={"dark"} footer={language === "en" ? FooterEnglish : FooterSpanish} />
        </main>
      </HelmetProvider>
    );
  } else {
    return <div></div>;
  }
};

export async function SupportLoader({ params }) {
  var data = {};

  data.lang = params.lang;
  return data;
}

// const DonationComp = ({ data, language }) => {
//   const [donationAmount, setDonationAmount] = useState(3);
//   const [disbableBtn, setDisableBtn] = useState(false);
//   const [currency, setCurrency] = useState("USD");
//   const [currencyData, setCurrencyData] = useState(null);

//   //Will be sent to PayPal component
//   const donation = {
//     description: "Donation to Zeal",
//     price: donationAmount,
//     currency: currency,
//   };

//   useEffect(() => {
//     setCurrencyData(require("../data/paypal-currencies.json"));
//   }, []);

//   const validateDonation = (e) => {
//     // const amount = Math.round(e.target.value * 100) / 100;
//     const amount = Math.floor(e.target.value);
//     //If number >= $1
//     if (amount >= 1) {
//       setDonationAmount(amount);
//       setDisableBtn(false);
//       //Render Paypal Component and remove alert
//     } else if (amount === 0) {
//       setDonationAmount("");
//       setDisableBtn(true);
//     }
//   };

//   const validateCurrency = (e) => {
//     setCurrency(e.target.value);
//   };

//   return (
//     <div className="donationSection">
//       <div className="donationForms">
//         <form className="donationCountry">
//           <div>
//             <p>{data.DonationCurrency}</p>
//           </div>
//           <div>
//             <select id="countryCode" name="countryCode" value={currency} onChange={validateCurrency}>
//               {currencyData &&
//                 currencyData.currencies.map((curr, index) => {
//                   return (
//                     <option key={index} value={curr.code}>
//                       {curr.country}
//                     </option>
//                   );
//                 })}
//             </select>
//           </div>
//         </form>
//         <div className="donationBox">
//           <div>
//             <p>{data.DonationAmount}</p>
//           </div>
//           <div>
//             <span>$ </span>
//             <input
//               className="formBox"
//               type="number"
//               id="donation"
//               name="donation"
//               value={donationAmount}
//               onChange={validateDonation}
//             ></input>
//           </div>
//         </div>
//       </div>
//       <PayPalScriptProvider
//         options={{
//           "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
//           components: "buttons,funding-eligibility",
//           "enable-funding": "venmo",
//           currency: "USD",
//           "disable-funding": "paylater",
//         }}
//       >
//         <PaypalCheckoutButtons msgData={data} disable={disbableBtn} donation={donation} />
//       </PayPalScriptProvider>
//     </div>
//   );
// };

// const PaypalCheckoutButtons = ({ msgData, disable, donation }) => {
//   //1. thinking - (specifying currency & donation), 2. checkout - (ready to pay), 3. complete - (donation made)
//   const [donationStatus, setDonationStatus] = useState("thinking");
//   const [error, setError] = useState(null);

//   const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

//   useEffect(() => {
//     setDonationStatus("thinking");
//     dispatch({
//       type: "resetOptions",
//       value: {
//         ...options,
//         currency: donation.currency,
//       },
//     });
//   }, [donation]);

//   const handleApprove = (orderId) => {
//     // Call backend function to send email to account maybe
//     // if response is success
//     console.log("success");
//     setDonationStatus("complete");
//     setError(null);
//   };

//   if (donationStatus === "thinking") {
//     return (
//       <div onClick={() => setDonationStatus("checkout")} className="readyBtn">
//         {msgData.Ready}
//       </div>
//     );
//   } else if (donationStatus === "complete") {
//     return (
//       <div className="donationComplete">
//         <p>{msgData.DonationCompleted}</p>
//       </div>
//     );
//   } else {
//     return (
//       <div className="payPal-btns">
//         <PayPalButtons
//           disabled={disable}
//           style={{ label: "donate" }}
//           forceReRender={[donation.price, donation.currency]}
//           createOrder={(data, actions) => {
//             return actions.order.create({
//               purchase_units: [
//                 {
//                   description: donation.description,
//                   amount: {
//                     value: donation.price,
//                     breakdown: {
//                       item_total: {
//                         currency_code: donation.currency,
//                         value: donation.price,
//                       },
//                     },
//                   },
//                   items: [
//                     {
//                       name: "donation to Zeal",
//                       quantity: "1",
//                       unit_amount: {
//                         currency_code: donation.currency,
//                         value: donation.price,
//                       },
//                       category: "DONATION",
//                     },
//                   ],
//                 },
//               ],
//             });
//           }}
//           onApprove={async (data, actions) => {
//             const order = await actions.order.capture();
//             console.log("order", order);

//             handleApprove(data.orderID);
//           }}
//           onError={(err) => {
//             console.error("error has occured", err);
//           }}
//         />
//       </div>
//     );
//   }
// };

export default memo(Support);
