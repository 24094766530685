/* eslint-disable */
import React, { useEffect, memo } from "react";
import { useGlobalContext } from "../Context";
import HeavenlyEvents from "./components/heavenlyTraces/ht-layout";
import { FooterEnglish, FooterSpanish } from "../data/footer";
import Footer from "./components/footer.js";
import { getImageUrlSV4, getJSON } from "./components/utils/s3-content";
import { useLoaderData } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga";
//1. Load new data object with keys (english & spanish)
import styles from "./styles/HeavenlyTraces.module.css";

// remember to UPDATE utils/content!!! Whenever adding a story.
const ImageKeys = [
  { index: 1, key: "heavenly-traces/ht-shroud-turin.jpg" },
  { index: 2, key: "heavenly-traces/ht-our-lady-guadalupe.jpg" },
  { index: 3, key: "heavenly-traces/ht-fatima.jpg" },
  { index: 4, key: "heavenly-traces/ht-lanciano.jpeg" },
  { index: 5, key: "heavenly-traces/ht-miracle-of-vistula-left.jpeg" },
  { index: 7, key: "heavenly-traces/ht-manila.jpg" },
  { index: 6, key: "heavenly-traces/ht-zeitoun.jpg" },
  { index: 8, key: "heavenly-traces/ht-medjugorje.jpg" },
  { index: 9, key: "heavenly-traces/ht-georgeWashington.jpg" },
  { index: 10, key: "heavenly-traces/ht-tixtla.jpg" },
  { index: 11, key: "heavenly-traces/ht-ireland.jpg" },
  { index: 12, key: "heavenly-traces/ht-bueno-aires.jpg" },
  { index: 13, key: "heavenly-traces/ht-stigmata.jpg" },
  { index: 14, key: "heavenly-traces/ht-incorruptible-saint.png" },
  { index: 15, key: "heavenly-traces/ht-la-vang.jpg" },
  { index: 16, key: "heavenly-traces/ht-tapao.jpg" },
  { index: 17, key: "heavenly-traces/ht-garabandal.png" },
  { index: 18, key: "heavenly-traces/ht-our-lady-las-lajas.jpg" },
];

const JSONData = {
  englishKey: "heavenly-traces/ht-english-dev.json",
  spanishKey: "heavenly-traces/ht-spanish-dev.json",
};

const HeavenlyTraces = () => {
  // const [data, setData] = useState(null);
  // const [images, setImages] = useState(null);
  const { language, setLanguage, setHomePage, ReportToGA } = useGlobalContext();
  const { data, images, lang } = useLoaderData();

  // Render the Navbar, Render the correct Org Logo
  useEffect(() => {
    setHomePage(false);
    window.scrollTo(0, 0);

    // setColoredZealLogo(true);

    if (lang === "sp") {
      setLanguage("sp");
    } else {
      setLanguage("en");
    }
    ReportToGA(window.location.pathname);
  }, []);

  // if (data) {
  if (data) {
    return (
      <HelmetProvider>
        <Helmet>
          <title>{language === "sp" ? "Huellas de Cielo" : "Heavenly Traces"}</title>
          <meta name="description" content="What the world doesn't show you." />
          <meta
            name="keywords"
            content={[
              "Heaven",
              "Cielo",
              "noticias",
              "News",
              "miracle",
              "God",
              "Traces",
              "milagro",
              "apparitions",
              "Love",
              "zeal",
              "christian",
            ]}
          />
        </Helmet>
        <main className={styles["heavenly-Traces-page"]}>
          <section className={styles["heavenly-traces-section"]}>
            <HeavenlyEvents props={data.Miracles} title={data.Title} images={images} />
          </section>
          <Footer theme={"dark"} footer={language === "en" ? FooterEnglish : FooterSpanish} />
        </main>
      </HelmetProvider>
    );
  } else {
    return <div></div>;
  }
};

export async function HeavenlyTracesLoader({ params }) {
  var data = {};

  // Set the Language in Global Context, Query the data for the HT stories
  const GetJSONData = async () => {
    if (params.lang === "sp") {
      const result = await getJSON(JSONData["spanishKey"]);
      // console.log(JSON.parse(result));
      return JSON.parse(result);
    } else {
      const result = await getJSON(JSONData["englishKey"]);
      // console.log(JSON.parse(result));
      return JSON.parse(result);
    }
  };

  // Return all the required Images for the Page signed with v4
  const SignImgUrls = async () => {
    const imageMap = new Map();
    const results = ImageKeys.map(async ({ key }) => {
      return getImageUrlSV4(key);
    });
    Promise.all(results).then((signedImages) => {
      //Iterate through image Keys to reattach num to object, (Need to turn signedImages into an object)
      for (let i = 0; i < signedImages.length; i++) {
        imageMap.set(ImageKeys[i]["index"], signedImages[i]);
      }
      // setImages(imageMap);
    });
    return imageMap;
  };

  data.data = await GetJSONData();
  data.images = await SignImgUrls();
  data.lang = params.lang;

  return data;
}

export default memo(HeavenlyTraces);
