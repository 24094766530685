import React, { memo } from "react";
import { useGlobalContext } from "../../Context";
import { Link } from "react-router-dom";
import { SocialLinks } from "../../data/social-links";
import styles from "../styles/footer.module.css";

// Font Awesome Icon Inserts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@awesome.me/kit-72510df5aa/icons";

const Footer = ({ theme, footer }) => {
  const Color = theme === "dark" ? "white" : "black";
  const bgColor = theme === "dark" ? "black" : "white";

  return (
    <footer style={{ background: bgColor }} className={styles["footer-section"]}>
      <div className={styles["footer-subsection"]}>
        {/* <div className={styles["connect"]}>
          <p style={{ color: Color }}>{footer.connectTitle}</p>
          <ul className={styles["social-links"]}>
            {SocialLinks.map(({ media, url }, index) => {
              return (
                <li key={index}>
                  <a href={url} target="_blank">
                    <i className={`fab fa-${media}`}></i>
                  </a>
                </li>
              );
            })}
          </ul>
        </div> */}
        <div className={styles["contact"]}>
          <Link to="/support-zeal">
            {/* <i className={`fa-solid fa-book-medical ${styles["support-logo"]}`}></i> */}
            <FontAwesomeIcon className={styles["support-logo"]} icon={fas.faBookMedical} />
            <p>{footer.supportTitle}</p>
          </Link>
          {/* <p style={{ color: Color }}>{footer.supportTitle}</p> */}
        </div>
      </div>

      <div className={styles["copyright"]}>
        <p style={{ color: Color }}>
          <span style={{ fontSize: "1.2em" }} className={styles["bold"]}>
            &copy;
          </span>{" "}
          {footer.copyRightTitle}
        </p>
        <p style={{ color: Color }}>{footer.copyRightMsg}</p>
      </div>
    </footer>
  );
};

export default memo(Footer);
