import React, { useState, useEffect, memo } from "react";
import ThemeSelectedQuote from "./theme_selected_quote";
import { StairCaseComplete } from "../../../fonts/svg/stairCaseComplete";
import styles from "../../styles/PowerfulLessons.module.css";
// import "./styles/theme_stars.css";

// Font Awesome Icon Inserts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@awesome.me/kit-72510df5aa/icons";

const StarsRepresentingLessons = ({ notebookName, themeData, themeProperty, backToThemePageFunc }) => {
  //basically themeData quotes
  const [themeDataContent, setThemeDataContent] = useState([]);
  // const [bTrueDMFalseBothNull, setbTrueDMFalseBothNull] = useState(null);
  const [selectedQuote, setSelectedQuote] = useState({ qte: null });
  // variable that sets up orb positions of the quote selectors
  const [orbPositions, setOrbPositions] = useState([]);
  // variable that sets up star positions of the quote selectors
  const [starPositions, setStarPositions] = useState([]);

  const orbAnimationClasses = [
    "orb-trans-1",
    "orb-trans-2",
    "orb-trans-3",
    "orb-trans-4",
    "orb-trans-5",
    "orb-trans-6",
    "orb-trans-7",
  ];

  // load the data from props in the appropiate useStateContainers
  useEffect(() => {
    setThemeDataContent(themeData.quotes);
  }, [themeData]);

  useEffect(() => {
    //Only want to load array once, not everytime we take a read quote out of themeDataContent
    if (orbPositions.length === 0) {
      const newArray = [];
      themeDataContent.forEach((element) => {
        newArray.push([Math.floor(Math.random() * (100 - 30) + 30), Math.floor(Math.random() * (60 - 40) + 40)]);
      });
      setOrbPositions(newArray);
    }
  }, [themeDataContent]);

  useEffect(() => {
    const newArray = [];
    for (let i = 0; i < 28; i++) {
      if (i < 7) {
        //Get left side star positions
        newArray.push([GetSideStarTopPos(), GetSideStarRightPos(true)]);
      } else if (i < 14) {
        //Get right side star positions
        newArray.push([GetSideStarTopPos(), GetSideStarRightPos(false)]);
      } else {
        //Get top side star positions (right => left)
        if (i % 2 === 0) {
          newArray.push([GetTopStarTopPos(), GetTopStarRightPos(true)]);
        } else {
          newArray.push([GetTopStarTopPos(), GetTopStarRightPos(false)]);
        }
      }
    }
    setStarPositions(newArray);
  }, []);

  const GetTopStarTopPos = () => {
    return Math.floor(Math.random() * 29);
  };
  const GetTopStarRightPos = (isLeft) => {
    if (isLeft) {
      return Math.floor(Math.random() * (100 - 50) + 50);
    } else {
      return Math.floor(Math.random() * 100);
    }
  };
  const GetSideStarRightPos = (isLeft) => {
    if (isLeft) {
      return Math.floor(Math.random() * (100 - 80) + 80);
    } else {
      return Math.floor(Math.random() * 20);
    }
  };

  const GetSideStarTopPos = () => {
    return Math.floor(Math.random() * 50);
  };

  const GetOrbClass = (index) => {
    return orbAnimationClasses[index % 6];
  };

  const UserSelectsQuote = (qte) => {
    setSelectedQuote({ qte: qte });
    setThemeDataContent(themeDataContent.filter((obj) => obj.id !== qte.id));
  };

  const UserDeselectsQuote = () => {
    setSelectedQuote({ qte: null });
  };

  return (
    <>
      {/* Container for Theme Lessons */}
      {/* <div className="theme-title">
        <h5 id="theme-title">{themeProperty && themeProperty.theme}</h5>
      </div> */}

      <div className={styles["theme-grid-container"]}>
        <button onClick={() => backToThemePageFunc()}>
          {/* <i className={"fa-solid fa-house " + styles["theme-pg-back-arrow"]} /> */}
          <FontAwesomeIcon className={styles["theme-pg-back-arrow"]} id="nav1i" icon={fas.faHouse} />
        </button>
        <StairCaseComplete />
        {/* Make sure we have an array of all the positions first before rendering */}
        {orbPositions.length > 0 &&
          themeDataContent.map((qte) => {
            const orbClass = styles[GetOrbClass(qte.id)] + " " + styles["lessons-orbs"];
            return (
              <div
                onClick={() => UserSelectsQuote(qte)}
                key={qte.id}
                style={{ top: `${orbPositions[qte.id - 1][0]}%`, right: `${orbPositions[qte.id - 1][1]}%` }}
                className={orbClass}
              ></div>
            );
          })}
        {selectedQuote.qte !== null && (
          <ThemeSelectedQuote
            qte={selectedQuote.qte}
            themeProperties={themeProperty}
            userDeselectsQuote={UserDeselectsQuote}
            notebookName={notebookName}
          />
        )}

        {/* right stars */}
        {starPositions.length > 0 && (
          <>
            {/* Stars */}
            <i
              style={{ top: `${starPositions[0][0]}%`, right: `${starPositions[0][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star1"]}
            ></i>
            <i
              style={{ top: `${starPositions[1][0]}%`, right: `${starPositions[1][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star2"]}
            ></i>
            <i
              style={{ top: `${starPositions[2][0]}%`, right: `${starPositions[2][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star3"]}
            ></i>
            <i
              style={{ top: `${starPositions[3][0]}%`, right: `${starPositions[3][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star4"]}
            ></i>
            <i
              style={{ top: `${starPositions[4][0]}%`, right: `${starPositions[4][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star5"]}
            ></i>
            <i
              style={{ top: `${starPositions[5][0]}%`, right: `${starPositions[5][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star6"]}
            ></i>
            <i
              style={{ top: `${starPositions[6][0]}%`, right: `${starPositions[6][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star7"]}
            ></i>
            <i
              style={{ top: `${starPositions[7][0]}%`, right: `${starPositions[7][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star1"]}
            ></i>
            <i
              style={{ top: `${starPositions[8][0]}%`, right: `${starPositions[8][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star2"]}
            ></i>
            <i
              style={{ top: `${starPositions[9][0]}%`, right: `${starPositions[9][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star3"]}
            ></i>
            <i
              style={{ top: `${starPositions[10][0]}%`, right: `${starPositions[10][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star4"]}
            ></i>
            <i
              style={{ top: `${starPositions[11][0]}%`, right: `${starPositions[11][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star5"]}
            ></i>
            <i
              style={{ top: `${starPositions[12][0]}%`, right: `${starPositions[12][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star6"]}
            ></i>
            <i
              style={{ top: `${starPositions[13][0]}%`, right: `${starPositions[13][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star7"]}
            ></i>
            {/* top stars */}
            <i
              style={{ top: `${starPositions[14][0]}%`, right: `${starPositions[14][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star1"]}
            ></i>
            <i
              style={{ top: `${starPositions[15][0]}%`, right: `${starPositions[15][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star2"]}
            ></i>
            <i
              style={{ top: `${starPositions[16][0]}%`, right: `${starPositions[16][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star3"]}
            ></i>
            <i
              style={{ top: `${starPositions[17][0]}%`, right: `${starPositions[17][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star4"]}
            ></i>
            <i
              style={{ top: `${starPositions[18][0]}%`, right: `${starPositions[18][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star5"]}
            ></i>
            <i
              style={{ top: `${starPositions[19][0]}%`, right: `${starPositions[19][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star6"]}
            ></i>
            <i
              style={{ top: `${starPositions[20][0]}%`, right: `${starPositions[20][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star7"]}
            ></i>
            <i
              style={{ top: `${starPositions[21][0]}%`, right: `${starPositions[21][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star1"]}
            ></i>
            <i
              style={{ top: `${starPositions[22][0]}%`, right: `${starPositions[22][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star2"]}
            ></i>
            <i
              style={{ top: `${starPositions[23][0]}%`, right: `${starPositions[23][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star3"]}
            ></i>
            <i
              style={{ top: `${starPositions[24][0]}%`, right: `${starPositions[24][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star4"]}
            ></i>
            <i
              style={{ top: `${starPositions[25][0]}%`, right: `${starPositions[25][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star5"]}
            ></i>
            <i
              style={{ top: `${starPositions[26][0]}%`, right: `${starPositions[26][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star6"]}
            ></i>
            <i
              style={{ top: `${starPositions[27][0]}%`, right: `${starPositions[27][1]}%` }}
              className={styles["icon-star"] + " " + styles["lessons-stars"] + " " + styles["star7"]}
            ></i>
          </>
        )}
      </div>
    </>
  );
};

export default StarsRepresentingLessons;
