import React, { useEffect, useState } from "react";
import { getImageUrlSV4, getJSON } from "./components/utils/s3-content";
import { useGlobalContext } from "../Context";

const WesternAppVideo = () => {
  const { windowDimensions, setMedVidLoaded } = useGlobalContext();
  const [scrnRatio, setScrnRatio] = useState(0);
  const [data, setData] = useState(null);

  useEffect(() => {
    setVideoUrls();
    setScrnRatio(windowDimensions.width / windowDimensions.height);
    const timeout = setTimeout(() => {
      var vid = document.getElementById("med-vid");
      vid.play();
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  // Encrypt Urls for videos for different screen sizes
  const setVideoUrls = async () => {
    const mobileVidTall = await getImageUrlSV4("video/ZealMapMBTall_dev.mp4");
    const mobileVid = await getImageUrlSV4("video/ZealMap_dev.mp4");
    const tabletVid = await getImageUrlSV4("video/ZealMapTablet_dev.mp4");
    const desktopVid = await getImageUrlSV4("video/ZealMapDesktop_dev.mp4");
    setData({ mobileTall: mobileVidTall, mobile: mobileVid, tablet: tabletVid, desktop: desktopVid });
  };

  const mbplay = () => {
    const timeout = setTimeout(() => {
      setMedVidLoaded(true);
    }, 750);
  };

  if (data && scrnRatio < 0.54) {
    return (
      <video
        key={1}
        id="med-vid"
        autoPlay={false}
        onPlay={() => setMedVidLoaded(true)}
        muted
        playsInline
        width="100%"
        preload="auto"
      >
        <source src={data.mobileTall} type="video/mp4" />
      </video>
    );
  } else if (data && windowDimensions.width < 400) {
    return (
      <video
        key={2}
        id="med-vid"
        autoPlay={false}
        onPlay={() => mbplay()}
        muted
        playsInline
        width="100%"
        preload="auto"
      >
        <source src={data.mobile} type="video/mp4" />
      </video>
    );
  } else if (data && scrnRatio < 0.9) {
    return (
      <video
        key={3}
        id="med-vid"
        autoPlay={false}
        onPlay={() => mbplay()}
        muted
        playsInline
        width="100%"
        height="100%"
        preload="auto"
      >
        <source src={data.tablet} type="video/mp4" />
      </video>
    );
  } else if (data) {
    return (
      <video
        key={4}
        id="med-vid"
        autoPlay={false}
        onPlay={() => mbplay()}
        muted
        playsInline
        width="100%"
        preload="auto"
      >
        <source src={data.desktop} type="video/mp4" />
      </video>
    );
  } else {
    <div></div>;
  }
};

export default WesternAppVideo;
