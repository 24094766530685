import React, { useEffect, useState, useRef, lazy, Suspense, memo } from "react";
// import Loading from "../fonts/svg/apparitions/Loading";
import { PassionSceneBox } from "../fonts/svg/passion/passionSceneBox";
import { PassionSceneSelectArt } from "../fonts/svg/passion/passionSceneSelectArt";
import { ParagraphParsing } from "./components/utils/paragraphParsing";
import styles from "./styles/ChristPassion.module.css";

import { useGlobalContext } from "../Context";

// Font Awesome Icon Inserts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@awesome.me/kit-72510df5aa/icons";

const TheStoryOverview = ({ payload }) => {
  // DISPLAYS THE HEADER OF THE WEBPAGE, AND BRIEF OVERVIEW OF THE CONTENT
  //      CHILD COMPONENT (TheStoreArticles): ...
  //  Args:
  //    header -> `app-story-header` value (object of keys: `title`, `messages`)
  //    content -> `app-story` value (array of scenes)

  const [sceneStoryToRead, setSceneStoryToRead] = useState(null);
  const [displaySceneSelection, setDisplaySceneSelection] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    setData(payload);
  }, [payload]);

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }, []);

  const loadStoryOfScene = (id) => {
    setSceneStoryToRead(id);
  };

  if (data) {
    const header = data["app-story-header"];
    const content = data["app-story"];
    let mainClassStyle = null;
    if (sceneStoryToRead) {
      mainClassStyle = `${styles["c-passion-main-container"]} ${styles["c-passion-main-container-scene-content"]}`;
    } else if (displaySceneSelection) {
      mainClassStyle = `${styles["c-passion-main-container"]} ${styles["c-passion-main-container-scenes"]}`;
    } else {
      mainClassStyle = `${styles["c-passion-main-container"]} ${styles["c-passion-main-container-overview"]}`;
    }
    return (
      <div className={mainClassStyle}>
        {/* Renders title in all cases except when user select scene to read. */}
        {!sceneStoryToRead && <h1 className={styles["c-passion-title"]}>{header.title}</h1>}

        {/* User will select arrow/btn to view scene selection */}
        {!displaySceneSelection && (
          <div className={styles["c-passion-overview-container"]}>
            {header.messages.map((paragraph, index) => {
              return (
                // <p key={index} className={styles["c-passion-overview-txt"]}>
                //   {paragraph}
                // </p>
                <ParagraphParsing paragraph={paragraph} k={index} txtClass={styles["c-passion-overview-txt"]} />
              );
            })}
            <button className={styles["c-passion-overview-btn"]} onClick={setDisplaySceneSelection}>
              <p>View Scene Selection</p>
              <div className={styles["c-passion-overview-arrows"]}>
                {/* <i style={{ color: "#e1bd45" }} className="fa-solid fa-forward fa-beat-fade"></i> */}
                <FontAwesomeIcon icon={fas.faForward} />
              </div>
            </button>
          </div>
        )}
        {/* Renders back btn, If user select scene to read */}

        {/* Renders scene selection */}
        {displaySceneSelection && !sceneStoryToRead && (
          <SceneSelection content={content} loadStoryOfScenefunc={loadStoryOfScene} />
        )}
        {/* Renders Scene Story for audience to read */}
        {sceneStoryToRead && <SceneStory scene={content[sceneStoryToRead - 1]} />}
        {sceneStoryToRead && (
          <div onClick={() => setSceneStoryToRead(null)} className={styles["c-passion-home-btn"]}>
            {/* <i
              className={`fa-solid fa-forward fa-rotate-180`}
              style={{
                color: "#ffffff",
              }}
            ></i> */}
            <FontAwesomeIcon
              style={{
                color: "#ffffff",
                rotate: "x 180deg",
              }}
              icon={fas.faForward}
            />
            Scenes
          </div>
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
};

// The Storay
const SceneSelection = ({ content, loadStoryOfScenefunc }) => {
  const [sceneSelected, setSceneSelected] = useState(null);
  const numberOfSceneStories = useRef(null);
  const { windowDimensions } = useGlobalContext();
  const [displayVisionArt, setDisplayVisionArt] = useState(false);

  useEffect(() => {
    // TODO: HEIGHT FACTOR NEEDS TO BE UPDATED WITH WINDOW DIMENSIONS
    if (sceneSelected) {
      //Change app.css var that animation `dropDownSubtitles` references`
      const height = heightFactorWidthScrn(); // (height for links) + btn height

      document.documentElement.style.setProperty("--passSubtitleLinkHeight", `${height}em`);
      //Change style property to animate the appearance of subtitle links
      const sceneElement = document.querySelector(`#c-passion-scene-select-subtitle-list-${sceneSelected}`);
      sceneElement.style.animation = `${styles["dropDownSubtitles"]} .5s ease forwards, ${styles["opacityOn"]} .5s ease .5s forwards`;
    }
  }, [sceneSelected]);

  useEffect(() => {
    const timeDelay = windowDimensions.width >= 1200 ? 1500 : 3000;
    const timeout = setTimeout(() => {
      setDisplayVisionArt(true);
    }, timeDelay);
    return () => clearTimeout(timeout);
  }, []);

  // 1st elem (line height), 2nd elem (extra space before btn), 3rd elem (if only one element, space for btn)
  const heightFactorWidthScrn = () => {
    if (windowDimensions.width < 375) {
      return numberOfSceneStories.current > 1 ? numberOfSceneStories.current * 1.5 + 1.6 : 3.4;
    } else if (windowDimensions.width < 700) {
      return numberOfSceneStories.current > 1 ? numberOfSceneStories.current * 1.7 + 1.4 : 3.4;
    } else if (windowDimensions.width < 800) {
      return numberOfSceneStories.current > 1 ? numberOfSceneStories.current * 2 + 2.1 : 4.4;
    } else if (windowDimensions.width < 950) {
      return numberOfSceneStories.current > 1 ? numberOfSceneStories.current * 2.1 + 2.1 : 4.4;
    } else if (windowDimensions.width < 1200) {
      return numberOfSceneStories.current > 1 ? numberOfSceneStories.current * 2.4 + 2.5 : 5.6;
    } else if (windowDimensions.width < 2200) {
      return numberOfSceneStories.current > 1 ? numberOfSceneStories.current * 2.1 + 2.3 : 5;
    } else if (windowDimensions.width < 2500) {
      return numberOfSceneStories.current > 1 ? numberOfSceneStories.current * 2.2 + 2.5 : 5.5;
    } else {
      return numberOfSceneStories.current > 1 ? numberOfSceneStories.current * 2.4 + 2.7 : 5.5;
    }
  };

  const sceneClicked = (id, numStories) => {
    if (sceneSelected === id) {
      setSceneSelected(null);
    } else {
      setSceneSelected(id);
      numberOfSceneStories.current = numStories;
    }
  };

  if (content) {
    return (
      <main className={styles["c-passion-story-titles"]}>
        {/* {content.story.map((paragraph, index) => {
          return <p className={styles["c-passion-overview"]}>{paragraph}</p>;
        })} */}

        {content.map((scene, index) => {
          return (
            <div
              onClick={() => sceneClicked(scene.id, scene.story.length)}
              key={index}
              className={styles["c-passion-scene-box-n-list"]}
            >
              <div className={styles["c-passion-scene-select-box"]}>
                <PassionSceneBox />
              </div>
              <div className={styles["c-passion-scene-select-title-cont"]}>
                <p>{scene.title}</p>
              </div>

              {sceneSelected == scene.id && (
                <div className={styles["c-passion-scene-select-subtitle-cont"]}>
                  <SceneSubTitles story={scene.story} id={scene.id} loadStoryOfScenefunc={loadStoryOfScenefunc} />
                </div>
              )}
            </div>
          );
        })}
        <div className={styles["c-passion-story-select-art"]}>{displayVisionArt && <PassionSceneSelectArt />}</div>
      </main>
    );
  } else {
    return <></>;
  }
};

const SceneSubTitles = ({ story, id, loadStoryOfScenefunc }) => {
  // `story` -> the list of `subStory`s for each scene
  // console.log(story);

  return (
    <ul
      id={`c-passion-scene-select-subtitle-list-${id}`}
      style={{ height: 0, opacity: 0 }}
      className={styles["c-passion-scene-select-subtitle-list"]}
    >
      {story.map((subStory, index) => {
        return (
          <li key={index} className={styles["subtitle-list-elements"]}>
            {subStory.subtitle}
          </li>
        );
      })}
      <button onClick={() => loadStoryOfScenefunc(id)} className={styles["c-passion-scene-select-btn"]}>
        Read More
      </button>
    </ul>
  );
};

const SceneStory = ({ scene }) => {
  //  Args:
  //    `scene` -> (object of keys: `id`, `title`, `story`[list] )
  // Words by God, are contained within a @ symbol, and will be bolded/italicised Gold
  const [subScenesToDisplay, setSubScenesToDisplay] = useState([]);
  const subSceneToggled = useRef(false);

  //Function to help collapse/open subscenes
  const refreshSubScenesToDisplay = (id) => {
    // console.log("id received -> ", id);
    if (subScenesToDisplay.includes(id)) {
      const updatedScenes = subScenesToDisplay.filter((subSceneId) => subSceneId !== id);
      setSubScenesToDisplay(updatedScenes);
      //console.log("scene deleted");
    } else {
      const updatedScenes = [...subScenesToDisplay, id];
      setSubScenesToDisplay(updatedScenes);
      //console.log("scene added");
    }
    subSceneToggled.current = true;
  };

  // useEffect(() => {
  //   console.log(subScenesToDisplay);
  // }, [subScenesToDisplay]);

  return (
    <div>
      <h2 id={styles["c-passion-scene-title"]}>{scene.title}</h2>
      <div>
        {scene.story.map((subScene, index) => {
          //destructure subScene
          const { subtitle, paragraphs, id } = subScene;
          //decision statement is allow collapsible subScenes
          if (scene.story.length === 1) {
            return (
              <section key={index}>
                <p className={styles["c-passion-scene-substory-title"]}>{subtitle}</p>
                <SubSceneParagraphs paragraphs={paragraphs} />
              </section>
            );
          } else if (!subSceneToggled.current && subScenesToDisplay.length === 0) {
            // logic if array is empty, we want to display only part of the first scene
            return (
              <section onClick={() => refreshSubScenesToDisplay(index)} key={index}>
                <p className={styles["c-passion-scene-substory-title"]}>{subtitle}</p>
                {index === 0 && <SubSceneParagraphs preview={paragraphs} />}
              </section>
            );
          } else {
            return (
              <section key={index}>
                <p
                  onClick={() => refreshSubScenesToDisplay(index)}
                  className={styles["c-passion-scene-substory-title"]}
                >
                  {subtitle}
                </p>
                {subScenesToDisplay.includes(index) && <SubSceneParagraphs paragraphs={paragraphs} />}
              </section>
            );
          }
        })}
      </div>
    </div>
  );
};

const SubSceneParagraphs = ({ paragraphs, preview }) => {
  if (preview) {
    const { txt, pageRef } = preview[0];
    return (
      <div className={styles["c-passion-paragraph-box"]} key={1}>
        <ParagraphParsing paragraph={txt.substr(0, 240) + " ..."} k={1} txtClass={styles["c-passion-paragraph"]} />
      </div>
    );
  } else {
    return (
      <>
        {paragraphs.map((paragraph, index) => {
          const { txt, pageRef } = paragraph;
          return (
            <div className={styles["c-passion-paragraph-box"]} key={index}>
              <ParagraphParsing paragraph={txt + " @" + pageRef} k={index} txtClass={styles["c-passion-paragraph"]} />
            </div>
          );
        })}
      </>
    );
  }
};

// const ParagraphParsing = ({ paragraph, k, txtClass }) => {
//   // @^ ... @ -> Gold Text
//   // @ ... @ -> Italicized
//   // @%r ... @ -> Red Text(r) , Green Text(g)

//   let contentArr = paragraph.split("@");

//   const colorMap = { r: "#cf1111", g: "#e1bd45" };
//   return (
//     <p key={k} className={txtClass}>
//       {contentArr.map((txt, index) => {
//         // normal, bold, italic condition statement
//         if (index % 2 === 0) {
//           return <span key={`${k}${index}`}>{txt}</span>;
//         } else if (txt.charAt(0) === "^") {
//           return (
//             <span
//               key={`${k}${index}`}
//               style={{
//                 color: "#d8b012e8",
//                 // textShadow: "0 0 0.03em black, 0 0 0.04em #341e8a, 0 0 0.10em #341e8a",
//                 // textShadow: "0 0 0.05em black, 0 0 0.1em #00662c, 0 0 0.15em #3a3210, 0 0 0.2em #c35d04",
//                 fontWeight: "bold",
//                 letterSpacing: ".015em",
//               }}
//             >
//               {txt.substr(1, txt.length - 1)}
//             </span>
//           );
//         } else if (txt.charAt(0) === "%") {
//           //Extract color from letter after %
//           const colorLetter = txt.charAt(1);
//           var colorForText = "black";
//           if (Object.hasOwn(colorMap, colorLetter)) {
//             colorForText = colorMap[colorLetter];
//           }
//           return (
//             <span key={`${k}${index}`} style={{ fontWeight: "bold", color: colorForText }}>
//               {txt.substr(2, txt.length - 1)}
//             </span>
//           );
//         } else {
//           return (
//             <span key={`${k}${index}`} style={{ fontStyle: "italic" }}>
//               {txt}
//             </span>
//           );
//         }
//       })}
//     </p>
//   );
// };

export default memo(TheStoryOverview);
