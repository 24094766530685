import React, { useState, useEffect, memo } from "react";
import { useGlobalContext } from "../../../Context";
import { ItalicsInParagraph, ItalicsInSources } from "../heavenlyTraces/ht-card";
import { GetAmountSlideStoryByVertical } from "../utils/moveStoriesVertically";
import styles from "../../styles/Rosary.module.css";
import pStyles from "../../styles/PowerfulLessons.module.css";
import hStyles from "../../styles/HeavenlyTraces.module.css";

// Font Awesome Icon Inserts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@awesome.me/kit-72510df5aa/icons";

const MariamApparitions = ({ apparitions, rosaryFillers, images, BtmNav }) => {
  const { windowDimensions } = useGlobalContext();
  const [selectedStory, setSelectedStory] = useState(0);
  const [storyMobileScreen, setStoryMobileScreen] = useState(false);

  const scrollStoryIntoView = (id) => {
    setSelectedStory(id);
    var container = document.querySelector(`#apparitions-container`);
    var element = document.querySelector(`#apparition-${id}`);
    var btmElement = document.querySelector("#marian-story-btns");
    let yMove = GetAmountSlideStoryByVertical(element, btmElement);
    // Add padding for space between buttons and selected card
    yMove += 5;
    container.scrollBy(0, yMove);
  };
  useEffect(() => {
    if (windowDimensions.width < 650) {
      setStoryMobileScreen(true);
    } else {
      setStoryMobileScreen(false);
    }
  }, [windowDimensions.width]);

  return (
    <div className={styles["rosary-inspirations"]}>
      <div className={styles["powerful-lessons-header"]}>
        <h2 className={styles["section-title"]}>{rosaryFillers && rosaryFillers.mariamAppTitle}</h2>
      </div>
      <div id="apparitions-container" className={styles["rosary-flex-container"]}>
        {apparitions.map((apparitions, ind) => {
          return (
            <Card
              callBack={setSelectedStory}
              key={ind}
              inspiration={apparitions}
              ind={ind}
              image={images[ind]}
              sourceName={rosaryFillers.sourceName}
              BtmNav={BtmNav}
            />
          );
        })}
      </div>
      <div id="marian-story-btns" className={styles["marian-story-btns"]}>
        {storyMobileScreen &&
          apparitions.map((obj, index) => {
            const classStyle =
              selectedStory === index
                ? styles["queen-story-btn-s"] + " " + styles["queen-story-btn"]
                : styles["queen-story-btn-ns"] + " " + styles["queen-story-btn"];
            return <div onClick={() => scrollStoryIntoView(index)} key={index} className={classStyle}></div>;
          })}
      </div>
    </div>
  );
};

const Card = ({ callBack, inspiration, image, ind, sourceName, BtmNav }) => {
  const [displaySources, setDisplaySources] = useState(false);
  // const leftArrowClass = " fa-solid fa-circle-arrow-left " + hStyles["ht-arrow"];
  // const rightArrowClass = " fa-solid fa-circle-arrow-right " + hStyles["ht-arrow"];
  const { title, backTitle, year, story, location, sources, img } = inspiration;

  return (
    <article
      onClick={() => callBack(ind)}
      id={`apparition-${ind}`}
      key={ind}
      className={styles["rosary-inspiration-card"]}
    >
      <div className={styles["ht-card-front"] + " " + styles["ht-card-side"]}>
        <img src={image} className={hStyles["front-img"]} alt={title} />
        <div className={hStyles["ht-card-front-text"]}>
          <h5 className={styles["shaded-title"]} style={{ textAlign: "center" }}>
            {title}
          </h5>
          <h5 style={{ fontStyle: "italic", letterSpacing: ".1em" }}>{location}</h5>
          <h5 className={styles["shaded-title"]}>{year}</h5>
        </div>
      </div>

      <div className={styles["ht-card-back"] + " " + styles["ht-card-side"]}>
        <div className={styles["rt-insp-app-card-back-header"] + " " + hStyles["ht-back-title"]}>
          {/* <i
            onClick={() => setDisplaySources(false)}
            className={
              displaySources
                ? hStyles["purple-arrow"] + " " + leftArrowClass
                : hStyles["grey-arrow"] + " " + leftArrowClass
            }
          ></i> */}
          {displaySources ? (
            <FontAwesomeIcon
              onClick={() => setDisplaySources(false)}
              className={hStyles["purple-arrow"] + " " + hStyles["ht-arrow"]}
              icon={fas.faCircleArrowLeft}
            />
          ) : (
            <FontAwesomeIcon
              className={hStyles["grey-arrow"] + " " + hStyles["ht-arrow"]}
              icon={fas.faCircleArrowLeft}
            />
          )}
          <p style={{ margin: "0" }}>{displaySources ? sourceName : backTitle}</p>

          {/* <i
            onClick={() => setDisplaySources(true)}
            className={
              !displaySources
                ? hStyles["purple-arrow"] + " " + rightArrowClass
                : hStyles["grey-arrow"] + " " + rightArrowClass
            }
          ></i> */}
          {!displaySources ? (
            <FontAwesomeIcon
              onClick={() => setDisplaySources(true)}
              className={hStyles["purple-arrow"] + " " + hStyles["ht-arrow"]}
              icon={fas.faCircleArrowRight}
            />
          ) : (
            <FontAwesomeIcon
              className={hStyles["grey-arrow"] + " " + hStyles["ht-arrow"]}
              icon={fas.faCircleArrowRight}
            />
          )}
        </div>
        <div className={styles["ht-card-back-content"] + " " + hStyles["purple-scroll"]}>
          {!displaySources
            ? story.map((paragraph, index) => {
                const { subTitle, text } = paragraph;
                if (subTitle && subTitle.charAt(0) === "#") {
                  return (
                    <div key={index} className={hStyles["ht-content-paragraph"]}>
                      <p className={hStyles["center-txt"]}>{subTitle.substr(1, subTitle.length - 1)}</p>
                      {/* <p style={{ textIndent: "0px" }}>{text}</p> */}
                      <ItalicsInParagraph content={text} indent={false} />
                    </div>
                  );
                } else if (subTitle && subTitle.charAt(0) === "*") {
                  return (
                    <div key={index} className={hStyles["ht-content-paragraph"]}>
                      <p style={{ marginTop: "1em" }} className={hStyles["center-txt"]}>
                        {subTitle.substr(1, subTitle.length - 1)}
                      </p>
                      {/* <p style={{ textIndent: "0px" }}>{text}</p> */}
                      <ItalicsInParagraph content={text} indent={true} />
                    </div>
                  );
                } else {
                  return (
                    <div key={index} className={hStyles["ht-content-paragraph"]}>
                      {subTitle && <p className={hStyles["center-txt"]}>{subTitle}</p>}
                      <ItalicsInParagraph content={text} indent={true} />
                    </div>
                  );
                }
              })
            : sources.map((obj, index) => {
                const { link, title } = obj;

                if (link) {
                  //Web Sources
                  return (
                    <div key={index} className={hStyles["ht-back-links"]}>
                      <a href={link} target="_blank">
                        <ItalicsInSources content={index + 1 + ". " + title} />
                      </a>
                    </div>
                  );
                } else if (title.charAt(0) === "#") {
                  //Book Sources, we still want numbered
                  return (
                    <div key={index} className={hStyles["ht-back-links"]}>
                      <ItalicsInSources content={index + 1 + ". " + title.substr(1, title.length - 1)} />
                    </div>
                  );
                } else {
                  //Photo credits or any comments we don't want to number
                  return (
                    <div key={index} className={hStyles["ht-back-links"]}>
                      <ItalicsInSources content={title} />
                    </div>
                  );
                }
              })}
        </div>
        <BtmNav name={img} />
      </div>
    </article>
  );
};

export default memo(MariamApparitions);
