/* eslint-disable */
import React, { useState, useEffect, useRef, memo } from "react";
import { useGlobalContext } from "../Context";
// Components
import DivineMercyChannels from "./components/divineMercy/divineMercyChannels";
import DivineMercyQuotes from "./components/divineMercy/divineMercyQuotes";
import DivineMercyMemorial from "./components/divineMercy/divineMercyMemorial";
import DivineMercyLastQuote from "./components/divineMercy/divineMercyLastQ";
import { FooterEnglish, FooterSpanish } from "../data/footer";
import Footer from "./components/footer.js";
import { getImageUrlSV4, getJSON } from "./components/utils/s3-content";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLoaderData } from "react-router-dom";
import ReactGA from "react-ga";

// CSS
import styles from "./styles/DivineMercy.module.css";
// import "../scrollbars/scrollbar.css";

const ImageKeys = [
  { index: 1, key: "our-greatest-shield/dm-image.jpg" },
  { index: 2, key: "our-greatest-shield/dm-chaplet.jpeg" },
  { index: 3, key: "our-greatest-shield/dm-feast.jpeg" },
  { index: 4, key: "our-greatest-shield/dm-hour.jpeg" },
  { index: 5, key: "our-greatest-shield/dm-novena.jpeg" },
];

const JSONData = {
  englishKey: "our-greatest-shield/dm-english.json",
  spanishKey: "our-greatest-shield/dm-spanish.json",
};

const DivineMercy = () => {
  const { language, setLanguage, setHomePage, ReportToGA } = useGlobalContext();
  const { data, images, lang } = useLoaderData();

  useEffect(() => {
    window.scrollTo(0, 0);
    setHomePage(false);
    if (lang === "sp") {
      setLanguage("sp");
    } else {
      setLanguage("en");
    }
    ReportToGA(window.location.pathname);
  }, []);

  if (data) {
    return (
      <HelmetProvider>
        <Helmet>
          <title>{language === "en" ? "Our Divine Shield" : "Nuestra Protección Divina"}</title>
          <meta name="description" content="Introducing the world to it's greatest shield." />
          <meta
            name="keywords"
            content={[
              "Jesus",
              "Jesús",
              "God",
              "Christian",
              "Mercy",
              "misericordia",
              "Dios",
              "Shield",
              "Lord",
              "Love",
              "zeal",
              "king",
              "Dios",
              "amor",
            ]}
          />
        </Helmet>
        <main className={styles["dm-mercy-page"]}>
          <section className={styles["dm-channels-section"]}>
            <DivineMercyChannels props={data.DMChannels} dmFillers={data.DMFillers} images={images} />
          </section>
          <section className={styles["dm-quotes-section"]}>
            <DivineMercyQuotes
              dmFillers={data.DMFillers}
              sources={data.DMQuotesSource}
              props={data.DMQuotes}
              spanish={language === "sp" ? true : false}
            />
          </section>

          <section className={styles["dm-memorial-section"]}>
            <DivineMercyMemorial dmFillers={data.DMFillers} props={data.DMMemorialQuotes} />
          </section>
          <section className={styles["dm-last-quote-section"]}>
            <DivineMercyLastQuote lastQuotes={data.DMLastQuotes} />
          </section>

          <Footer theme={"light"} footer={language === "sp" ? FooterSpanish : FooterEnglish} />
        </main>
      </HelmetProvider>
    );
  } else {
    return <div></div>;
  }
};

export async function GreatestShieldLoader({ params }) {
  var data = {};

  const SignImgUrls = async () => {
    let imageList = [];
    const results = ImageKeys.map(async ({ key }) => {
      return getImageUrlSV4(key);
    });
    Promise.all(results).then((signedImages) => {
      // setImages(signedImages);
      for (let i = 0; i < signedImages.length; i++) {
        imageList.push(signedImages[i]);
      }
      // imageList = signedImages;
    });
    return imageList;
  };

  const GetJSONData = async () => {
    if (params.lang === "sp") {
      //2. Call function to retrieve data from S3
      const result = await getJSON(JSONData["spanishKey"]);
      return JSON.parse(result);
    } else {
      const result = await getJSON(JSONData["englishKey"]);
      return JSON.parse(result);
    }
  };

  data.data = await GetJSONData();
  data.images = await SignImgUrls();
  data.lang = params.lang;
  return data;
}
export default memo(DivineMercy);
