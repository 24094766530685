import React, { memo } from "react";
import styles from "../../styles/PowerfulLessons.module.css";
import { RetrieveIcon } from "../utils/theme_icons";

// Font Awesome Icon Inserts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@awesome.me/kit-72510df5aa/icons";

const ThemeSelectedQuote = ({ qte, themeProperties, userDeselectsQuote, notebookName }) => {
  const { bibleType, dmDate_bibleBook, dmNotebook_bibleVerse, dmEntry_biblePassage, content } = qte;
  const contentTextColor = "var(--glory-blue-100)"; //themeProperties.color;
  return (
    <article style={{ border: `1px solid ${contentTextColor}` }} className={styles["selected-quote"]}>
      <div className={styles["selected-title"]}>
        <p style={{ color: contentTextColor }}>
          {bibleType ? dmEntry_biblePassage : "Journal Entry " + [dmEntry_biblePassage]}
        </p>
      </div>

      <div className={styles["selected-content"]}>
        {content.map((q, ind) => {
          if (content[0] === null && ind === 1) {
            return (
              <p key={ind} style={{ color: contentTextColor }}>
                {q}
              </p>
            );
          } else if (ind % 2 === 1) {
            return (
              <p
                key={ind}
                style={{ color: "var(--cross-gold-300)", letterSpacing: ".01em" }}
                className={styles["bold"]}
              >
                {q}
              </p>
            );
          } else {
            return (
              <p style={{ color: contentTextColor }} key={ind}>
                {q}
              </p>
            );
          }
        })}
      </div>
      <div className={styles["lock-icon"]}>
        {/* <i
          style={{ color: contentTextColor }}
          onClick={userDeselectsQuote}
          className={"fa-solid fa-circle-arrow-left "}
        /> */}
        <FontAwesomeIcon
          style={{ color: contentTextColor }}
          onClick={userDeselectsQuote}
          icon={fas.faCircleArrowLeft}
        />
      </div>
      <div className={styles["selected-footer"]}>
        <p style={{ color: contentTextColor }}>{dmDate_bibleBook}</p>
        {/* <i style={{ color: themeProperties.color }} className={themeProperties.icon} /> */}
        <RetrieveIcon style={{ color: themeProperties.color }} theme_id={themeProperties.id} />
        <p style={{ color: contentTextColor }}>
          {bibleType ? dmNotebook_bibleVerse : notebookName + " " + dmNotebook_bibleVerse}
        </p>
      </div>
      {/* left */}
      {/* <span className={styles["quote-radiate-orb"] + " " + styles["l-orb1"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["l-orb2"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["l-orb3"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["l-orb4"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["l-orb5"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["l-orb6"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["l-orb7"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["l-orb8"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["l-orb9"]}></span> */}
      {/* right */}
      {/* <span className={styles["quote-radiate-orb"] + " " + styles["r-orb1"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["r-orb2"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["r-orb3"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["r-orb4"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["r-orb5"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["r-orb6"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["r-orb7"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["r-orb8"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["r-orb9"]}></span> */}
      {/* top */}
      {/* <span className={styles["quote-radiate-orb"] + " " + styles["t-orb1"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["t-orb2"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["t-orb3"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["t-orb4"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["t-orb5"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["t-orb6"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["t-orb7"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["t-orb8"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["t-orb9"]}></span> */}
      {/* bottom */}
      {/* <span className={styles["quote-radiate-orb"] + " " + styles["b-orb1"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["b-orb2"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["b-orb3"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["b-orb4"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["b-orb5"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["b-orb6"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["b-orb7"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["b-orb8"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["b-orb9"]}></span> */}
      {/* corner */}
      {/* <span className={styles["quote-radiate-orb"] + " " + styles["top-left-orb"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["top-right-orb"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["bottom-left-orb"]}></span>
      <span className={styles["quote-radiate-orb"] + " " + styles["bottom-right-orb"]}></span> */}
    </article>
  );
};

export default memo(ThemeSelectedQuote);
