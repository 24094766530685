/* eslint-disable */
import React, { useState, useEffect, memo, useNavigate } from "react";
import { useLoaderData } from "react-router-dom";
import { useGlobalContext } from "../Context";
// Components
import Medjugorje from "./Medjugorje";
import { FooterEnglish, FooterSpanish } from "../data/footer";
import Footer from "./components/footer.js";

import { getJSON } from "./components/utils/s3-content";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga";
// Data
// import { FooterEnglish, FooterSpanish } from "../data/footer";
// import GData from "../data/ga-english.json";
// import GDataSp from "../data/ga-spanish.json";

const JSONData = {
  englishKey: "global-arena/ga-english_dev.json",
  spanishKey: "global-arena/ga-spanish_dev.json",
};

const GlobalArena = () => {
  const { language, setLanguage, setHomePage, ReportToGA } = useGlobalContext();
  const { data, lang } = useLoaderData();
  // const [data, setData] = useState(null);

  // const GetJSONData = async () => {
  //   if (language === "english") {
  //     //2. Call function to retrieve data from S3
  //     const result = await getJSON(JSONData["englishKey"]);
  //     const medData = JSON.parse(result);
  //     setData(medData["app-info"]);
  //   } else {
  //     const result = await getJSON(JSONData["spanishKey"]);
  //     const medData = JSON.parse(result);
  //     setData(medData["app-info"]);
  //   }
  // };

  // Delete Later : set local JSON for now here
  // const GetJSONData = () => {
  //   if (language === "english") {
  //     setData(GData["app-info"]);
  //   } else {
  //     setData(GDataSp["app-info"]);
  //   }
  // };

  // useEffect(() => {
  //   GetJSONData();
  // }, [language]);

  useEffect(() => {
    setHomePage(true);
    if (lang === "sp") {
      setLanguage("sp");
    } else {
      setLanguage("en");
    }
    ReportToGA(window.location.pathname);
  }, []);

  if (data) {
    return (
      <HelmetProvider>
        <Helmet>
          <title>{language === "en" ? "Global Battle Arena" : "Campo de Batalla Mundial"}</title>
          <meta name="description" content="Introducing the world to Medjugorje." />
          <meta
            name="keywords"
            content={[
              "Jesus",
              "Jesús",
              "God",
              "Medjugorje",
              "Mercy",
              "misericordia",
              "Dios",
              "Shield",
              "Lord",
              "Love",
              "zeal",
              "king",
              "Dios",
              "amor",
            ]}
          />
        </Helmet>
        <main>
          <Medjugorje data={data} />
          {/* <Footer theme={"light"} footer={language === "en" ? FooterEnglish : FooterSpanish} /> */}
        </main>
      </HelmetProvider>
    );
  } else {
    return <div></div>;
  }
};

export async function GlobalArenaLoader({ params }) {
  // const { setLanguage, setHomePage, setColoredZealLogo } = useGlobalContext();
  var data = {};

  // Set the Language in Global Context, Query the data for the HT stories
  const GetJSONData = async () => {
    if (params.lang === "sp") {
      const result = await getJSON(JSONData["spanishKey"]);
      // console.log(JSON.parse(result));
      return JSON.parse(result);
    } else {
      const result = await getJSON(JSONData["englishKey"]);
      // console.log(JSON.parse(result));
      return JSON.parse(result);
    }
  };

  const global_arena_json = await GetJSONData();
  data.data = global_arena_json["app-info"];
  data.lang = params.lang;
  return data;
}

export default memo(GlobalArena);
