import React, { useState, useEffect, useRef, memo } from "react";
import { useGlobalContext } from "../../../Context";
// import RosaryStory from "./rosary-story";
import { ParagraphParsing } from "../utils/paragraphParsing";
import { PreciousBloodArrow } from "../../../fonts/svg/heavenlyPower/preciousBloodArrow";
import { QuestionMarkRed } from "../../../fonts/svg/questionMarkRed";
import styles from "../../styles/HeavenlyPower.module.css";

// Font Awesome Icon Inserts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@awesome.me/kit-72510df5aa/icons";

export const HeavenlyPowerContext = ({ context }) => {
  const [preciousBloodSelection, setPreciousBloodSelection] = useState("context");

  const NavigateToPrayerSection = () => {
    var prayerSectionElement = document.querySelector("#heavenly-power-prayer-section");
    // dmQuoteElement.scrollIntoView({ behavior: "smooth", block: "center" });
    let top = prayerSectionElement.getBoundingClientRect().top;
    window.scrollBy(0, top - 80);
  };

  if (preciousBloodSelection === "context") {
    return (
      <div className={styles["heavenly-power-context-overview"]}>
        <Overview context={context} BGScreen={false} />
        <PreciousBloodContxtBtns
          selection={preciousBloodSelection}
          selectionHandler={setPreciousBloodSelection}
          overviewTitle={context["overviewBtnTitle"]}
          quotesTitle={context["quotesFromJesusBtnTitle"]}
          devotionTitle={context["dailyDevotionBtnTitle"]}
        />
      </div>
    );
  } else if (preciousBloodSelection === "daily_devotion") {
    return (
      <div className={styles["heavenly-power-context"]}>
        <DailyDevotion context={context} />
        <PreciousBloodContxtBtns
          selection={preciousBloodSelection}
          selectionHandler={setPreciousBloodSelection}
          overviewTitle={context["overviewBtnTitle"]}
          quotesTitle={context["quotesFromJesusBtnTitle"]}
          devotionTitle={context["dailyDevotionBtnTitle"]}
          NavigateToPrayerSection={NavigateToPrayerSection}
        />
      </div>
    );
  } else if (preciousBloodSelection === "quotes_by_Jesus") {
    return (
      <div className={styles["heavenly-power-context"]}>
        <QuotesByJesus context={context} />
        <PreciousBloodContxtBtns
          selection={preciousBloodSelection}
          selectionHandler={setPreciousBloodSelection}
          overviewTitle={context["overviewBtnTitle"]}
          quotesTitle={context["quotesFromJesusBtnTitle"]}
          devotionTitle={context["dailyDevotionBtnTitle"]}
        />
      </div>
    );
  } else {
    return <div></div>;
  }
};

const PreciousBloodContxtBtns = ({
  selection,
  selectionHandler,
  overviewTitle,
  quotesTitle,
  devotionTitle,
  NavigateToPrayerSection,
}) => {
  if (selection === "context") {
    return (
      <div className={styles["heavenly-power-context-btns"]}>
        <div onClick={() => selectionHandler("quotes_by_Jesus")} className={styles["hp-context-quotes-btn"]}>
          {quotesTitle}
        </div>
        <div onClick={() => selectionHandler("daily_devotion")} className={styles["hp-context-devotion-btn"]}>
          {devotionTitle}
        </div>
      </div>
    );
  } else if (selection === "quotes_by_Jesus") {
    return (
      <div className={styles["heavenly-power-context-btns"]}>
        <div onClick={() => selectionHandler("context")} className={styles["hp-context-overview-btn"]}>
          {overviewTitle}
        </div>
        <div onClick={() => selectionHandler("daily_devotion")} className={styles["hp-context-devotion-btn"]}>
          {devotionTitle}
        </div>
      </div>
    );
  } else if (selection === "daily_devotion") {
    return (
      <div className={styles["heavenly-power-context-btns"]}>
        <div onClick={() => selectionHandler("quotes_by_Jesus")} className={styles["hp-context-quotes-btn"]}>
          {quotesTitle}
        </div>
        <div onClick={() => selectionHandler("context")} className={styles["hp-context-overview-btn"]}>
          {overviewTitle}
        </div>
        {/* Class is only used to hide svg on Big Screen */}
        <div onClick={() => NavigateToPrayerSection()} className={styles["precious-blood-arrow-cont"]}>
          <PreciousBloodArrow />
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export const HeavenlyPowerContextBG = ({ context }) => {
  const [preciousBloodSelectionBG, setPreciousBloodSelectionBG] = useState("context");
  const [informationIconBGStatus, setInformationIconBGStatus] = useState({ selected: false, hasSelected: false });

  const NavigateToPrayerSection = () => {
    var prayerSectionElement = document.querySelector("#heavenly-power-prayer-section");
    // dmQuoteElement.scrollIntoView({ behavior: "smooth", block: "center" });
    let top = prayerSectionElement.getBoundingClientRect().top;
    window.scrollBy(0, top - 80);
  };

  // Have Context Screen on the left hand side (With Btn to switch to Daily Devotion), have the quotes on the right hand side,
  return (
    <div className={styles["heavenly-power-context-bg"]}>
      <div className={styles["heavenly-power-bg-context-information"]}>
        <button
          onClick={() => setInformationIconBGStatus({ selected: !informationIconBGStatus.selected, hasSelected: true })}
          className={
            !informationIconBGStatus.hasSelected
              ? `${styles["information-icon"]} ${styles["icon-bounce"]}`
              : styles["information-icon"]
          }
        >
          <QuestionMarkRed />
        </button>
        {informationIconBGStatus.selected && (
          <div className={styles["information-msg-hp"]}>
            <p>{context.contextMsg.informationMsg + " "}</p>
            <div className={styles["information-icons"]}>
              {/* <i
                onClick={() => setInformationIconBGStatus({ ...informationIconBGStatus, selected: false })}
                style={{ position: "relative", top: ".25em" }}
                className="fa-solid fa-xmark"
              ></i> */}
              <FontAwesomeIcon
                onClick={() => setInformationIconBGStatus({ ...informationIconBGStatus, selected: false })}
                style={{ position: "relative", top: ".25em" }}
                icon={fas.faXmark}
              />
              <a href={context.contextMsg.informationLink} target="_blank">
                {/* <i className="fa-solid fa-arrow-up-right-from-square"></i> */}
                <FontAwesomeIcon icon={fas.faArrowUpRightFromSquare} />
              </a>
            </div>
          </div>
        )}
      </div>
      <div className={styles["hp-context-bg-scrn-1"]}>
        {preciousBloodSelectionBG === "daily_devotion" ? (
          <>
            <DailyDevotion context={context} />

            <div onClick={() => setPreciousBloodSelectionBG("context")} className={styles["hp-context-overview-btn"]}>
              {context["overviewBtnTitle"]}
            </div>
          </>
        ) : (
          <>
            <Overview context={context} BGScreen={true} />

            <div
              onClick={() => setPreciousBloodSelectionBG("daily_devotion")}
              className={styles["hp-context-devotion-btn"]}
            >
              {context["dailyDevotionBtnTitle"]}
            </div>
          </>
        )}
      </div>
      <div className={styles["hp-context-bg-scrn-2"]}>
        <QuotesByJesus context={context} />
      </div>
    </div>
  );
};

const Overview = ({ context, BGScreen }) => {
  // use state only used if `BGScreen` == True
  const [informationIconStatus, setInformationIconStatus] = useState({ selected: false, hasSelected: false });
  if (BGScreen) {
    return (
      <>
        <h1 className={styles["section-title"]}>{context.overviewTitle}</h1>

        <div className={styles["heavenly-power-context-body"]}>
          {context.overview.map((txt, index) => {
            return <ParagraphParsing paragraph={txt} k={index} txtClass={styles["heavenly-power-context-paragraph"]} />;
          })}
        </div>
      </>
    );
  } else {
    return (
      <>
        <h1 className={styles["section-title"]}>{context.overviewTitle}</h1>
        <button
          onClick={() => setInformationIconStatus({ selected: !informationIconStatus.selected, hasSelected: true })}
          className={
            !informationIconStatus.hasSelected
              ? `${styles["information-icon"]} ${styles["icon-bounce"]}`
              : styles["information-icon"]
          }
        >
          <QuestionMarkRed />
        </button>
        {informationIconStatus.selected && (
          <div className={styles["information-msg-hp"]}>
            <p>{context.contextMsg.informationMsg + " "}</p>
            <div className={styles["information-icons"]}>
              {/* <i
                onClick={() => setInformationIconStatus({ ...informationIconStatus, selected: false })}
                style={{ position: "relative", top: ".25em" }}
                className="fa-solid fa-xmark"
              ></i> */}
              <FontAwesomeIcon
                onClick={() => setInformationIconStatus({ ...informationIconStatus, selected: false })}
                style={{ position: "relative", top: ".25em" }}
                icon={fas.faXmark}
              />
              <a href={context.contextMsg.informationLink} target="_blank">
                {/* <i className="fa-solid fa-arrow-up-right-from-square"></i> */}
                <FontAwesomeIcon icon={fas.faArrowUpRightFromSquare} />
              </a>
            </div>
          </div>
        )}
        <div className={styles["heavenly-power-context-body"]}>
          {context.overview.map((txt, index) => {
            return <ParagraphParsing paragraph={txt} k={index} txtClass={styles["heavenly-power-context-paragraph"]} />;
          })}
        </div>
      </>
    );
  }
};

const DailyDevotion = ({ context }) => {
  return (
    <>
      <h1 className={styles["section-title"]}>{context.dailyDevotionTitle}</h1>
      <div className={styles["heavenly-power-context-body"]}>
        <ParagraphParsing
          paragraph={context.dailyDevotion["paragraph"]}
          k={"intro"}
          txtClass={styles["heavenly-power-context-paragraph"]}
        />
        <ul className={styles["daily-devotion-list"]}>
          {context.dailyDevotion["devotions"].map((txt, index) => {
            return (
              <li key={index} className={styles["daily-devotion-list-elements"]}>
                <ParagraphParsing paragraph={txt} k={"intro"} txtClass={styles["heavenly-power-context-paragraph"]} />
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

const QuotesByJesus = ({ context }) => {
  return (
    <>
      <h1 className={styles["section-title"]}>{context.quotesFromJesusTitle}</h1>
      <div className={styles["heavenly-power-context-body"]}>
        {context.quotesFromJesus.map((quote_obj, index) => {
          const { quote, date } = quote_obj;
          return (
            <>
              <ParagraphParsing paragraph={date} k={index} txtClass={styles["heavenly-power-context-quote-date"]} />
              {quote.map((paragraph, p_index) => {
                p_index = "p" + p_index;
                return (
                  <ParagraphParsing
                    paragraph={paragraph}
                    k={p_index}
                    txtClass={styles["heavenly-power-context-quote-paragraph"]}
                  />
                );
              })}
            </>
          );
        })}
      </div>
    </>
  );
};
// export default memo(HeavenlyPowerContext);
