import React from "react";
import { ReactComponent as Svg } from "./SacredHeartOfJesus.svg";
import styles from "./sacredHeartOfJesus.module.css";

export const SacredHeartOfJesus = () => {
  return (
    <svg
      id="sacredheartofjesus-2"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="-30 -68 480 520"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
    >
      <defs>
        <radialGradient
          id="sacredheartofjesus-2-s-circle1-fill"
          cx="0"
          cy="0"
          r="0.566272"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0.5 0.5)"
        >
          <stop id="sacredheartofjesus-2-s-circle1-fill-0" offset="0%" stopColor="#fbfbfb" />
          <stop id="sacredheartofjesus-2-s-circle1-fill-1" offset="100%" stopColor="#e3b414" />
        </radialGradient>
        <linearGradient
          id="sacredheartofjesus-2-u-l2-flame-fill"
          x1="0.71796"
          y1="0.995528"
          x2="1"
          y2="0.5"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="sacredheartofjesus-2-u-l2-flame-fill-0" offset="0%" stopColor="#ad610f" />
          <stop id="sacredheartofjesus-2-u-l2-flame-fill-1" offset="100%" stopColor="#f0e010" />
        </linearGradient>
        <linearGradient
          id="sacredheartofjesus-2-u-r2-flame-fill"
          x1="0.71796"
          y1="0.995528"
          x2="1"
          y2="0.5"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="sacredheartofjesus-2-u-r2-flame-fill-0" offset="0%" stopColor="#ad610f" />
          <stop id="sacredheartofjesus-2-u-r2-flame-fill-1" offset="100%" stopColor="#f0e010" />
        </linearGradient>
        <linearGradient
          id="sacredheartofjesus-2-u-r1-flame-fill"
          x1="0.71796"
          y1="0.995528"
          x2="1"
          y2="0.5"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="sacredheartofjesus-2-u-r1-flame-fill-0" offset="0%" stopColor="#ad610f" />
          <stop id="sacredheartofjesus-2-u-r1-flame-fill-1" offset="100%" stopColor="#f0e010" />
        </linearGradient>
        <linearGradient
          id="sacredheartofjesus-2-u-l1-flame-fill"
          x1="0.71796"
          y1="0.995528"
          x2="1"
          y2="0.5"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="sacredheartofjesus-2-u-l1-flame-fill-0" offset="0%" stopColor="#ad610f" />
          <stop id="sacredheartofjesus-2-u-l1-flame-fill-1" offset="100%" stopColor="#f0e010" />
        </linearGradient>
        <linearGradient
          id="sacredheartofjesus-2-u-mid-flame-fill"
          x1="0.71796"
          y1="0.995528"
          x2="1"
          y2="0.5"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="sacredheartofjesus-2-u-mid-flame-fill-0" offset="0%" stopColor="#ad610f" />
          <stop id="sacredheartofjesus-2-u-mid-flame-fill-1" offset="100%" stopColor="#f0e010" />
        </linearGradient>
        <radialGradient
          id="sacredheartofjesus-2-u-heart-fill"
          cx="0"
          cy="0"
          r="0.549672"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0.497301 0.358326)"
        >
          <stop id="sacredheartofjesus-2-u-heart-fill-0" offset="0%" stopColor="#edb50c" />
          <stop id="sacredheartofjesus-2-u-heart-fill-1" offset="99%" stopColor="#600202" />
        </radialGradient>
      </defs>
      <circle
        id="sacredheartofjesus-2-s-circle1"
        r="128.998039"
        transform="matrix(.659721 0.751511-.751511 0.659721 209.646891 200.813413)"
        fill="url(#sacredheartofjesus-2-s-circle1-fill)"
      />
      <g id="sacredheartofjesus-2-u-sacredheart" transform="matrix(.68 0 0 0.65 73.489925 69.910635)">
        <g id="sacredheartofjesus-2-u-flame" transform="translate(.000011 21.000011)">
          <path
            id="sacredheartofjesus-2-u-l2-flame"
            d="M15.847675,47.930215c-1.878932-2.548234-20.435434-22.684604-6.962991-34.448996s8.110162-23.559857,9.906588-33.812888c5.26132,6.615477,16.963891,13.535145,5.26132,33.812888s9.904067,37.502649,9.904067,37.502649-14.26486-1.779134-18.170865-3.053653"
            transform="translate(136.000799 54.335642)"
            fill="url(#sacredheartofjesus-2-u-l2-flame-fill)"
            fillRule="evenodd"
            stroke="#481101"
            strokeLinecap="round"
          />
          <path
            id="sacredheartofjesus-2-u-r2-flame"
            d="M15.558459,48.930214C13.679527,46.38198,-4.587759,25.245611,8.884684,13.481219s8.110162-23.559857,9.906588-33.812888c5.26132,6.615477,16.963891,13.535145,5.26132,33.812888s11.392475,38.183693,11.392475,38.183693-15.888243-2.142796-19.886607-2.734698"
            transform="matrix(-1 0 0 1 264.499421 53.335643)"
            fill="url(#sacredheartofjesus-2-u-r2-flame-fill)"
            fillRule="evenodd"
            stroke="#481101"
            strokeLinecap="round"
          />
          <path
            id="sacredheartofjesus-2-u-r1-flame"
            d="M15.558459,48.930214c-4.535763.108597-27.659748-13.033717-12.20255-43.796928s-.60102-39.598362,1.195406-49.851393c5.26132,6.615477,31.203848,37.921583,19.501277,58.199326s11.446829,40.036719,11.446829,40.036719-16.046209-3.313205-19.952214-4.587724"
            transform="matrix(-1 0 0 1 241.499421 55.335643)"
            fill="url(#sacredheartofjesus-2-u-r1-flame-fill)"
            fillRule="evenodd"
            stroke="#481101"
            strokeLinecap="round"
          />
          <path
            id="sacredheartofjesus-2-u-l1-flame"
            d="M15.558459,48.930214c-4.535763.108597-27.659748-13.033717-12.20255-43.796928s-.60102-39.598362,1.195406-49.851393c5.26132,6.615477,31.203848,37.921583,19.501277,58.199326s11.446829,40.036719,11.446829,40.036719-16.046209-3.313205-19.952214-4.587724"
            transform="translate(158.667466 56.668976)"
            fill="url(#sacredheartofjesus-2-u-l1-flame-fill)"
            fillRule="evenodd"
            stroke="#481101"
            strokeLinecap="round"
          />
          <path
            id="sacredheartofjesus-2-u-mid-flame"
            d="M13.459696,53.205201c-4.535763.108597-21.765747-20.439315-9.424188-41.723982s9.424188-37.43197,9.424188-56.199326c5.26132,6.615477,8.151341,36.04644,19.612479,56.199326s-7.197796,40.357983-7.197796,40.357983-8.508678,2.640518-12.414683,1.365999"
            transform="translate(182.000799 55.014379)"
            fill="url(#sacredheartofjesus-2-u-mid-flame-fill)"
            fillRule="evenodd"
            stroke="#481101"
            strokeLinecap="round"
          />
        </g>
        <path
          id="sacredheartofjesus-2-u-heart"
          d="M116.132603,312.928241c-99.880689-54.542356-133.863985-119.756265-140-185.207091-3.408897-34.088973,18.302369-80.005113,70-70.681779c89.432419,12.493608,49.204023,12.493608,140,0c50.334072-8.641555,73.922911,37.527618,70,70.681779-5.576956,66.577467-39.437532,130.664735-140,185.207091"
          transform="translate(83.109752 62.88415)"
          fill="url(#sacredheartofjesus-2-u-heart-fill)"
          stroke="#3a0202"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g id="sacredheartofjesus-2-u-vines" transform="translate(0 0.000001)">
          <path
            id="sacredheartofjesus-2-u-1stline"
            d="M-67.654908,98.366696c48.264991,22.176435,62.099818.349969,80.973293-10.958791s42.796783-23.831047,70.19376-18.523505s22.545275,30.795534,72.879347,18.523504"
            transform="translate(158.522243 186.925782)"
            fill="none"
            stroke="#4c1f05"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="sacredheartofjesus-2-u-2ndline"
            d="M-93.822274,20.037507C-75.448576,42.455028,-29.371321,3.919478,-4.325477,3.919478s54.301621,41.637195,80.011208,41.637195s36.524857-41.637195,73.411868-41.637195"
            transform="translate(176.166105 248.878089)"
            fill="none"
            stroke="#4c1f05"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="sacredheartofjesus-2-u-3rdline"
            d="M-104.172244,-15.807395c32.897907-3.661286,58.446885,18.730999,79.146826,22.692585s50.532204,23.997115,60.273352,24.062662s63.260616-54.697463,108.025287-26.102042"
            transform="translate(175.910007 263.48691)"
            fill="none"
            stroke="#4c1f05"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="sacredheartofjesus-2-u-5thline"
            d="M-105.685447,20.127615c48.374969,17.936628,77.812784,26.151062,96.068228,23.701775s62.603509,24.713747,80.011208,19.985648s23.148384-15.549761,54.988372-19.985648"
            transform="translate(182.198312 241.463088)"
            fill="none"
            stroke="#4c1f05"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="sacredheartofjesus-2-u-4thline"
            d="M-105.54777,22.606629c34.702839,0,60.828005,44.702839,90.714439,44.702839s40.818739-28.728789,63.317461-30.988604s53.366739,18.104344,71.426419,25.919398"
            transform="translate(179.976918 233.203553)"
            fill="none"
            stroke="#4c1f05"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g id="sacredheartofjesus-2-u-thorns" transform="translate(0 21)">
          <polygon
            id="sacredheartofjesus-2-u-triangle"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(-.969901-.243502 0.243502-.969901 164.59875 358.860269)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle2"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(-.764912 0.644135-.644135-.764912 321.344482 328.704819)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle3"
            points="22.363423,70.005717 19,76.4553 24.034784,76.4553 22.363423,70.005717"
            transform="matrix(-.673798 0.738915-.738915-.673798 310.832094 293.32189)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle4"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(-.808952-.587875 0.587875-.808952 178.503437 347.665265)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle5"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(.917838 0.396956-.396956 0.917838 243.257869 202.444547)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle6"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(-.999979 0.006554-.006554-.999979 112.862996 303.495724)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle7"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(-.808225-.588874 0.588874-.808225 67.737353 320.360624)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle8"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(.997917 0.064511-.064511 0.997917 86.198932 152.530335)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle9"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(.976728-.214483 0.214483 0.976728 74.786617 201.08633)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle10"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(.969901 0.243502-.243502 0.969901 138.388211 182.730402)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle11"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(.773668 0.633591-.633591 0.773668 133.404641 170.442859)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle12"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(-.916119 0.400906-.400906-.916119 259.176781 319.386175)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle13"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(.856649 0.5159-.5159 0.856649 180.37017 174.141868)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle14"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(-.843298-.537446 0.537446-.843298 179.995797 323.506112)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle15"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(-.752126-.65902 0.65902-.752126 218.755025 310.313881)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle16"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(.717802-.696247 0.696247 0.717802 195.667764 229.168138)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle17"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(-.949668 0.313257-.313257-.949668 322.276073 307.593553)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle18"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(-.911086 0.412215-.412215-.911086 261.090262 296.100956)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle19"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(-.902101-.431524 0.431524-.902101 165.077324 311.611924)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle20"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(-.851992-.523555 0.523555-.851992 117.630037 322.152224)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle21"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(-.878393-.47794 0.47794-.878393 160.376201 341.510338)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle22"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(.806455-.591295 0.591295 0.806455 88.241724 187.308413)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle23"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(-.911745-.410756 0.410756-.911745 296.309266 320.853749)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle24"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(.648195 0.761475-.761475 0.648195 328.320239 200.677591)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle25"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(.965245-.261347 0.261347 0.965245 256.70667 188.32913)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle26"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(.89838-.439219 0.439219 0.89838 259.740772 174.642642)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle27"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(.964863 0.262754-.262754 0.964863 285.076012 160.224577)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle28"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="translate(206.312593 157.039596)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle29"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(-.972009 0.234944-.234944-.972009 272.578341 302.821086)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle30"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(.867923-.496698 0.496698 0.867923 171.023644 193.222802)"
            fill="#4c1f05"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle31"
            points="21.517392,69.639919 19,76.4553 24.034784,76.4553 21.517392,69.639919"
            transform="matrix(.623089 0.782151-.782151 0.623089 304.077424 176.554872)"
            fill="#4c1f05"
          />
        </g>
      </g>
      <g id="sacredheartofjesus-2-u-rays" transform="translate(0-.6515)">
        <g id={styles["sacredheartofjesus-2-u-group-4"]} transform="translate(.000001 0)" opacity="0">
          <polygon
            id="sacredheartofjesus-2-u-triangle-19"
            points="47.689959,-17.875164 19,93.160754 76.379918,93.160754 47.689959,-17.875164"
            transform="matrix(-.165794-.05371 0.328534-1.014128 147.219175 418.581555)"
            fill="#e3b414"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle-14"
            points="47.689959,23.15 19,93.160754 76.379918,93.160754 47.689959,23.15"
            transform="matrix(-.073299 0.158113-1.295875-.600747 444.336929 310.112722)"
            fill="#e3b414"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle-8"
            points="47.689959,23.15 19,93.160754 76.379918,93.160754 47.689959,23.15"
            transform="matrix(.080202-.154726 1.268113 0.657327-25.693928 93.721004)"
            fill="#e3b414"
          />
        </g>
        <g id={styles["sacredheartofjesus-2-u-group-5"]} transform="translate(0 0.000001)" opacity="0">
          <polygon
            id="sacredheartofjesus-2-u-triangle-15"
            points="47.689959,23.15 19,93.160754 76.379918,93.160754 47.689959,23.15"
            transform="matrix(.169719 0.039598-.32454 1.390994 259.250645-53.717142)"
            fill="#e3b414"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle-10"
            points="47.689959,23.15 19,93.160754 76.379918,93.160754 47.689959,23.15"
            transform="matrix(.084365 0.152496-1.249839 0.691441 434.862466 73.09482)"
            fill="#e3b414"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle-5"
            points="36.058723,39.519531 15.975752,96.776014 56.141694,96.776014 36.058723,39.519531"
            transform="matrix(-.035112-.170703 1.513985-.311412-58.565886 273.594129)"
            fill="#e3b414"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle-3"
            points="47.689959,-17.875164 19,99.552681 76.379918,99.552681 47.689959,-17.875164"
            transform="matrix(.142461-.100386 1.018249 1.445035 29.543238-42.842423)"
            fill="#e3b414"
          />
        </g>
        <g id={styles["sacredheartofjesus-2-u-group-1"]} transform="translate(.000001 0)">
          <polygon
            id="sacredheartofjesus-2-u-triangle-9"
            points="47.689959,-17.875164 19,93.160754 76.379918,93.160754 47.689959,-17.875164"
            transform="matrix(-.119826 0.126547-1.022953-.968622 395.021229 379.697423)"
            fill="#e3b414"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle-12"
            points="47.689959,23.15 19,93.160754 76.379918,93.160754 47.689959,23.15"
            transform="matrix(-.000212-.174277 1.428351-.001735-47.734921 210.213591)"
            fill="#e3b414"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle-2"
            points="47.689959,-17.875164 19,85.08028 76.379918,85.08028 47.689959,-17.875164"
            transform="matrix(.139031 0.105085-1.06592 1.410239 355.135551-31.635689)"
            fill="#e3b414"
          />
        </g>
        <g id={styles["sacredheartofjesus-2-u-group-6"]} opacity="0.4">
          <polygon
            id="sacredheartofjesus-2-u-triangle-18"
            points="47.689959,-17.875164 19,93.160754 76.379918,93.160754 47.689959,-17.875164"
            transform="matrix(.119394 0.126954-1.026245 0.965133 392.536362 22.581077)"
            fill="#e3b414"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle-17"
            points="47.689959,-17.875164 19,93.160754 76.379918,93.160754 47.689959,-17.875164"
            transform="matrix(-.157929 0.073694-.595708-1.276633 319.154104 434.454219)"
            fill="#e3b414"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle-16"
            points="47.689959,-17.875164 19,93.160754 76.379918,93.160754 47.689959,-17.875164"
            transform="matrix(.123705-.122758 0.99232 0.999981 19.628635 29.535758)"
            fill="#e3b414"
          />
        </g>
        <g id={styles["sacredheartofjesus-2-u-group-7"]} opacity="0.7">
          <polygon
            id="sacredheartofjesus-2-u-copy-of-triangle-5"
            points="36.058723,39.519531 15.975752,96.776014 56.141694,96.776014 36.058723,39.519531"
            transform="matrix(.047026 0.167813-1.488346 0.417076 471.267513 134.370135)"
            fill="#e3b414"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle-13"
            points="47.689959,23.15 19,93.160754 76.379918,93.160754 47.689959,23.15"
            transform="matrix(-.174273 0.001171-.009594-1.42832 220.324528 458.992976)"
            fill="#e3b414"
          />
          <polygon
            id="sacredheartofjesus-2-u-copy-of-triangle-8"
            points="41.951967,23.15 19,88.286936 64.903935,88.286936 41.951967,23.15"
            transform="matrix(.043925-.168651 1.307386 0.340504-32.360905 152.091005)"
            fill="#e3b414"
          />
        </g>
        <g id={styles["sacredheartofjesus-2-u-group-3"]} opacity="0">
          <polygon
            id="sacredheartofjesus-2-u-copy-of-triangle-8-2"
            points="41.951967,23.15 19,88.286936 64.903935,88.286936 41.951967,23.15"
            transform="matrix(-.029063 0.171837-1.271076-.214979 443.126769 245.102346)"
            fill="#e3b414"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle-4"
            points="36.058723,30.567069 12.55497,112.347678 59.562476,112.347678 36.058723,30.567069"
            transform="matrix(-.090764-.148776 1.221319-.745091-29.819879 363.131306)"
            fill="#e3b414"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle-1"
            points="47.689959,-17.875164 19,93.160754 76.379918,93.160754 47.689959,-17.875164"
            transform="matrix(.174024-.009386 0.075869 1.406736 187.943332-57.791991)"
            fill="#e3b414"
          />
        </g>
        <g id={styles["sacredheartofjesus-2-u-group-2"]} opacity="0.2">
          <polygon
            id="sacredheartofjesus-2-u-triangle-11"
            points="47.689959,-17.875164 19,93.160754 76.379918,93.160754 47.689959,-17.875164"
            transform="matrix(-.139436-.104547 0.845115-1.127139 61.450562 413.854313)"
            fill="#e3b414"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle-7"
            points="47.689959,23.15 19,93.160754 76.379918,93.160754 47.689959,23.15"
            transform="matrix(.001171 0.174273-1.42832 0.009594 467.783254 198.544189)"
            fill="#e3b414"
          />
          <polygon
            id="sacredheartofjesus-2-u-triangle-6"
            points="47.689959,23.15 19,107.162905 76.379918,107.162905 47.689959,23.15"
            transform="matrix(.162952-.061798 0.506489 1.335537 108.517828-56.142716)"
            fill="#e3b414"
          />
        </g>
      </g>
    </svg>
  );
};
